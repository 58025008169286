<template>
  <div>
    <span class="font-weight-bold">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span
            v-on="on"
            v-if="invoice && invoice.invoiceNumber"
            class="font-weight-bold success--text"
            style="cursor: pointer"
          >
            {{ invoice.invoiceNumber }}
          </span>
        </template>
        <div>
          <p class="font-weight-bold">Tuotteet</p>
          <div v-html="formattedProducts.productsString"></div>
          <p class="font-weight-bold" style="margin-top: 5px">
            Yhteensä: {{ formatCurrency(formattedProducts.totalAmount) }}
          </p>
        </div>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import invoiceMixins from "@/mixins/invoiceMixins";
import invoiceHelpers from "@/utils/invoiceHelpers";
export default {
  mixins: [mixins, invoiceMixins],
  props: ["invoice"],

  computed: {
    products() {
      if (this.invoice.isUpdated) {
        return this.invoice.updatedProducts;
      } else {
        return this.invoice.products;
      }
    },

    formattedProducts() {
      return invoiceHelpers.formatRecurrentInvoiceProducts(this.invoice);
    },
  },
};
</script>

<style></style>
