<template>
  <div>
    <div v-if="!base64Pdf.fileName && !base64Pdf.base64FileString">
      <v-file-input
        v-model="file"
        label="Liitä PDF-tiedosto"
        accept=".pdf"
        outlined
        dense
        show-size
        :disabled="loading"
        :rules="[validateFileType, validateFileSize]"
        :loading="loading"
        @change="convertToBase64"
      ></v-file-input>
      <small class="error--text">Max. {{ maxSize * 1000 }} kB / {{ maxSize }} MB</small>
    </div>

    <div v-else>
      <v-card>
        <v-card-text style="display: flex; justify-content: space-between; align-items: center">
          <div><v-icon class="mr-1" medium>mdi-file</v-icon>{{ base64Pdf.fileName }}</div>
          <v-btn icon small>
            <v-icon @click="deleteContent" color="error" small>mdi-delete</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],

  data() {
    return {
      file: null,
      maxSize: 2,
      base64Pdf: {
        base64FileString: "",
        fileName: "",
      },
      loading: false,
    };
  },

  methods: {
    validateFileType(file) {
      if (!file) return "Tiedosto vaaditaan";
      return file.type === "application/pdf" || "Vain PDF-tiedostot sallittu";
    },

    validateFileSize(file) {
      if (!file) return "Tiedosto vaaditaan";
      return (
        file.size / (1024 * 1024) <= this.maxSize ||
        `Tiedoston koko ei saa ylittää ${this.maxSize} MB`
      );
    },

    convertToBase64(file) {
      if (!file) return;
      if (this.validateFileSize(file) !== true) {
        return this.showPopup(this.validateFileSize(file), "error");
      }
      this.loading = true;

      try {
        this.base64Pdf.fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.base64Pdf.base64FileString = e.target.result.split(",")[1];
          this.loading = false;
          this.showPopup("Pdf lisätty", "success");
        };
      } catch (err) {
        this.showPopup("Pdf lisäys ei onnistunut", "error");
        this.base64Pdf.fileName = "";
        this.base64Pdf.base64FileString = "";
        this.loading = false;
      }
    },

    getContent() {
      return this.base64Pdf;
    },

    setContent(obj) {
      this.base64Pdf = obj;
    },

    deleteContent() {
      this.file = null;
      this.base64Pdf.base64FileString = "";
      this.base64Pdf.fileName = "";
    },
  },
};
</script>

<style></style>
