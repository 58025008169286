import { snackbarBus } from "../main";
import globalValues from "../configs/globalValues";

export const showPopup = function (text, color, timeOut = 6000) {
  snackbarBus.$emit("createsnackbar", {
    text,
    color,
    timeOut,
  });
};

export function debounce(cb, delay = 1000, value) {
  let timeout;

  if (value == "") return;

  return function (...args) {
    if (timeout) clearTimeout(timeout);
    const that = this;
    timeout = setTimeout(function () {
      if (args[0] == "") return;
      cb.apply(that, args);
    }, delay);
  };
}

export const setCustomStyles = (group, vuetify) => {
  // set styles only if group is active
  if (group.active) {
    // Set custom styles
    const theme = vuetify.theme.themes.light;
    const colors = group.colors;
    const font = group.fonts?.fontFamily || "Bai Jamjuree";

    // Set font
    loadFont(font);
    document.body.style.setProperty("--custom-body-font", font);

    // Set colors
    theme.primary = colors.primary;
    theme.info = colors.info;
    theme.success = colors.success;
    theme.warning = colors.warning;
    theme.error = colors.error;
    theme.draft = colors.draft;
    theme.footer = colors.footer;
    theme["footer-text"] = colors["footer-text"];
  }
};

export const setDefaultStyles = (vuetify) => {
  // Set font
  const font = "Bai Jamjuree";
  loadFont(font);
  document.body.style.setProperty("--custom-body-font", font);

  // Set colors
  const theme = vuetify.theme.themes.light;
  const colors = globalValues.themeColors;

  theme.primary = colors.primary;
  theme.info = colors.info;
  theme.success = colors.success;
  theme.warning = colors.warning;
  theme.error = colors.error;
  theme.draft = colors.draft;
  theme.footer = colors.footer;
  theme["footer-text"] = colors["footer-text"];
};

export const loadFont = (fontFamily = "Bai Jamjuree") => {
  const googleFontFormat = fontFamily.split(" ").join("+");

  const link = document.createElement("link");
  link.href = `https://fonts.googleapis.com/css2?family=${googleFontFormat}:wght@300;400;500;600;700&display=swap`;
  link.rel = "stylesheet";
  document.head.appendChild(link);
};
