<template>
  <v-container>
    <v-row dense justify="center">
      <v-col cols="12" md="10" lg="9" xl="5">
        <!-- Model -->
        <Model
          modelType="rentalContract"
          ref="model"
          @contractmodelidchanged="contractModelIdChangeHandler"
        />

        <!-- CONTENT MODE -->
        <div v-if="!editmode && rentalContract.modelId && showEditMode">
          <v-btn class="mb-3 mt-3" color="primary" @click="editContent"> Muokkaa sopimusta </v-btn>

          <div v-html="contractContent" />

          <v-btn class="mt-5 mb-5" color="primary" @click="editContent"> Muokkaa sopimusta </v-btn>
        </div>

        <!-- EDIT MODE -->

        <ModelEditor
          v-if="editmode"
          @cancelcontenthandler="contentCancelHandler"
          @savecontent="contentOkHandler"
          modelType="rentalContract"
          :value="modelContentLoaded"
          @input="(content) => handlerContentChange(content)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModelEditor from "../ContractModels/ModelEditor";
import Model from "@/components/ContractModels/Model";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  components: {
    ModelEditor,
    Model,
  },

  data: function () {
    return {
      modelContentLoaded: "",
      modelContentMutated: "",
      editmode: false,
      snackbar: true,
      showEditMode: false,
    };
  },

  computed: {
    ...mapState("contractmodel", ["contractModel", "contractContent"]),
    ...mapState(["rentalContract"]),
  },

  watch: {
    contractModel() {
      this.modelContentLoaded = this.contractModel.content;
      this.modelContentMutated = this.modelContentLoaded;
      this.getContent();
      this.$store.commit("setContractField", {
        content: this.modelContentMutated,
      });
      this.$store.commit("updateLocalStorage");
    },
  },

  methods: {
    ...mapActions("contractmodel", ["getContractContent", "getContractModel"]),

    getRentalContractContent() {
      this.modelContentLoaded = this.rentalContract.content || this.modelContentLoaded;
      this.modelContentMutated = this.modelContentLoaded;
      this.getContent();
    },

    contractModelIdChangeHandler() {
      this.getContractModel(this.rentalContract.modelId);
    },

    getContent() {
      let contractData = this.$store.getters.getContract;

      this.getContractContent({
        contractData: contractData,
        contentData: this.modelContentMutated,
        contractType: "rentalContract",
      });

      // Show edit buttons and contract
      if (this.rentalContract.modelId) this.showEditMode = true;
    },

    handlerContentChange(content) {
      this.modelContentMutated = content;
    },

    editContent() {
      this.editmode = true;
    },

    contentOkHandler() {
      this.modelContentLoaded = this.modelContentMutated;
      this.getContent();

      this.$store.commit("setContractField", {
        content: this.modelContentLoaded,
      });
      this.$store.commit("updateLocalStorage");

      this.editmode = false;
    },

    contentCancelHandler() {
      this.modelContentMutated = this.modelContentLoaded;
      this.editmode = false;
    },

    handleContractContentSave() {
      this.$emit("saveContractContent", this.modelContentMutated);
    },
  },
};
</script>

<style scoped>
div >>> h1 {
  font-size: 2.4em;
  line-height: 1.4em;
  font-weight: 600;
  margin-bottom: 15px;
}

div >>> h2 {
  line-height: 1.5em;
  margin-bottom: 10px;
}

div >>> h3 {
  line-height: 1.4em;
  margin-bottom: 5px;
}

div >>> .text-center {
  text-align: center;
}

div >>> p {
  line-height: 1.35em;
  white-space: pre-line;
}

div >>> p:empty {
  height: 0.5em;
}

div >>> .grid-container {
  grid-template-columns: auto auto;
  display: grid;
  width: 800px;
}

div >>> .grid-item {
  margin-right: 20px;
  text-align: left;
  width: 350px;
}

@media only screen and (max-width: 800px) {
  div >>> .grid-container {
    grid-template-columns: auto;
  }
  div >>> .grid-item {
    margin-right: 10px;
  }
  div >>> p {
    font-size: 12px;
  }
  div >>> h1 {
    font-size: 1.35em;
  }
  div >>> h2 {
    font-size: 1.2em;
  }
  div >>> h3 {
    font-size: 1em;
  }
  div >>> .variable {
    font-size: 12px;
  }
}
</style>
