<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h2>Toimeksiantajan tiedot</h2>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- COL1 -->
      <v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>{{ p.isCompany ? "Yritys" : "Henkilö" }}</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Nimi:</span>
              <strong class="right">{{ p.name }}</strong>
            </p>
            <p v-if="socialOrBusiness" class="info-wrapper">
              <span class="left">{{ p.isCompany ? "Y-tunnus" : "Henkilötunnus" }}:</span>
              <strong class="right">{{ socialOrBusiness }}</strong>
            </p>
            <p v-if="p.phone" class="info-wrapper">
              <span class="left">Puhelinnumero:</span>
              <strong class="right">{{ p.phone }}</strong>
            </p>
            <p v-if="p.email" class="info-wrapper">
              <span class="left">Email:</span>
              <strong class="right link" @click="mailTo([p.email])">{{ p.email }}</strong>
            </p>
            <p v-if="p.address" class="info-wrapper">
              <span class="left">Postiosoite:</span>
              <strong class="right">{{ p.address }}</strong>
            </p>
            <p v-if="p.zipCode" class="info-wrapper">
              <span class="left">Postinumero:</span>
              <strong class="right">{{ p.zipCode }}</strong>
            </p>
            <p v-if="p.city" class="info-wrapper">
              <span class="left">Postitoimipaikka:</span>
              <strong class="right">{{ p.city }}</strong>
            </p>
            <p v-if="p.country" class="info-wrapper">
              <span class="left">Maa:</span>
              <strong class="right">{{ p.country }}</strong>
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!-- COL 2 -->
      <v-col v-if="p.comments" cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-sm-1" dense>
          <v-col cols="12">
            <h3>Laskutus</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p v-if="p.bank" class="info-wrapper">
              <span class="left">Pankki</span>
              <strong class="right">{{ p.bank }}</strong>
            </p>
            <p v-if="p.iban" class="info-wrapper">
              <span class="left">Tilinumero</span>
              <strong class="right">{{ p.iban }}</strong>
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-sm-1" dense>
          <v-col cols="12">
            <h3>Lisätiedot</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper-noborder" style="white-space: pre-line">
              {{ p.comments }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "@/mixins/mixins";
export default {
  props: ["sm", "md", "lg", "xl"],

  mixins: [mixins],

  computed: {
    p() {
      return this.$store.state.partner.currentPartner;
    },

    socialOrBusiness() {
      if (this.p.isCompany) {
        return this.p.businessId || "Ei määritelty";
      } else {
        return this.p.social || "Ei määritelty";
      }
    },
  },
};
</script>

<style scoped>
.info-wrapper {
  display: flex;
  border-bottom: 1px solid #d4d4d4;
}

.info-wrapper-noborder {
  display: flex;
}

.left {
  width: 140px;
}

.right {
  flex: 1;
  text-align: right;
}

.link {
  color: var(--v-info-base);
  cursor: pointer;
}
</style>
