<template>
  <v-dialog v-model="dialog" :persistent="loading" width="600">
    <v-card>
      <v-card-title>Tarkista maksut</v-card-title>
      <!-- Last check -->
      <v-card-text>
        <div>
          <div v-if="stats.outgoingPayments.updatedAt">
            <p>Viimeisin tarkastus: {{ formatDateAndTime(stats.outgoingPayments.updatedAt) }}</p>

            <!-- Dates -->
            <p
              v-if="stats.outgoingPayments.dates.startDate && stats.outgoingPayments.dates.endDate"
            >
              Tarkistettu aikaväliltä {{ formatDate(stats.outgoingPayments.dates.startDate) }} -
              {{ formatDate(stats.outgoingPayments.dates.endDate) }}
            </p>

            <p v-if="stats.outgoingPayments.latestPaymentIdentifier">
              Maksutunnus: {{ stats.outgoingPayments.latestPaymentIdentifier }}
            </p>
          </div>

          <span v-else>Ei vielä tarkistettu</span>
        </div>

        <div v-if="!nordigenAuthorized && isAuthorized('openBanking', 'create')">
          <WarningComponent class="mt-1">
            Käyttääksesi Nordigen open banking-rajapintaa, sinun täytyy valtuuttaa tai
            uudelleenvaltuuttaa tilisi.
            <router-link :to="'/open-banking/nordigen'" style="text-decoration: none">
              Siirry valtuutukseen tästä
            </router-link>
          </WarningComponent>
        </div>
      </v-card-text>

      <div v-show="!loading">
        <v-card-text>
          <v-form ref="form">
            <h3>Tarkasteluväli</h3>

            <v-row dense>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateFormatted"
                      outlined
                      dense
                      label="Alkaen"
                      v-bind="attrs"
                      :rules="validations.required"
                      v-on="on"
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.startDate"
                    :allowed-dates="allowedDates"
                    first-day-of-week="1"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                  ref="endMenu"
                  v-model="endMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDateFormatted"
                      outlined
                      dense
                      label="Päättyen"
                      v-bind="attrs"
                      :rules="validations.required"
                      v-on="on"
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.endDate"
                    :allowed-dates="allowedDates"
                    first-day-of-week="1"
                    @input="endMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- IF OPEN BANKING IN USE -->
            <div v-if="nordigenAuthorized">
              <v-row dense class="mb-1">
                <v-col>
                  <v-checkbox
                    v-model="useNordigen"
                    style="margin-top: 0"
                    label="Käytä open banking rajapintaa"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <div v-if="useNordigen">
                <label>Miltä pankkitililtä tapahtumat haetaan?</label>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="bankAccount"
                      :loading="loadingBankAccounts"
                      :items="bankAccounts"
                      outlined
                      dense
                      item-value="nordigenAccountId"
                      item-text="iban"
                      label="Pankkitili"
                      :rules="validations.required"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div v-if="!useNordigen || !nordigenAuthorized">
              <h3 class="mt-1">Tiliote</h3>
              <v-row dense>
                <v-col>
                  <v-file-input
                    v-model="file"
                    show-size
                    label="Valitse tiedosto"
                    accept=".csv"
                    :rules="validations.required"
                    outlined
                    dense
                  ></v-file-input>
                </v-col>
              </v-row>
            </div>

            <label>Mitkä lähtevät maksut tarkistetaan?</label>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="status"
                  item-text="text"
                  item-value="val"
                  outlined
                  dense
                  :rules="validations.required"
                  hide-details
                  :items="[
                    { text: 'Uudet', val: 'created' },
                    { text: 'Tarkistetut', val: 'updated' },
                  ]"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <small class="error--text">Max. {{ maxSize }} kB / {{ maxSize / 1000 }} MB</small>
          <br />
          <small class="error--text">.csv</small>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click.prevent="upload()">Tarkista maksut</v-btn>
        </v-card-actions>
      </div>

      <!-- LOADING -->
      <div v-show="loading">
        <v-card-text class="text-center">
          <h3 class="mb-2">Odota hetkinen, tapahtumia käsitellään...</h3>
          <v-progress-circular
            class="text-center mb-2"
            :value="100"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import validations from "@/validations";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    loanPayments: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: { type: String, default: "" },
    document: { type: String, default: "" },
  },

  data() {
    return {
      loading: false,
      startMenu: false,
      endMenu: false,
      useNordigen: false,
      bankAccount: null,
      dates: {
        startDate: "",
        endDate: "",
      },
      startDateFormatted: "",
      endDateFormatted: "",
      status: "created",
      file: undefined,
      maxSize: 3000,
      allowedTypes: ["text/csv"],
      required: [(v) => !!v || "Pakollinen kenttä"],
      validations,
    };
  },

  computed: {
    ...mapState("statistics", ["stats"]),
    ...mapState("account", ["currentUser"]),
    ...mapState("nordigen", ["bankAccounts", "loadingBankAccounts"]),

    nordigenAuthorized() {
      return this.currentUser.currentAccount?.settings?.openBanking?.nordigen?.authorized;
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    "dates.startDate"() {
      this.startDateFormatted = this.formatDate(this.dates.startDate);
    },
    "dates.endDate"() {
      this.endDateFormatted = this.formatDate(this.dates.endDate);
    },

    value(val) {
      if (val) {
        if (
          this.nordigenAuthorized &&
          this.bankAccounts.length == 0 &&
          this.currentUser?.currentAccount?.settings?.openBanking?.nordigen?.accounts.length > 0
        ) {
          this.setLoading({ field: "loadingBankAccounts", val: true });
          this.getAccountDetails(
            this.currentUser.currentAccount.settings.openBanking.nordigen.accounts
          );
        }
      }
    },

    async dialog(val) {
      if (val) {
        try {
          await this.getStats();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },

  methods: {
    ...mapActions("outgoingpayment", ["checkTransactionFile", "checkTransactionOpenBanking"]),
    ...mapActions("statistics", ["getStats"]),
    ...mapActions("nordigen", ["getAccountDetails"]),
    ...mapMutations("nordigen", ["setLoading"]),

    async upload() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.useNordigen) {
            await this.checkTransactionOpenBanking({
              dates: {
                startDate: this.dates.startDate,
                endDate: this.dates.endDate,
              },
              status: this.status,
              accountId: this.bankAccount,
            });
          } else {
            if (this.file.size / 1000 > this.maxSize)
              return this.showPopup("Tiedosto on liian suuri.", "error");

            if (!this.allowedTypes.includes(this.file.type)) {
              return this.showPopup("Tiedostomuoto ei kelpaa. Lataa .csv tiedosto.", "error");
            }

            const formData = new FormData();
            formData.append("file", this.file);

            await this.checkTransactionFile({
              formData,
              status: this.status,
              dates: this.dates,
            });
            this.loading = false;

            this.file = undefined;
            this.$refs.form.resetValidation();
          }

          setTimeout(() => {
            this.$router.go();
          }, 1500);
        } catch (err) {
          this.showPopup(err, "error");
          this.loading = false;
        }
      }
    },

    allowedDates(val) {
      const date = new Date(val);
      const today = new Date();
      if (date > today) return false;
      else return true;
    },
  },
};
</script>

<style scoped>
.v-card__title .headline {
  font-weight: 300;
}
</style>
