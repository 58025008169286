<template>
  <v-app class="app">
    <component :is="currentLayout" v-if="isRouterLoaded">
      <router-view />
    </component>

    <v-snackbars :objects.sync="objects" top center>
      <template #action="{ close }">
        <v-btn text @click="close()">Sulje</v-btn>
      </template>
    </v-snackbars>
  </v-app>
</template>

<script>
import defaultLayout from "@/layouts/Default";
import authLayout from "@/layouts/Auth";
import fullLayout from "@/layouts/Full";
import { snackbarBus } from "./main";
import mixins from "./mixins/mixins";
import VSnackbars from "v-snackbars";

export default {
  name: "App",

  mixins: [mixins],

  data() {
    return {
      objects: [],
    };
  },

  components: {
    defaultLayout,
    authLayout,
    fullLayout,
    VSnackbars,
  },

  created() {
    snackbarBus.$on("createsnackbar", this.showSnackbar);

    window.addEventListener("popstate", (event) => {
      this.stayOnSamePageAndTab(event);
    });
  },

  methods: {
    showSnackbar(obj) {
      this.objects.push({
        message: obj.text,
        color: obj.color,
        timeout: obj.timeOut,
      });
    },
  },

  computed: {
    isRouterLoaded: function () {
      if (this.$route.name !== null) return true;
      return false;
    },

    currentLayout: function () {
      const layout = this.$route.meta.layout || "default";
      return layout + "Layout";
    },
  },
};
</script>

<style scoped></style>
