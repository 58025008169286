<template>
  <v-dialog v-model="dialog" width="1920">
    <v-card>
      <v-container fluid>
        <v-toolbar flat height="30">
          <v-card-title class="pa-0 ma-0">Luo uusi toimeksiantaja</v-card-title>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <partner-form submit-btn-text="Luo" @submit="submit"> </partner-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import PartnerForm from "@/components/Partner/PartnerForm";
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],
  props: { value: { type: Boolean, default: false } },

  components: {
    PartnerForm,
  },

  computed: {
    ...mapState("partner", ["currentPartner"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("partner", ["createPartner"]),

    async submit() {
      try {
        await this.createPartner(this.currentPartner);
        this.dialog = false;
        this.showPopup("Uusi toimeksiantaja luotu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style></style>
