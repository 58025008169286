<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title>Valitse vuokrasopimustyyppi</v-card-title>

      <v-card-text>
        <v-row dense>
          <!-- NEW RENTAL CONTRACT-->
          <v-col cols="12" sm="6">
            <v-card class="mx-auto fill-height card-container" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Allekirjoitettava sopimus</div>

                  <v-list-item-title class="text-h5 mb-1">Uusi vuokrasopimus</v-list-item-title>
                  <v-list-item-subtitle
                    >Uusi vuokrasopimus luodaan, kun luodaan sopimus, jota ei ole vielä olemassa ja
                    joka on tarkoitus allekirjoittaa. Sopimuksesta saa pdf-tulosteen.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn color="info" @click="createContract">Luo uusi</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- OLD RENTAL CONTRACT -->
          <v-col cols="12" sm="6">
            <v-card class="mx-auto fill-height card-container" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Aikaisemmin allekirjoitettu sopimus</div>

                  <v-list-item-title class="text-h5 mb-1"
                    >Voimassa oleva vuokrasopimus</v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Olemassa oleva vuokrasopimus luodaan, kun siirretään jo aikaisemmin luotu,
                    voimassa oleva ja allekirjoitettu sopimus järjestelmään. Sopimuksesta ei saa
                    pdf-tulostetta.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn
                  color="info"
                  @click="
                    dialog = false;
                    $router.push(`/rental-contract/create-old`);
                  "
                >
                  Luo voimassa oleva
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "@/mixins/mixins";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    async createContract() {
      try {
        // If no models, dont let user enter the page
        let res = await axiosMethods.get("/api/v1/contract-model/getAll?search=rentalContract");

        if (res.data.data.length > 0) {
          this.dialog = false;
          this.$router.push("/rental-contract/create");
        } else
          this.showPopup(
            "Luo ensin vähintään yksi sopimuspohja, jotta voit luoda vuokrasopimuksen. Luo sopimuspohja kohdasta sopimukset -> sopimuspohjat.",
            "error"
          );
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal !important;
  -webkit-line-clamp: unset !important;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.v-card-actions {
  margin-top: auto;
}
</style>
