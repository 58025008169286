import tenantService from "../../services/tenant.service";
import _ from "lodash";
import { defaultTenant } from "@/configs/defaultState";
import { showPopup } from "../../utils/helpers";

const state = {
  tenants: [],
  activeTenants: [],
  keyHolderTenants: [],
  totalLength: 0,
  currentTenant: { keys: [] },
  loading: false,
  trackedFields: {},
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  addAllTenants(state, data) {
    if (data.value == "keyHolders") {
      state.keyHolderTenants = data.tenants;
    } else {
      state.tenants = data.tenants;
      state.totalLength = data.totalLength;
    }
  },

  setDefaultValuesToTrackedFields(state, fieldObj) {
    state.trackedFields = fieldObj;
  },

  setCurrentValuesToTrackedFields(state, obj) {
    updateTrackedFields(state.trackedFields, obj);
  },

  addActiveTenants(state, tenants) {
    state.activeTenants = [...tenants, ...state.activeTenants].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  inActivateTenant(state, id) {
    state.tenants = state.tenants.filter((el) => el._id != id);
  },

  setDefaultTenant(state, accountState) {
    state.currentTenant = _.cloneDeep(defaultTenant);
    if (accountState?.currentUser?.currentAccount?.settings?.personalRefNumInUse) {
      state.currentTenant.personalRefNumInUse = true;
    } else {
      state.currentTenant.personalRefNumInUse = false;
    }
  },

  setCurrentTenant(state, tenant) {
    state.currentTenant = { ...tenant };
  },

  setTenantField(state, obj) {
    state.currentTenant = _.set(state.currentTenant, obj.field, obj.val);
  },

  addAndReplaceTenantKey(state, key) {
    state.currentTenant.keys.splice(
      state.currentTenant.keys.findIndex((x) => x._id == key._id),
      1,
      { ...key }
    );
  },
};

const actions = {
  async getAllTenants({ commit }, data) {
    try {
      const res = await tenantService.getAllTenants(data.url);

      commit("addAllTenants", {
        tenants: res.data.tenants,
        totalLength: res.data.totalLength,
        value: data.value,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async createTenant(ctx, data) {
    try {
      const res = await tenantService.createTenant(data);
      showDuplicateEmailCount(res.data.duplicateTenantEmails);
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateTenant(ctx, data) {
    try {
      const res = await tenantService.updateTenant(data);
      showDuplicateEmailCount(res.data.duplicateTenantEmails);

      if (res.data.data.isOnlyKeyHolder) {
        showPopup("Vuokralainen tallennettu ja siirretty avaimenhaltijoihin", "success");
      } else {
        showPopup("Vuokralainen tallennettu", "success");
      }
    } catch (err) {
      throw new Error(err);
    }
  },

  async searchTenant({ commit }, url) {
    try {
      const res = await tenantService.searchTenant(url);
      commit("addActiveTenants", res.data.tenants);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  getTenantsById({ commit }, ids) {
    return tenantService
      .getTenantsById(ids)
      .then((res) => {
        commit("addActiveTenants", res.data.tenants);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async getOneTenant({ commit }, url) {
    try {
      const res = await tenantService.getOneTenant(url);
      commit("setCurrentTenant", res.data.tenant);
    } catch (err) {
      throw new Error(err);
    }
  },

  async inActivateTenant({ commit }, id) {
    try {
      await tenantService.inActivateTenant(id);
      commit("inActivateTenant", id);
      showPopup("Vuokralainen poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async addTenantKeys({ commit }, data) {
    try {
      const res = await tenantService.addTenantKeys(data);
      commit("setCurrentTenant", res.data.tenant);
      showPopup("Avaimet lisätty", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async removeKeyFromTenant({ commit }, data) {
    try {
      const res = await tenantService.removeKeyFromTenant(data);

      commit("setCurrentTenant", res.data.tenant);
      showPopup("Avain poistettu käyttäjältä", "success");
    } catch (err) {
      showPopup(err, " error");
    }
  },
};

// Helper
function showDuplicateEmailCount(duplicateEmails) {
  if (duplicateEmails.length > 0) {
    showPopup(
      `Tallennettaessa löytyi päällekkäisiä vuokralaisten emaileja: \n ${duplicateEmails
        .map((result) => `${result.email}: ${result.count} kpl`)
        .join(", \n")}`,
      "info",
      -1
    );
  }
}

function updateTrackedFields(trackedFields, currentContract) {
  for (const key of Object.keys(trackedFields)) {
    if (key in currentContract) {
      if (
        typeof currentContract[key] === "object" &&
        currentContract[key] !== null &&
        !Array.isArray(currentContract[key])
      ) {
        // Recursively update nested objects
        updateTrackedFields(trackedFields[key], currentContract[key]);
      } else {
        // Set the value
        trackedFields[key].current = currentContract[key];
      }
    }
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
