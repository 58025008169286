<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" class="d-flex justify-center">
        <h2 class="mb-2">Vuokralaiset</h2>
      </v-col>

      <!-- Create new tenant -->
      <v-col cols="12" class="mb-2 d-flex justify-center">
        <v-btn color="info" small @click="openTenantDialog">Luo uusi vuokralainen</v-btn>
      </v-col>
    </v-row>

    <v-form ref="form">
      <v-row dense class="justify-center"> </v-row>

      <!-- TENANTS -->
      <v-row dense :class="otherTenants.length < 3 ? 'justify-center' : 'justify-start'">
        <v-col cols="12" sm="10" md="6" lg="5" xl="4">
          <v-col cols="12">
            <label><strong>Päävuokralainen</strong></label>
            <v-autocomplete
              :value="tenant.tenantId"
              :items="activeTenants"
              :item-text="tenantNameAndGovid"
              item-value="_id"
              no-data-text="Vuokralaisen nimi"
              hint="Valitse olemassa olevista vuokralaisista"
              :persistent-hint="tenant.tenantId ? false : true"
              :hide-details="tenant.tenantId ? true : false"
              outlined
              dense
              small-chips
              return-object
              @change="setPrincipalTenant($event)"
              @input.native="getTenants"
              :rules="validations.required"
            >
            </v-autocomplete>
          </v-col>

          <template v-if="tenant.tenantId">
            <v-col cols="12">
              <label>Vuokrasopimustyyppi</label>
              <v-radio-group
                v-model="tenant.tenantType"
                :mandatory="true"
                style="padding-top: 0; margin-top: 10px"
                hide-details
              >
                <v-radio label="Ensisijainen vuokralainen" value="first"></v-radio>
                <v-radio label="Toissijainen vuokralainen" value="second"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <label>Asiakastyyppi</label>
              <v-radio-group
                v-model="tenant.isCompany"
                :mandatory="true"
                style="padding-top: 0; margin-top: 10px"
                hide-details
              >
                <v-radio label="Yksityinen" :value="false"></v-radio>
                <v-radio label="Yritys" :value="true"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col v-if="!tenant.isCompany" cols="12">
              <label>Vuokralaisella on suomalainen henkilötunnus</label>
              <v-radio-group
                v-model="tenant.finnishCitizen"
                :mandatory="true"
                style="padding-top: 0; margin-top: 10px"
                hide-details
              >
                <v-radio label="Kyllä" :value="true"></v-radio>
                <v-radio label="Ei" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <label>Nimi</label>
              <v-text-field
                v-model="tenant.name"
                :rules="validations.required"
                outlined
                dense
                disabled
                hide-details
              />
            </v-col>

            <v-col v-if="!tenant.isCompany" cols="12">
              <label>Henkilötunnus</label>
              <v-text-field
                v-model.trim="tenant.social"
                :rules="[requiredSocial(tenant)]"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col v-if="tenant.isCompany" cols="12">
              <label>Y-tunnus</label>
              <v-text-field
                v-model="tenant.businessId"
                :rules="validations.required"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Postiosoite</label>
              <v-text-field
                v-model="tenant.address"
                :rules="validations.required"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Postinumero</label>
              <v-text-field
                v-model="tenant.zipCode"
                :rules="validations.required"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Kaupunki</label>
              <v-text-field
                v-model="tenant.city"
                :rules="validations.required"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Puhelin</label>
              <v-text-field
                v-model="tenant.phone"
                :rules="validations.required"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Sähköposti</label>
              <v-text-field
                v-model="tenant.email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Mukana muuttavien tiedot</label>
              <v-textarea v-model="tenant.others" outlined dense hide-details />
            </v-col>

            <v-col cols="12">
              <label>Yhteyshenkilön nimi</label>
              <v-text-field v-model="tenant.contactPersonName" outlined dense hide-details />
            </v-col>

            <v-col cols="12">
              <label>Yhteyshenkilön numero</label>
              <v-text-field v-model="tenant.contactPersonPhone" outlined dense hide-details />
            </v-col>
          </template>
        </v-col>

        <v-col
          v-for="(tenant, idx) in otherTenants"
          :key="idx + 'a'"
          cols="12"
          sm="10"
          md="6"
          lg="5"
          xl="4"
        >
          <v-col cols="12">
            <label
              ><strong>Vuokralainen {{ idx + 2 }}</strong>
              <span
                class="ml-2 error--text"
                @click="deleteOtherTenant(idx)"
                style="font-size: 15px; cursor: pointer"
                >Poista
              </span>
            </label>

            <v-autocomplete
              :value="tenant.tenantId"
              :items="activeTenants"
              item-text="name"
              item-value="_id"
              no-data-text="Vuokralaisen nimi"
              hint="Valitse olemassa olevista vuokralaisista"
              :persistent-hint="tenant.tenantId ? false : true"
              :hide-details="tenant.tenantId ? true : false"
              outlined
              dense
              small-chips
              return-object
              @change="setOtherTenant($event, idx)"
              @input.native="getTenants"
              :rules="validations.required"
            >
            </v-autocomplete>
          </v-col>

          <template v-if="tenant.tenantId">
            <v-col cols="12">
              <label>Asiakastyyppi</label>
              <v-radio-group
                v-model="tenant.isCompany"
                :mandatory="true"
                style="padding-top: 0; margin-top: 10px"
                hide-details
                @change="updateTenantData($event, { ...tenant }, idx)"
              >
                <v-radio label="Yksityinen" :value="false"></v-radio>
                <v-radio label="Yritys" :value="true"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col v-if="!tenant.isCompany" cols="12">
              <label>Vuokralaisella on suomalainen henkilötunnus</label>
              <v-radio-group
                v-model="tenant.finnishCitizen"
                :mandatory="true"
                style="padding-top: 0; margin-top: 10px"
                hide-details
                @change="updateOtherTenants"
              >
                <v-radio label="Kyllä" :value="true"></v-radio>
                <v-radio label="Ei" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <label>Nimi</label>
              <v-text-field
                v-model="tenant.name"
                :rules="validations.required"
                outlined
                dense
                disabled
                hide-details
                @change="updateOtherTenants"
              />
            </v-col>

            <v-col v-if="!tenant.isCompany" cols="12">
              <label>Henkilötunnus</label>
              <v-text-field
                :value="tenant.social"
                :rules="[requiredSocial(tenant)]"
                outlined
                dense
                hide-details
                @input="updateTenantGovid($event, { ...tenant }, idx)"
                @change="updateOtherTenants"
              />
            </v-col>

            <v-col v-if="tenant.isCompany" cols="12">
              <label>Y-tunnus</label>
              <v-text-field
                v-model.trim="tenant.businessId"
                :rules="validations.required"
                outlined
                dense
                @change="updateOtherTenants"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <label>Osoite</label>
              <v-text-field
                v-model="tenant.address"
                outlined
                dense
                @change="updateOtherTenants"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <label>Postinumero</label>
              <v-text-field
                v-model="tenant.zipCode"
                outlined
                dense
                @change="updateOtherTenants"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <label>Kaupunki</label>
              <v-text-field
                v-model="tenant.city"
                outlined
                dense
                @change="updateOtherTenants"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <label>Puhelin</label>
              <v-text-field
                v-model="tenant.phone"
                outlined
                dense
                @change="updateOtherTenants"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <label>Sähköposti</label>
              <v-text-field
                v-model="tenant.email"
                :rules="validations.email2"
                outlined
                dense
                @change="updateOtherTenants"
                hide-details
              ></v-text-field>
            </v-col>
          </template>
        </v-col>
      </v-row>

      <v-row dense no-gutters class="justify-center mt-3">
        <v-btn color="primary" @click="addOtherTenant">Lisää vuokralainen</v-btn>
      </v-row>
    </v-form>

    <v-row dense class="justify-center">
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-checkbox
          v-model="saveTenantInformation"
          hide-details
          label="Tallenna vuokralaisten muuttuneet tiedot asiakashallintaan"
        ></v-checkbox>
      </v-col>
    </v-row>

    <!-- Create tenant dialog -->
    <create-tenant-dialog v-model="newTenantDialog" />
  </v-container>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import { eventBus } from "../../main";
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";
import { mapActions, mapState, mapMutations } from "vuex";
import CreateTenantDialog from "@/components/Tenant/CreateTenantDialog";
import validations from "@/validations";

export default {
  mixins: [mixins],

  components: {
    CreateTenantDialog,
  },

  data() {
    return {
      newTenantDialog: false,
      tenants: [],
      tenantInput: "",

      validations,
    };
  },

  computed: {
    ...mapState("tenant", ["activeTenants"]),

    tenant() {
      return this.$store.state.rentalContract.tenant;
    },

    otherTenants: {
      get() {
        return this.$store.state.rentalContract.otherTenants;
      },
      set(arr) {
        this.$store.commit("setContractField", {
          otherTenants: arr,
        });
      },
    },

    saveTenantInformation: {
      get() {
        return this.$store.state.saveTenantInformation;
      },
      set(val) {
        this.$store.state.saveTenantInformation = val;
      },
    },

    showOnlyOnCreate() {
      return this.$route.name == "Luo vuokrasopimus" ? true : false;
    },
  },

  watch: {
    "tenant.social": function () {
      if (this.tenant.social) {
        const formattedSocial = this.tenant.social.replace(/[a-z]/g, (match) =>
          match.toUpperCase()
        );
        this.tenant.social = formattedSocial;
      }
    },

    "tenant.isCompany"(val) {
      if (val) {
        this.tenant.social = null;
      } else {
        this.tenant.businessId = null;
      }
    },

    tenant: {
      deep: true,
      handler() {
        this.$store.commit("updateLocalStorage");
      },
    },

    saveTenantInformation() {
      this.$store.commit("updateLocalStorage");
    },

    otherTenants: {
      deep: true,
      handler() {
        this.$store.commit("updateLocalStorage");
      },
    },

    tenantInput: debounce(function (newVal) {
      this.searchTenant(`/api/v1/tenant/search-tenant?search=${newVal}`);
    }, 1000),
  },

  async created() {
    this.$root.$refs.tenant = this;
  },

  methods: {
    ...mapActions("tenant", ["searchTenant"]),
    ...mapMutations("tenant", ["setDefaultTenant"]),

    openTenantDialog() {
      this.setDefaultTenant(this.$store.state.account);
      this.newTenantDialog = true;
    },

    tenantNameAndGovid(item) {
      const govid = item.isCompany ? item.businessId || "" : item.social || "";
      return `${item.name} (${govid})`;
    },

    setPrincipalTenant(tenant) {
      if (tenant) {
        const obj = { ...tenant, tenantId: tenant._id };
        delete obj._id;
        this.$store.state.rentalContract.tenant = obj;
      }
    },

    setOtherTenant(tenant, index) {
      if (tenant) {
        const obj = { ...tenant, tenantId: tenant._id };
        delete obj._id;
        this.otherTenants.splice(index, 1, obj);
      }
    },

    getTenants(event) {
      this.tenantInput = event.target.value;
    },

    updateTenantData(isCompany, tenant, index) {
      if (isCompany) {
        tenant.social = null;
      } else {
        tenant.businessId = null;
      }

      this.otherTenants.splice(index, 1, tenant);
    },

    updateTenantGovid(event, tenant, index) {
      if (!tenant.isCompany) {
        const formattedSocial = event
          ? event.replace(/[a-z]/g, (match) => match.toUpperCase()).trim()
          : null;
        tenant.social = formattedSocial;
      }

      this.otherTenants.splice(index, 1, tenant);
    },

    updateOtherTenants() {
      this.$store.commit("updateLocalStorage");
    },

    addOtherTenant() {
      let otherTenants = this.otherTenants;
      otherTenants.push({
        isCompany: false,
        name: "",
        social: null,
        businessId: null,
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        email: "",
        tenantId: null,
      });
      this.otherTenants = [...otherTenants];
    },

    deleteOtherTenant(index) {
      this.otherTenants.splice(index, 1);
    },

    requiredSocial(tenant) {
      return (val) => {
        const social = val || null;
        if (!tenant.finnishCitizen) {
          return true;
        } else {
          return !social ? false : true;
        }
      };
    },

    checkIfValid() {
      eventBus.$emit("checktenant", this.$refs.form.validate());
    },

    async getTenant(id) {
      try {
        let res = await axiosMethods.get("/api/v1/tenant/getOne/" + id);

        let tenant = { ...res.data.tenant, tenantId: res.data.tenant._id };
        this.$store.commit("setContractField", { tenant: tenant });
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.details-container {
  display: flex;
}
</style>
