<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Toimeksiantaja</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <!-- Loader -->
      <v-container fluid v-if="loading">
        <full-page-loader
          v-if="loading"
          text="Ladataan toimeksiantajan tietoja..."
          class="full-page-loader"
        ></full-page-loader>
      </v-container>

      <!-- Content -->
      <v-card-title v-if="!loading">
        <p>{{ currentPartner.name }} <span v-if="!currentPartner.active">(poistettu)</span></p>
        <v-spacer></v-spacer>
        <router-link :to="`/partner/edit/${$route.params.id}`">
          <v-btn color="primary">Muokkaa</v-btn>
        </router-link>
      </v-card-title>

      <v-container fluid v-if="!loading">
        <!-- Tabs -->
        <v-row dense>
          <v-col>
            <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
              <v-tabs-slider color="parimary"></v-tabs-slider>

              <v-tab v-for="item in computedTabs" :key="item.text">
                <div>{{ item }}</div>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <!-- Components -->
        <partner-info v-if="tab == 0" sm="12" md="5" lg="5" xl="3"></partner-info>
        <partner-rental-contracts v-if="tab == 1"></partner-rental-contracts>
        <partner-invoices v-if="tab == 2"></partner-invoices>
        <partner-outgoing-payments v-if="tab == 3"></partner-outgoing-payments>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import PartnerInfo from "../../components/Partner/PartnerInfo";
import PartnerRentalContracts from "../../components/Partner/PartnerRentalContracts";
import PartnerInvoices from "../../components/Partner/PartnerInvoices";
import PartnerOutgoingPayments from "../../components/Partner/PartnerOutgoingPayments";
import mixins from "../../mixins/mixins";
import FullPageLoader from "@/components/FullPageLoader.vue";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  title: "Toimeksiantajan hallinta",

  components: {
    PartnerInfo,
    PartnerRentalContracts,
    PartnerInvoices,
    PartnerOutgoingPayments,
    FullPageLoader,
  },

  mixins: [mixins],

  data() {
    return {
      search: "",
      tab: 0,
      tabs: ["Yhteenveto", "Vuokrasopimukset", "Laskut", "Lähtevät maksut"],
    };
  },

  created() {
    this.getPartner();
  },

  watch: {
    "$route.params.id": {
      handler: function () {
        this.getPartner();
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapState("partner", ["currentPartner", "loading"]),
    ...mapState("account", ["currentUser"]),

    computedTabs() {
      return this.tabs.filter((tab) => {
        if (this.currentUser.currentAccount.settings.outgoingPayments) {
          return tab;
        } else {
          return tab != "Lähtevät maksut";
        }
      });
    },
  },

  methods: {
    ...mapActions("partner", ["getOnePartner"]),
    ...mapMutations("partner", ["setLoading"]),

    async getPartner() {
      try {
        this.setLoading(true);
        await this.getOnePartner("/api/v1/partner/getOne/" + this.$route.params.id);
        this.setLoading(false);
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
