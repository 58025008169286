import axiosMethods from "../mixins/axios";

export default {
  async getAllContracts(url) {
    return await axiosMethods.get(url);
  },

  async getTenantRentalContracts(url) {
    return await axiosMethods.get(url);
  },

  async getTenantRentalContractList(id) {
    return await axiosMethods.get("/api/v1/rental-contract/tenant-contract-list/" + id);
  },

  async getPartnerRentalContracts(url) {
    return await axiosMethods.get(url);
  },

  async getFormattedContract(id) {
    return await axiosMethods.get("/api/v1/rental-contract/get-one-formatted/" + id);
  },

  async getOne(id) {
    return await axiosMethods.get("/api/v1/rental-contract/get-one/" + id);
  },

  async searchContract(url) {
    return await axiosMethods.get(url);
  },

  async getRentalContractsById(ids) {
    return await axiosMethods.post("/api/v1/rental-contract/get-contracts-by-id", ids);
  },

  async deleteRentalContract(id) {
    return await axiosMethods.delete("/api/v1/rental-contract/delete/" + id);
  },

  async removeReferenceContract(data) {
    return await axiosMethods.update(
      "/api/v1/rental-contract/reference-contract/" + data.rentalContractId,
      { referenceId: data.referenceId }
    );
  },

  async addTenantToContract(data) {
    return await axiosMethods.update(
      `/api/v1/rental-contract/${data.rentalContractId}/add-tenant-to-contract`,
      data
    );
  },

  async setRevokedDateToTenant(data) {
    return await axiosMethods.update(
      `/api/v1/rental-contract/${data.rentalContractId}/revoke-tenants`,
      { selected: data.selected, revokedDate: data.revokedDate }
    );
  },

  async revertRevokedTenant(data) {
    return await axiosMethods.update(
      `/api/v1/rental-contract/${data.rentalContractId}/revert-revoked-tenant/${data.tenantId}`
    );
  },

  async getContractReport(data) {
    const response = await axiosMethods.post(
      "/api/v1/reports/get-contract-report/" + data.contractId,
      { dates: data.dates, searchType: data.searchType }
    );
    return response.data.data;
  },
};
