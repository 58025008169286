<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Vuokralaiset</div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <router-link style="text-decoration: none" to="/tenant/create">
          <v-btn v-if="isAuthorized('tenant', 'create')" color="info" class="mb-2 mr-1"
            >Uusi vuokralainen</v-btn
          >
        </router-link>
        <v-btn
          v-if="isAuthorized('tenant', 'read')"
          color="primary"
          class="mr-1 mb-2 ml-auto"
          @click="
            downloadDialog = true;
            downloading = false;
          "
        >
          Lataa excel
        </v-btn>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="7">
            <v-row dense>
              <v-col cols="12" sm="11" md="10">
                <v-text-field
                  v-model="search"
                  label="Asiakasnum, nimi, henkilötunnus, email tai puhelin..."
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  solo
                  @keyup.enter="getDataFromApi()"
                  @click:append="getDataFromApi()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="5" lg="4">
                <v-text-field
                  v-model="apartmentSearch"
                  label="Vuokrakohde"
                  no-data-text="Vuokrakohteen osoite"
                  small-chips
                  outlined
                  dense
                  clearable
                  hide-details
                  @keyup.enter="getDataFromApi()"
                  @click:clear="getDataFromApi()"
                >
                </v-text-field>
                <!-- <v-btn class="mt-1" color="info">Hae</v-btn> -->
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="5">
            <v-row dense>
              <v-col cols="12" sm="6">
                <label>Hae vuokrasopimusten mukaan</label>
                <v-radio-group
                  v-model="hasContract"
                  :mandatory="true"
                  style="margin-top: 0px"
                  @change="getDataFromApi()"
                >
                  <v-radio label="Kaikki" value="all"></v-radio>
                  <v-radio
                    class="active_contracts"
                    label="Voimassa olevat"
                    value="contracts"
                  ></v-radio>
                  <v-radio
                    class="released_contracts"
                    label="Irtisanotut"
                    value="releasedContracts"
                  ></v-radio>
                  <v-radio class="no_contracts" label="Ei sopimuksia" value="noContracts"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="tenants"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- Client id -->
        <template #[`item.clientId`]="{ item }">
          <span
            ><strong> # {{ item.clientId }} </strong>
          </span>
        </template>

        <!-- Overview link keyholder -->
        <template #[`item.name`]="{ item }">
          <router-link style="text-decoration: none" :to="`/tenant/overview/${item._id}`">
            <span>
              {{ item.name }}
            </span>
          </router-link>
          <v-icon
            size="15"
            style="margin-bottom: 3px; margin-left: 3px"
            @click="openTenantInfoDialog(item)"
            >mdi-eye-outline</v-icon
          >
        </template>

        <!-- Social or businessId-->
        <template #[`item.socialOrBusiness`]="{ item }">
          <span v-if="item.isCompany && item.businessId">
            {{ item.businessId }}
          </span>
          <span v-else>
            {{ item.social }}
          </span>
        </template>

        <!-- Contract -->
        <template #[`item.contract`]="{ item }">
          <span v-if="getContracts(item).length === 0" class="font-weight-bold error--text"
            >Ei sopimuksia</span
          >

          <span
            v-else-if="
              getTerminatedContracts(getContracts(item)).length > 0 &&
              getActiveContracts(getContracts(item)).length === 0
            "
            class="font-weight-bold draft--text"
            >Ei voimassa olevia sopimuksia</span
          >

          <!-- Active Contracts -->
          <p
            v-else
            v-for="(contract, idx) in getActiveContracts(getContracts(item))"
            :key="'a' + idx"
          >
            <span v-html="createContractText(item, contract)"> </span>

            <router-link
              v-if="isAuthorized('contract', 'read')"
              style="text-decoration: none"
              :class="getContractStateColor(contract.state)"
              :to="`/rental-contract/overview/${contract._id}`"
            >
              <span class="font-weight-bold"> Sop. {{ contract.contractNumber }}</span>
            </router-link>
          </p>
        </template>

        <!-- isProspect  -->
        <template #[`item.isProspect`]="{ item }">
          <span>{{ item.isProspect ? "Potentiaalinen" : "Vuokralainen" }}</span>
        </template>

        <!-- email-->
        <template #[`item.email`]="{ item }">
          <span>{{ item.email ? item.email : "" }}</span>
          <span class="ml-1" v-if="item.email">
            <v-icon small @click="mailTo([item.email])">mdi-email-send-outline</v-icon>
          </span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('tenant', 'read')"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="isAuthorized('tenant', 'delete')"
              title="Poista"
              small
              @click="inactivateItem(item)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei vuokralaisia</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Download dialog -->
    <v-dialog v-model="downloadDialog" :persistent="downloading" width="500">
      <v-card v-if="downloading">
        <v-card-title> Vuokralaisia haetaan </v-card-title>
        <v-card-text>
          <p>Odota hetki...</p>
          <v-progress-circular :value="100" indeterminate color="primary"></v-progress-circular
        ></v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title> Lataa vuokralaiset excel-tiedostoon </v-card-title>
        <v-card-text>
          <v-radio-group v-model="searchByTenantAddress" :mandatory="true" style="margin-top: 0px">
            <v-radio
              label="Lataa kaikki vuokralaiset omilla osoitetiedoilla"
              :value="true"
            ></v-radio>
            <v-radio
              label="Lataa voimassa olevien vuokrasopimusten mukaan vuokratun kohteen osoitetiedoilla"
              :value="false"
            ></v-radio>
          </v-radio-group>
          <v-btn color="primary" @click="download">Lataa</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tenant info dialog -->
    <tenant-info-dialog v-model="tenantInfoDialog" sm="12" md="6" lg="6" />
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import { apiAgent } from "../../services/apiAgent";
import axiosMethods from "@/mixins/axios";
import moment from "moment";
import globalValues from "@/configs/globalValues";
import TenantInfoDialog from "@/components/Tenant/TenantInfoDialog";

export default {
  title: "Vuokralaiset",
  mixins: [mixins],

  components: {
    TenantInfoDialog,
  },

  data: () => ({
    downloadDialog: false,
    downloading: false,
    tenantInfoDialog: false,
    apartmentSearch: "",
    loading: true,
    options: {},
    dialog: false,
    hasContract: "all",
    search: "",
    searchByTenantAddress: true,
    headers: [
      { text: "Asiakasnumero", value: "clientId" },
      { text: "Nimi", value: "name" },
      { text: "Status", value: "isProspect" },
      {
        text: "Henkilö- / Y-tunnus",
        value: "socialOrBusiness",
        sortable: false,
      },
      {
        text: "Puhelin",
        value: "phone",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Sopimukset",
        value: "contract",
        sortable: false,
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("tenant", ["tenants", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("tenant", ["getAllTenants", "inActivateTenant"]),
    ...mapMutations("tenant", ["setCurrentTenant"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      let url = `/api/v1/tenant/getAll?isOnlyKeyHolder=false&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&hasContract=${this.hasContract}&search=${search}&apartment=${this.apartmentSearch}`;

      await this.getAllTenants({ url, value: "tenants" });

      this.loading = false;
    },

    getContracts(item) {
      return [...item.contract, ...item.otherContracts];
    },

    getActiveContracts(contracts) {
      let contrs = [];
      const today = moment().format("YYYY-MM-DD");

      for (let i = 0; i < contracts.length; i++) {
        // If revoked Date, check if contract is already ended
        if (contracts[i].revokedDate && contracts[i].revoked) {
          if (moment(moment(contracts[i].revokedDate).format("YYYY-MM-DD")).isSameOrAfter(today)) {
            contrs.push(contracts[i]);
          }
        } else {
          // Check if lease has endDate
          if (contracts[i].lease.leaseType == "Määräaikainen") {
            if (
              moment(moment(contracts[i].lease.endDate).format("YYYY-MM-DD")).isSameOrAfter(today)
            ) {
              contrs.push(contracts[i]);
            }
          } else {
            contrs.push(contracts[i]);
          }
        }
      }

      return contrs;
    },

    getTerminatedContracts(contracts) {
      // Filter signed and active contracts
      const inActiveContracts = [];

      contracts.forEach((el) => {
        if (el.signed && el.state == "Päättynyt") {
          inActiveContracts.push(el);
        }
      });

      return inActiveContracts;
    },

    createContractText(tenant, contract) {
      const signed = contract.signed;
      const tenantIsRevoked = this.tenantIsRevoked(tenant, contract);
      const address = contract.apartment.address;
      const apartmentNumber = contract.apartment?.apartmentNumber || null;
      const fullAddress = apartmentNumber ? `${address}, ${apartmentNumber}` : address;
      const styledFullAddress = tenantIsRevoked
        ? `<span class="warning--text">${fullAddress}</span>`
        : `${fullAddress}`;

      let html = signed
        ? `<span>${styledFullAddress}</span>`
        : `<span class="draft--text">${styledFullAddress}`;

      const roomsInfo = this.getRooms(contract);

      if (roomsInfo !== "") html += `, (${roomsInfo}), `;
      else html += `, `;

      if (!signed) html += "</span>";
      return html;
    },

    getRooms(contract) {
      const rooms = contract?.apartment?.id?.rooms || [];
      const roomIds = contract.apartment.roomIds || [];

      if (rooms.length === 0 || roomIds.length === 0) return "";
      let rentedRooms = "";

      roomIds.forEach((roomId, idx) => {
        const index = rooms.findIndex((room) => String(room._id) === String(roomId));
        if (index != -1)
          rentedRooms += idx < roomIds.length - 1 ? `${rooms[index].name}, ` : rooms[index].name;
      });

      return rentedRooms;
    },

    tenantIsRevoked(tenant, contract) {
      if (contract && Array.isArray(contract.otherTenants)) {
        const currentTenantId = String(tenant._id);
        if (contract.tenant.revokedDate && String(contract.tenant.tenantId) === currentTenantId)
          return true;
        for (const tenant of contract.otherTenants) {
          if (tenant.revokedDate && String(tenant.tenantId) === currentTenantId) return true;
        }
        return false;
      } else return false;
    },

    openTenantInfoDialog(item) {
      this.setCurrentTenant(item);
      this.tenantInfoDialog = true;
    },

    editItem(item) {
      this.$router.push("/tenant/edit/" + item._id).catch(() => {});
    },

    inactivateItem(item) {
      if (confirm("Haluatko varmasti poistaa vuokralaisen: " + item.name)) {
        this.inActivateTenant(item._id);
      }
    },

    async download() {
      try {
        this.downloadDialog = true;
        this.downloading = true;
        let buffer;

        const res = await apiAgent({
          method: "post",
          url: "/api/v1/tenant/download-tenant-excel",
          data: {
            searchByTenantAddress: this.searchByTenantAddress,
          },
        });

        const additionalInfo = [
          `Vuokralaiset`,
          `Asiakas: ${this.currentUser.currentAccount.name}`,
          `Raportin päivämäärä: ${moment().format("DD.MM.YYYY")}`,
        ];

        if (!this.searchByTenantAddress) {
          const dataString = JSON.stringify(res.data);

          // If error
          if (dataString.includes("ERROR:")) {
            const errorMessage = dataString.split("ERROR: ")[1];
            throw new Error(errorMessage);
          }

          buffer = await this.createExcelBuffer(res.data, additionalInfo, true, false);
        } else {
          buffer = await this.createExcelBuffer(res.data.tenants, additionalInfo, true, false);
        }

        this.downloadExcel(buffer, "Vuokralaiset");
        this.downloading = false;
        this.downloadDialog = false;
      } catch (err) {
        this.downloading = false;
        this.downloadDialog = false;
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls .active_contracts >>> i {
  color: var(--v-success-base) !important;
}
.v-input--selection-controls .released_contracts >>> i {
  color: var(--v-warning-base) !important;
}
.v-input--selection-controls .no_contracts >>> i {
  color: var(--v-error-base) !important;
}
</style>
