import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=c28f41e6&scoped=true"
import script from "./FormBuilder.vue?vue&type=script&lang=js"
export * from "./FormBuilder.vue?vue&type=script&lang=js"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=c28f41e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c28f41e6",
  null
  
)

export default component.exports