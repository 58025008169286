<template>
  <v-menu
    ref="menu"
    v-model="menu"
    class="form-field-menu"
    :close-on-content-click="false"
    min-width="290"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        class="form-field"
        :value="formatDate(form.fields[col.label])"
        :label="currentForm.styles.showNameAbove ? '' : col.name"
        :outlined="col.fieldStyle == 'outlined' ? true : false"
        :solo="col.fieldStyle == 'solo' ? true : false"
        dense
        v-bind="attrs"
        v-on="on"
        clearable
        append-icon="mdi-calendar"
        v-prevent-manual-input
        :readonly="true"
        :rules="col.required ? validations.required : []"
        :style="fieldStyles"
        :hide-details="!currentForm.styles.showError"
        @click:clear="
          deleteDateValue({ label: col.label, rowIndex, colIndex });
          forceUpdate();
        "
      ></v-text-field>
    </template>
    <v-date-picker
      class="form-field-date"
      first-day-of-week="1"
      v-model="form.fields[col.label]"
      :style="fieldStyles"
      @input="menu = false"
      @change="forceUpdate()"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mixins from "@/mixins/mixins";
import validations from "../../validations";

export default {
  props: ["col", "rowIndex", "colIndex", "fieldStyles"],

  mixins: [mixins],

  data() {
    return {
      menu: false,
      validations,
    };
  },

  computed: {
    ...mapState("form", ["currentForm", "form"]),

    formattedDate() {
      return this.formatDate(this.date);
    },
  },

  methods: {
    ...mapMutations("form", ["deleteDateValue"]),

    forceUpdate() {
      this.$emit("forceupdate", "1");
    },
  },
};
</script>

<style scoped src="../../assets/css/formStyles.css"></style>
