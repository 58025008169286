<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title> Vakuudet, sopimus {{ contractNum }}</v-card-title>

      <!-- TABS -->
      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="mb-5" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in tabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <!-- TAB 0 -->

      <v-card-text v-if="tab == 0">
        <v-row justify="center">
          <v-col md="8">
            <v-form ref="form">
              <v-select
                v-model="deposit.name"
                dense
                outlined
                :items="names"
                label="Vakuuden tyyppi"
                :rules="required"
              >
              </v-select>
              <v-divider></v-divider>
              <v-select
                v-model="deposit.depositType"
                dense
                outlined
                :items="depositTypes"
                item-text="text"
                item-value="val"
                label="Talletuksen tapa"
                :rules="required"
              ></v-select>
              <v-divider></v-divider>
              <v-text-field
                v-model.number="deposit.amount"
                dense
                outlined
                type="number"
                step="0.01"
                label="Määrä"
                suffix="€"
                :rules="required"
              ></v-text-field>
              <v-divider></v-divider>
              <v-menu
                ref="payDateMenu"
                v-model="payDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedPayDate"
                    dense
                    outlined
                    v-bind="attrs"
                    :rules="required"
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Saapumispäivä"
                    v-prevent-manual-input
                    :readonly="true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deposit.payDate"
                  first-day-of-week="1"
                  @input="payDateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-divider></v-divider>
              <v-textarea v-model="deposit.comment" label="Kommentti" dense outlined></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="tab == 0">
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="payDeposit()"> Luo </v-btn>
      </v-card-actions>

      <!-- LIST, TAB 1  -->

      <v-card-text v-if="tab == 1" class="card-oldlist">
        <div v-for="(deposit, idx) in deposits" :key="idx">
          <v-row
            style="margin: -15px !important"
            align="center"
            justify="center"
            :class="{ active: !deposit.active }"
          >
            <v-col cols="9">
              <p>
                <strong :class="{ crossout: !deposit.active }">{{ deposit.name }} </strong>
              </p>

              <p>{{ formatDeposit(deposit.depositType) }}</p>

              <p>
                Vastaanotettu: {{ formatCurrency(deposit.amount) }} |
                {{ formatDate(deposit.payDate) }}
              </p>

              <p v-if="deposit.releaseDate && deposit.releaseAmount >= 0">
                Palautettu: {{ formatCurrency(deposit.releaseAmount) }} |
                {{ formatDate(deposit.releaseDate) }}
              </p>

              <p style="white-space: pre">{{ deposit.comment }}</p>
            </v-col>
            <v-col cols="3">
              <div class="action-grid-container" :class="{ active: !deposit.active }">
                <v-icon
                  :color="deposit.active ? 'rgba(0, 0, 0, 0.54)' : 'rgb(151, 151, 151)'"
                  small
                  @click="
                    depositObj = deposit;
                    releaseForm = true;
                  "
                  >mdi-lock-open</v-icon
                >

                <v-icon
                  :color="deposit.active ? 'rgba(0, 0, 0, 0.54)' : 'rgb(151, 151, 151)'"
                  small
                  @click="
                    depositObj = deposit;
                    editForm = true;
                  "
                  >mdi-pencil</v-icon
                >

                <v-icon
                  :color="deposit.active ? 'rgba(0, 0, 0, 0.54)' : 'rgb(151, 151, 151)'"
                  small
                  @click="deleteItem(deposit)"
                  >mdi-delete</v-icon
                >
              </div>
            </v-col>
          </v-row>
          <v-divider v-if="idx < deposits.length - 1" class="mt-1 mb-1"></v-divider>
        </div>
      </v-card-text>
    </v-card>

    <!-- EDITFORM -->
    <deposit-edit-form
      v-model="editForm"
      :depositObj="depositObj"
      :contractId="contractId"
    ></deposit-edit-form>

    <!-- REALEASEFORM -->
    <deposit-release-form
      v-model="releaseForm"
      :depositObj="{ ...depositObj }"
      :contractId="contractId"
      @updateddeposits="updateDeposits($event)"
    ></deposit-release-form>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import DepositEditForm from "./DepositEditForm";
import DepositReleaseForm from "./DepositReleaseForm";

export default {
  components: {
    DepositEditForm,
    DepositReleaseForm,
  },

  props: {
    value: { type: Boolean, default: false },

    contractNum: {
      type: Number,
      default: 0,
    },

    contractId: {
      type: String,
      default: "",
    },

    depositsArr: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  mixins: [mixins],

  data() {
    return {
      tab: 0,
      tabs: ["Uusi", "Vakuudet"],
      editForm: false,
      releaseForm: false,
      payDateMenu: false,
      dateFormattedPayDate: "",
      deposits: [],
      depositObj: {},
      deposit: {
        depositType: "",
        name: "",
        payDate: "",
        amount: null,
        comment: "",
      },
      depositTypes: [
        { text: "Pankkitakaus", val: "bank" },
        { text: "Maksettu vuokranantajan tilille", val: "payment" },
        { text: "Kelan / Sosiaalitoimen maksusitoumus", val: "commitment" },
        { text: "Henkilötakaus", val: "guarantee" },
        { text: "Asiakasvaratili", val: "customerReserveAccount" },
      ],
      names: ["Vuokravakuus", "Avainpantti"],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    "deposit.payDate"() {
      this.dateFormattedPayDate = this.formatDate(this.deposit.payDate);
    },

    depositsArr: function (arr) {
      this.deposits = arr || [];
    },
  },

  methods: {
    payDeposit() {
      if (this.$refs.form.validate()) {
        this.$emit("adddeposit", { ...this.deposit });
        this.$refs.form.reset();
      }
    },

    deleteItem(item) {
      let answer = confirm("Haluatko poistaa vakuuden " + item.name);

      if (answer) {
        this.$emit("deletedeposit", { ...item });
      }
    },

    updateDeposits(items) {
      this.deposits = items;
    },
  },
};
</script>

<style scoped>
.card-oldlist {
  height: 500px;
  overflow-y: scroll;
}

.active {
  color: rgb(179, 179, 179);
}

.crossout {
  text-decoration: line-through;
}
</style>
