<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Luo kohdistumaton hyvityslasku</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>
    <p style="font-size: 14px; margin-top: 3px">
      Kohdistetttu hyvityslasku luodaan alkuperäisen laskun riviltä.
    </p>

    <refund-invoice-form
      :edit="false"
      save-btn-text="Luo hyvityslaskuluonnos"
    ></refund-invoice-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import RefundInvoiceForm from "../../components/Invoice/RefundInvoiceForm";

export default {
  title: "Luo uusi hyvityslasku",

  mixins: [mixins],

  components: {
    RefundInvoiceForm,
  },
};
</script>

<style scoped></style>
