import axiosMethods from "../mixins/axios";

export default {
  async getLink(id) {
    return await axiosMethods.get(`/api/v1/short-link/${id}`);
  },

  async createShortLink(data) {
    return await axiosMethods.post("/api/v1/short-link", data);
  },
};
