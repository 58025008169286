<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <!-- TABS -->
      <v-card-text>
        <v-tabs v-model="tab" color="primary" class="mb-3" fixed-tabs show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab v-for="item in permissionTabs" :key="item.text" :href="`#${item.text}`">
            <div>{{ item.text }}</div>
          </v-tab>
        </v-tabs>
      </v-card-text>

      <v-card-text v-if="tab == 'Lataa'">
        <v-form ref="form">
          <v-file-input
            v-model="file"
            show-size
            label="Valitse tiedosto"
            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
            :rules="required"
            outlined
            dense
          ></v-file-input>
        </v-form>
        <small class="error--text">Max. {{ maxSize * 1000 }} kB / {{ maxSize }} MB</small>
        <br />
        <small class="error--text">.jpg .jpeg .png .pdf .doc .docx</small>
      </v-card-text>

      <!-- OLD LIST -->
      <v-card-text v-if="tab == 'Liitteet'" class="card-oldlist">
        <div v-for="(fil, idx) in files" :key="idx">
          <v-row dense style="margin: -10px">
            <v-col cols="10" class="pt-0 pb-0">
              <small style="font-size: 14px; cursor: pointer">
                <strong class="mr-1">{{ fil.name }}</strong>
                <v-btn v-if="fil.mimeType && fil.mimeType === 'application/pdf'" icon
                  ><v-icon size="18" color="info" @click="download(fil, 'view')"
                    >mdi-eye</v-icon
                  ></v-btn
                >
                <v-btn icon
                  ><v-icon size="18" color="info" @click="download(fil, 'download')"
                    >mdi-download</v-icon
                  ></v-btn
                >
              </small>
            </v-col>
            <v-col cols="2">
              <v-icon small color="error" @click="deleteFile(fil, idx)">mdi-delete</v-icon>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="tab == 'Lataa'" color="info" @click.prevent="upload()">Lataa</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../mixins/mixins";
import axiosMethods from "../mixins/axios";
import { apiAgent } from "../services/apiAgent";
import { mapMutations } from "vuex";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    title: { type: String, default: "" },
    documentId: { type: String, default: "" },
    document: { type: String, default: "" },
    bucket: { type: String, default: "" },
    model: { type: String, default: "" },
  },

  data() {
    return {
      tabs: [
        { text: "Liitteet", resource: "file", permission: "read" },
        { text: "Lataa", resource: "file", permission: "create" },
      ],
      tab: "Liitteet",
      files: [],
      file: undefined,
      maxSize: 5, //Mt
      allowedTypes: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "",
      ],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    permissionTabs() {
      return this.tabs.filter((tab) => {
        if (this.isAuthorized(tab.resource, tab.permission)) {
          return true;
        }
      });
    },
  },

  watch: {
    async documentId() {
      await this.getFiles();
    },
  },

  methods: {
    ...mapMutations("pdfViewer", ["openViewer"]),

    async upload() {
      if (this.$refs.form.validate()) {
        try {
          if (this.file.size / (1024 * 1024) > this.maxSize)
            return this.showPopup("Tiedosto on liian suuri.", "error");

          if (this.files.length >= 10) {
            return this.showPopup(
              "Saat lisättyä maksimissaan kymmenen tiedostoa yhteen sopimukseen.",
              "error"
            );
          }

          if (!this.allowedTypes.includes(this.file.type)) {
            return this.showPopup(
              "Tiedostomuoto ei kelpaa. Lataa .jpg, .jpeg, .png, .doc, .docx, tai .pdf tiedosto.",
              "error"
            );
          }

          const formData = new FormData();
          formData.append("file", this.file, encodeURIComponent(this.file.name));

          let res = await axiosMethods.post(
            `/api/v1/${this.model}/uploadFile/${this.documentId}?model=${this.model}&bucket=${this.bucket}`,
            formData
          );

          this.files.push(res.data.file);
          this.file = undefined;

          // Send to parent
          this.$emit("sendnewfiles", { ...res.data.document });

          this.showPopup("Tiedosto ladattu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async getFiles() {
      try {
        let res;
        if (this.model === "rental-contract")
          res = await axiosMethods.get(`/api/v1/${this.model}/get-one/${this.documentId}`);
        else res = await axiosMethods.get(`/api/v1/${this.model}/getOne/${this.documentId}`);

        this.files = res.data[this.document].files;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async download(file, viewType) {
      try {
        const encodedKey = this.encodeKey(file.key);

        const res = await apiAgent({
          method: "GET",
          url: `/api/v1/${this.model}/getFile?key=${encodedKey}&bucket=${file.bucket}&mimeType=${file.mimeType}`,
          responseType: "blob",
        });

        if (file.mimeType && file.mimeType === "application/pdf" && viewType === "view") {
          this.openViewer({
            data: res.data,
            name: `${file.name || "dokumentti"}`,
          });
        } else {
          this.downloadFile(res.data, decodeURIComponent(file.name));
        }
      } catch (err) {
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async deleteFile(file, i) {
      if (confirm("Haluatko poistaa tiedoston " + file.name + "?")) {
        try {
          const encodedKey = this.encodeKey(file.key);

          let res = await axiosMethods.delete(
            `/api/v1/${this.model}/deleteFile?bucket=${file.bucket}&key=${encodedKey}&documentId=${this.documentId}&model=${this.model}`
          );
          this.files.splice(i, 1);

          // Send to parent
          this.$emit("sendnewfiles", { ...res.data.document });

          this.showPopup("Tiedosto poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    encodeKey(key) {
      const normalizedKey = key.normalize("NFC");
      return encodeURIComponent(normalizedKey);
    },
  },
};
</script>

<style scoped>
.card-oldlist {
  height: 300px;
  overflow-y: scroll;
}

.v-card__title .headline {
  font-weight: 300;
}
</style>
