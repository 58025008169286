import partnerService from "../../services/partner.service";
import { showPopup } from "../../utils/helpers";
import { defaultPartner } from "@/configs/defaultState";
import _ from "lodash";

const state = {
  partners: [],
  activePartners: [],
  totalLength: 0,
  currentPartner: {},
  loading: false,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  addAllPartners(state, data) {
    state.partners = data.partners;
    state.totalLength = data.totalLength;
  },

  setCurrentPartner(state, partner) {
    state.currentPartner = { ...partner };
  },

  setDefaultPartner(state) {
    state.currentPartner = _.cloneDeep(defaultPartner);
  },

  addActivePartners(state, partners) {
    state.activePartners = [...partners, ...state.activePartners].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  removePartner(state, id) {
    state.partners = state.partners.filter((el) => el._id != id);
  },
};

const actions = {
  async getAllPartners({ commit }, url) {
    try {
      const res = await partnerService.getAllPartners(url);
      commit("addAllPartners", { partners: res.data.partners, totalLength: res.data.totalLength });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async createPartner(ctx, data) {
    try {
      const res = await partnerService.createPartner(data);
      showDuplicateEmailCount(res.data.duplicatePartnerEmails);
    } catch (err) {
      throw new Error(err);
    }
  },

  async getOnePartner({ commit }, url) {
    try {
      const res = await partnerService.getOnePartner(url);
      commit("setCurrentPartner", res.data.partner);
    } catch (err) {
      throw new Error(err);
    }
  },

  async editPartner(ctx, data) {
    try {
      const res = await partnerService.editPartner(data);
      showDuplicateEmailCount(res.data.duplicatePartnerEmails);
    } catch (err) {
      throw new Error(err);
    }
  },

  async inActivatePartner({ commit }, id) {
    try {
      await partnerService.inActivatePartner(id);
      commit("removePartner", id);
      showPopup("Toimeksiantaja poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async searchPartner({ commit }, url) {
    try {
      const res = await partnerService.searchPartner(url);

      commit("addActivePartners", res.data.partners);
    } catch (err) {
      showPopup(err, " error");
    }
  },
};

// Helpers
function showDuplicateEmailCount(duplicateEmails) {
  if (duplicateEmails.length > 0) {
    showPopup(
      `Tallennettaessa löytyi päällekkäisiä toimeksiantajien emaileja: \n ${duplicateEmails
        .map((result) => `${result.email}: ${result.count} kpl`)
        .join(", \n")}`,
      "info",
      -1
    );
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
