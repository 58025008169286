<template>
  <v-container>
    <v-form ref="form">
      <v-row dense class="justify-center">
        <v-col cols="12" class="d-flex justify-center">
          <h2 class="mb-2">Vuokranantajat</h2>
        </v-col>

        <!-- Create new partner -->
        <v-col cols="12" class="mb-2 d-flex justify-center">
          <v-btn color="info" small @click="openPartnerDialog">Luo uusi toimeksiantaja</v-btn>
        </v-col>
      </v-row>

      <v-row :class="landlords.length < 3 ? 'justify-center' : 'justify-start'" dense>
        <v-col
          v-for="(landlord, index) in landlords"
          :key="index"
          cols="12"
          sm="10"
          md="6"
          lg="5"
          xl="4"
        >
          <v-col cols="12">
            <label
              ><strong>Vuokranantaja {{ index + 1 }}</strong>
              <span
                v-if="index > 0"
                class="ml-2 error--text"
                @click="removeLandlord(index)"
                style="font-size: 15px; cursor: pointer"
                >Poista
              </span>
            </label>

            <v-autocomplete
              :value="landlord.landlordId"
              :items="activeLandlords"
              item-text="name"
              item-value="_id"
              no-data-text="Toimeksijantajan tai oman tilisi nimi"
              hint="Valitse olemassa olevista vuokranantajista"
              :persistent-hint="landlord.landlordId ? true : true"
              :hide-details="landlord.landlordId ? true : false"
              outlined
              dense
              return-object
              :rules="validations.required"
              small-chips
              @change="setLandlord($event, { ...landlord }, index)"
              @input.native="getLandlords"
            >
            </v-autocomplete>
          </v-col>

          <template v-if="landlord.landlordId">
            <v-col cols="12">
              <label>Nimi</label>
              <v-text-field
                v-model="landlord.name"
                :rules="validations.required"
                outlined
                dense
                disabled
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Asiakastyyppi</label>
              <v-radio-group
                v-model="landlord.isCompany"
                :mandatory="true"
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                style="padding-top: 0; margin-top: 10px"
                hide-details
                @change="updateLandlordData($event, { ...landlord }, index)"
              >
                <v-radio label="Yksityinen" :value="false"></v-radio>
                <v-radio label="Yritys" :value="true"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col v-if="!landlord.isCompany" cols="12">
              <label>Henkilötunnus</label>
              <v-text-field
                v-model.trim="landlord.social"
                :rules="validations.required"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
                @input="updateLandlordGovid($event, { ...landlord }, index)"
              />
            </v-col>

            <v-col v-if="landlord.isCompany" cols="12">
              <label>Y-tunnus</label>
              <v-text-field
                v-model="landlord.businessId"
                :rules="validations.required"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Osoite</label>
              <v-text-field
                v-model="landlord.address"
                :rules="validations.required"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Postinumero</label>
              <v-text-field
                v-model="landlord.zipCode"
                :rules="validations.required"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Kaupunki</label>
              <v-text-field
                v-model="landlord.city"
                :rules="validations.required"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Puhelin</label>
              <v-text-field
                v-model="landlord.phone"
                :rules="validations.required"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <label>Sähköposti</label>
              <v-text-field
                v-model="landlord.email"
                :rules="validations.email"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>

            <v-col v-if="!index > 0" cols="12">
              <label>Pankki</label>
              <v-text-field
                v-model="landlord.bank"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>

            <v-col v-if="!index > 0" cols="12">
              <label>Iban</label>
              <v-text-field
                v-model="landlord.iban"
                outlined
                dense
                :disabled="landlord.onModel === 'Account' || !landlord.landlordId"
                hide-details
              />
            </v-col>
          </template>
        </v-col>
      </v-row>
    </v-form>

    <v-row dense class="justify-center mt-3">
      <v-btn color="primary" @click="addLandlord()">Lisää vuokranantaja</v-btn>
    </v-row>

    <v-row dense class="justify-center">
      <v-col cols="12" sm="10" md="6" lg="5" xl="4" class="d-flex align-center justify-center">
        <v-checkbox
          v-model="saveLandlordInformation"
          hide-details
          label="Tallenna vuokranantajien muuttuneet tiedot asiakashallintaan"
        ></v-checkbox>
      </v-col>
    </v-row>

    <!-- Create partner dialog -->
    <create-partner-dialog v-model="newPartnerDialog"></create-partner-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { eventBus } from "../../main";
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";
import validations from "@/validations";
import CreatePartnerDialog from "../Partner/CreatePartnerDialog.vue";

export default {
  mixins: [mixins],

  components: {
    CreatePartnerDialog,
  },

  data() {
    return {
      newPartnerDialog: false,
      landlordInput: "",
      validations,
    };
  },

  computed: {
    ...mapState("account", ["activeLandlords"]),

    landlords: {
      get() {
        return this.$store.state.rentalContract.landlords;
      },
      set(value) {
        this.$store.commit("setContractField", {
          landlords: value,
        });
      },
    },

    saveLandlordInformation: {
      get() {
        return this.$store.state.saveLandlordInformation;
      },
      set(val) {
        this.$store.state.saveLandlordInformation = val;
      },
    },
  },

  watch: {
    landlords: {
      deep: true,
      handler() {
        this.$store.commit("updateLocalStorage");
      },
    },

    landlordInput: debounce(function (newVal) {
      this.searchAllLandlords(`/api/v1/account/search-landlords?search=${newVal}`);
    }, 1000),

    saveLandlordInformation() {
      this.$store.commit("updateLocalStorage");
    },
  },

  async created() {
    this.$root.$refs.editLandlord = this;
  },

  methods: {
    ...mapActions("account", ["searchAllLandlords"]),
    ...mapMutations("partner", ["setDefaultPartner"]),

    openPartnerDialog() {
      this.setDefaultPartner();
      this.newPartnerDialog = true;
    },

    getLandlords(event) {
      this.landlordInput = event.target.value;
    },

    checkIfValid() {
      eventBus.$emit("checklandlord", this.$refs.form.validate());
    },

    addLandlord() {
      let landlords = this.landlords;

      landlords.push({
        isCompany: false,
        name: "",
        social: null,
        businessId: null,
        phone: "",
        address: "",
        zipCode: "",
        city: "",
        email: "",
        bank: "",
        iban: "",
        onModel: "",
        landlordId: "",
      });

      this.landlords = [...landlords];
    },

    removeLandlord(index) {
      this.landlords.splice(index, 1);
    },

    setLandlord(event, landlord, index) {
      if (event) {
        landlord.name = event.name;
        landlord.address = event.address;
        landlord.city = event.city;
        landlord.zipCode = event.zipCode;
        landlord.email = event.email;
        landlord.phone = event.phone;
        landlord.bank = event.bank;
        landlord.iban = event.iban;
        landlord.landlordId = event._id;
        delete landlord._id;

        if (event.isCompany) {
          landlord.isCompany = true;
          landlord.businessId = event.businessId;
          landlord.social = null;
        } else {
          landlord.isCompany = false;
          landlord.social = event.social;
          landlord.businessId = null;
        }

        if (
          String(this.$store.state.account.currentUser.currentAccount._id) === String(event._id)
        ) {
          landlord.onModel = "Account";
        } else {
          landlord.onModel = "Partner";
        }

        this.landlords.splice(index, 1, landlord);
      }
    },

    updateLandlordData(isCompany, landlord, index) {
      if (isCompany) {
        landlord.social = null;
      } else {
        landlord.businessId = null;
      }

      this.landlords.splice(index, 1, landlord);
    },

    updateLandlordGovid(event, landlord, index) {
      if (!landlord.isCompany) {
        const formattedSocial = event
          ? event.replace(/[a-z]/g, (match) => match.toUpperCase())
          : null;
        landlord.social = formattedSocial;
      }

      this.landlords.splice(index, 1, landlord);
    },
  },
};
</script>

<style scoped>
.v-text-field >>> .v-messages .error--text {
  color: var(--v-error-base) !important;
}
</style>
