import shortLinkService from "../../services/shortLink.service";

const state = {};

const mutations = {};

const actions = {
  async getLink(ctx, id) {
    try {
      const res = await shortLinkService.getLink(id);
      return res.data.path;
    } catch (err) {
      throw new Error(err);
    }
  },

  async createShortLink({ commit }, data) {
    try {
      const res = await shortLinkService.createShortLink(data);

      if (data.attachTo === "apartment") {
        commit(
          "form/setAttachment",
          { field: "apartment", val: { ...res.data.document } },
          { root: true }
        );
        commit("apartment/replaceSelectedApartments", [{ ...res.data.document }], { root: true });
      } else if (data.attachTo === "rentalContract") {
        commit(
          "form/setAttachment",
          { field: "rentalContract", val: { ...res.data.document } },
          { root: true }
        );
        commit("contract/replaceContract", { ...res.data.document }, { root: true });
      }
    } catch (err) {
      throw new Error(err);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
