<template>
  <div>
    <v-dialog v-model="dialog" width="450">
      <v-card>
        <!-- New or edit -->
        <v-card-title>
          {{ !edit ? "Lisää uusi kulu" : "Muokkaa kulua" }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <!-- <v-select
              v-if="$route.params.id"
              v-model="currentReceipt.receiptType"
              label="Kulun tyyppi"
              :items="globalValues.receiptTypes"
              item-text="text"
              item-value="val"
              outlined
              dense
              :rules="validations.required"
            ></v-select> -->

            <v-checkbox
              v-if="currentReceipt.receiptType == 'receipt'"
              v-model="currentReceipt.taxDeductible"
              label="Vähennyskelpoinen kulu"
              style="margin-top: -10px; margin-bottom: -5px"
            ></v-checkbox>

            <v-text-field
              v-model="currentReceipt.name"
              label="Nimi / Yritys"
              placeholder="Esim. K-rauta"
              dense
              outlined
              :rules="validations.required"
            ></v-text-field>

            <v-checkbox
              v-if="currentReceipt.receiptType == 'maintenance'"
              v-model="currentReceipt.recurrency.isRecurrent"
              label="Toistuva maksu"
              style="margin-top: -10px; margin-bottom: -5px"
            ></v-checkbox>

            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" max-width="290">
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(currentReceipt.date)"
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  label="Kulun päiväys"
                  v-prevent-manual-input
                  :readonly="true"
                  :rules="validations.required"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="currentReceipt.date"
                first-day-of-week="1"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>

            <div
              v-if="
                currentReceipt.receiptType == 'maintenance' && currentReceipt.recurrency.isRecurrent
              "
            >
              <v-text-field
                v-model.number="currentReceipt.recurrency.repeatsLeft"
                dense
                outlined
                v-only-numbers
                v-prevent-paste
                suffix="kertaa"
                label="Toistetaan"
              ></v-text-field>
            </div>

            <v-textarea
              v-model="currentReceipt.description"
              label="Lisätiedot"
              placeholder="Omat merkinnät tai tarkennukset"
              dense
              outlined
            ></v-textarea>

            <v-text-field
              v-model="currentReceipt.reference"
              dense
              outlined
              label="Maksuviite"
              :disabled="isUpdated && currentReceipt.outgoingPayments.inUse"
            ></v-text-field>

            <div
              v-show="$route.path.includes('overview') && currentReceipt.receiptType === 'receipt'"
            >
              <v-checkbox
                style="margin-top: -10px; margin-bottom: -5px"
                v-if="currentUser.currentAccount.settings.subtractReceiptsFromOutgoing"
                v-model="currentReceipt.outgoingPayments.inUse"
                label="Vähennetään lähtevistä maksuista"
              ></v-checkbox>

              <v-menu
                v-if="
                  currentReceipt.outgoingPayments.inUse &&
                  currentUser.currentAccount.settings.subtractReceiptsFromOutgoing
                "
                ref="menu"
                v-model="menuOutgoing"
                :close-on-content-click="false"
                max-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDateMonthName(currentReceipt.outgoingPayments.subtractDate)"
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Vähennetään valitun kuun lähtevistä maksuista"
                    v-prevent-manual-input
                    :readonly="true"
                    :rules="validations.required"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentReceipt.outgoingPayments.subtractDate"
                  :allowed-dates="allowedMonths"
                  type="month"
                  first-day-of-week="1"
                  @input="menuOutgoing = false"
                ></v-date-picker>
              </v-menu>

              <div
                v-if="
                  currentReceipt.outgoingPayments.inUse &&
                  currentReceipt.outgoingPayments.paymentId &&
                  currentReceipt.outgoingPayments.subtracted
                "
                class="font-weight-bold success--text"
              >
                Lisätty toimeksiantajalle lähtevään maksuun.
              </div>

              <div
                v-if="
                  currentReceipt.outgoingPayments.inUse &&
                  !currentReceipt.outgoingPayments.subtracted &&
                  edit
                "
                class="font-weight-bold warning--text"
              >
                Odottaa lisäystä toimeksiantajalle lähtevään maksuun.
              </div>

              <div
                v-if="
                  currentReceipt.outgoingPayments.inUse &&
                  currentReceipt.outgoingPayments.receiptPaymentId &&
                  edit
                "
                class="font-weight-bold success--text"
              >
                Lisätty lähtevään kulumaksuun.
              </div>
            </div>

            <div
              class="mb-2 mt-2"
              @click.stop="
                clearProductForm();
                addProducts = true;
                productsDialog = true;
              "
            >
              <label style="cursor: pointer" class="mr-1"><strong>Lisää tuotteita</strong> </label>
              <v-icon style="cursor: pointer" color="primary">mdi-plus</v-icon>
            </div>

            <div style="cursor: pointer" class="mb-2" @click="productListDialog = true">
              Tuotteita
              <strong class="success--text">
                {{ currentReceipt.products.length }}
              </strong>
              kpl
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
          <v-spacer></v-spacer>
          <!-- New -->
          <v-btn v-if="!edit" color="info" @click="createReceipt"> Lisää kulu </v-btn>
          <!-- Edit -->
          <v-btn
            v-if="edit"
            color="info"
            @click="
              currentReceipt.outgoingPayments.paymentId ||
              currentReceipt.outgoingPayments.receiptPaymentId
                ? (confirmDialog = true)
                : saveReceipt()
            "
          >
            Tallenna kulu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Product dialog -->
    <product-form v-model="productsDialog" :addProducts="addProducts"></product-form>
    <!-- Product list -->
    <product-list v-model="productListDialog" :edit="edit"></product-list>
    <!-- Confirm create new payment -->
    <confirm-new-payment
      v-model="confirmDialog"
      :edit="edit"
      @savereceipt="saveReceipt"
    ></confirm-new-payment>
  </div>
</template>

<script>
import ProductForm from "./ProductForm.vue";
import ProductList from "./ProductList.vue";
import ConfirmNewPayment from "./ConfirmNewPayment.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import mixins from "@/mixins/mixins";
import validations from "@/validations";
import moment from "moment";
import globalValues from "@/configs/globalValues";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  mixins: [mixins],

  components: {
    ProductForm,
    ProductList,
    ConfirmNewPayment,
  },

  data() {
    return {
      productsDialog: false,
      addProducts: false,
      productListDialog: false,
      menu: false,
      menuOutgoing: false,
      confirmDialog: false,
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("receipt", ["currentReceipt"]),
    ...mapGetters("receipt", ["isUpdated"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    dialog(val) {
      if (val && !this.edit) {
        this.$nextTick(() => {
          this.setDefaultValues();
        });
      }
    },

    "currentReceipt.outgoingPayments.inUse"(val) {
      if (!val) this.addCurrentReceiptValue({ field: "outgoingPayments.subtractDate", val: null });
    },

    "currentReceipt.receiptType"(val) {
      if (val == "receipt") {
        const currentLength = this.currentReceipt.products.length;
        const filteredProducts = this.currentReceipt.products.filter(
          (obj) => !obj.productId || obj.productId === null
        );

        this.addCurrentReceiptValue({ field: "products", val: filteredProducts });
        // disable fields
        this.addCurrentReceiptValue({ field: "recurrency.isRecurrent", val: false });
        this.addCurrentReceiptValue({ field: "recurrency.repeatsLeft", val: null });
        this.addCurrentReceiptValue({ field: "recurrency.startDate", val: "" });

        if (currentLength != filteredProducts.length) {
          this.showPopup(
            "Muulle kululle ei voi valita tuotekortin tuotteita. Ne poistettiin automaattisesti kulun tuotteista",
            "info"
          );
        }
      } else if (val == "maintenance") {
        this.addCurrentReceiptValue({ field: "outgoingPayments.inUse", val: false });
        this.addCurrentReceiptValue({ field: "outgoingPayments.subtractDate", val: null });
      }
    },
  },

  methods: {
    ...mapMutations("receipt", [
      "setCurrentReceipt",
      "addCurrentReceiptValue",
      "setCurrentProduct",
    ]),

    saveReceipt() {
      if (this.$refs.form.validate()) {
        if (this.currentReceipt.products.length == 0) {
          return this.showPopup("Lisää ainakin yksi tuote kululle.", "error");
        }
        this.$emit("savereceipt");
        this.confirmDialog = false;
      }
    },

    createReceipt() {
      if (this.$refs.form.validate()) {
        if (this.currentReceipt.products.length == 0) {
          return this.showPopup("Lisää ainakin yksi tuote kululle.", "error");
        }
        this.$emit("createreceipt");
      }
    },

    allowedMonths(val) {
      const time = new Date(val).getTime();
      const startOfMonth = new Date(moment(new Date()).startOf("month")).getTime();
      if (time >= startOfMonth) return true;
      else return false;
    },

    clearProductForm() {
      this.setCurrentProduct({
        name: "",
        count: 1,
        taxpr: this.currentReceipt.receiptType === "maintenance" ? 0 : 25.5,
        amount: null,
        handling: null,
        productId: null,
      });
    },

    setDefaultValues() {
      const apartmentId = this.$route.params.id ? this.$route.params.id : null;

      this.setCurrentReceipt({
        source: apartmentId ? "apartment" : "general",
        receiptType: "receipt", //apartmentId ? this.currentReceipt.receiptType : "receipt",
        taxDeductible: true,
        name: "",
        description: "",
        products: [],
        amount: null,
        date: "",
        reference: "",
        apartment: apartmentId ? this.$route.params.id : null,
        recurrency: {
          isRecurrent: false,
          repeatsLeft: null,
        },
        outgoingPayments: {
          inUse: false,
          subtractDate: "",
          subtracted: false,
          paymentId: null,
          receiptPaymentId: null,
        },
      });
      // reset validation
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
