<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa toimeksiantajaa</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan toimeksiantajaa..."
      class="full-page-loader"
    ></full-page-loader>

    <p v-if="!loading" style="font-size: 18px">{{ currentPartner.name }}</p>

    <partner-form v-if="!loading" submit-btn-text="Tallenna" @submit="submit"></partner-form>
  </div>
</template>

<script>
import PartnerForm from "../../components/Partner/PartnerForm";
import mixins from "../../mixins/mixins";
import { mapMutations, mapActions, mapState } from "vuex";
import FullPageLoader from "../../components/FullPageLoader.vue";

export default {
  title: "Muokkaa toimeksiantajaa",

  components: {
    FullPageLoader,
    PartnerForm,
  },

  mixins: [mixins],

  computed: {
    ...mapState("partner", ["currentPartner", "loading"]),
  },

  async created() {
    try {
      this.setLoading(true);
      await this.getOnePartner(`/api/v1/partner/getOne/${this.$route.params.id}`);
      this.setLoading(false);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("partner", ["setLoading"]),
    ...mapActions("partner", ["editPartner", "getOnePartner"]),

    async submit() {
      try {
        await this.editPartner({
          url: `/api/v1/partner/update/${this.$route.params.id}`,
          data: this.currentPartner,
        });
        this.showPopup("Toimeksiantaja tallennettu", "success");
        this.$router.push("/partners");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
