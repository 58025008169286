<template>
  <v-dialog v-model="dialog" width="450">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text v-if="currentLoan.condominiumLoanId">
        <p>
          <strong>Korkojakson alkamispäivä: </strong
          >{{ formatDate(getLoanByCondominiumLoanId(currentLoan)["curRefRateIntStart"]) }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form ref="form">
          <v-select
            v-model="currentLoan.condominiumLoanId"
            label="Laina"
            :items="activeCondominiumLoans"
            item-text="name"
            item-value="_id"
            no-data-text="Lisää laina ensin taloyhtiöön"
            :rules="validations.required"
            dense
            outlined
          ></v-select>

          <label>Taloyhtiölle maksettava rahoitusvastike</label>
          <v-text-field
            v-model.number="currentLoan.amount"
            outlined
            dense
            step="0.01"
            type="number"
            :rules="validations.required.concat(validations.positiveNumber)"
            label="Maksuerä"
            suffix="€/kk"
          />

          <v-menu
            v-if="currentLoan.condominiumLoanId"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentLoan.startDate)"
                label="Viimeisin lainan päiväys"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :readonly="true"
                :rules="validations.required"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentLoan.startDate"
              first-day-of-week="1"
              @input="menu = false"
              type="month"
              :allowed-dates="allowedLoanStartDates"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model.number="currentLoan.totalAmount"
            label="Päiväyksen mukainen lainasumma"
            step="0.01"
            type="number"
            dense
            outlined
            suffix="€"
            :rules="validations.required.concat(validations.positiveNumber)"
          ></v-text-field>

          <div v-if="edit && currentLoan.currentAmount">
            <v-checkbox
              v-model="removeCurrentAmount"
              style="margin-top: 0px"
              label="Nollaa järjellä oleva lainaosuus"
              hide-details
            ></v-checkbox>

            <WarningComponent v-if="removeCurrentAmount && currentLoan.totalAmount" class="mt-1">
              Lainaosuus {{ formatCurrency(currentLoan.currentAmount) }} palautetaan summaan
              {{ formatCurrency(currentLoan.totalAmount) }} tallennettaessa.
            </WarningComponent>

            <small
              >Jäljellä olevaa lainaosuutta ei voi muuttaa käsin, mutta mikäli nollaat sen, laskenta
              aloitetaan alusta lainan tietojen mukaisesti.</small
            >
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click.prevent="submit">{{ submitBtnText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import mixins from "../../mixins/mixins";
import globalValues from "../../configs/globalValues";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    title: { type: String, default: "Muokkaa lainaa" },
  },

  data() {
    return {
      removeCurrentAmount: false,
      menu: false,
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("apartment", ["currentApartment", "currentLoan", "loanIndex"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    activeCondominiumLoans() {
      return this.currentApartment.condominium.loans.filter((el) => el.active);
    },

    condominiumLoans() {
      return this.currentApartment.condominium.loans;
    },

    submitBtnText() {
      return this.edit ? "Tallenna" : "Lisää vastike";
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
          this.removeCurrentAmount = false;
        });
      }
    },

    "currentLoan.startDate"(val) {
      if (val) this.currentLoan.startDate = moment(val).endOf("month").format("YYYY-MM-DD");
    },
  },

  methods: {
    ...mapMutations("apartment", ["addLoanPayment", "editLoanPayment", "setDefaultLoanForm"]),

    submit() {
      if (this.$refs.form.validate()) {
        if (this.edit) {
          const boolean = this.currentApartment.currentLoans.some((el, index) => {
            if (index !== this.loanIndex) {
              return String(el.condominiumLoanId) == String(this.currentLoan.condominiumLoanId);
            }
          });

          if (boolean) return this.showPopup("Sama rahoitusvastike on jo lisätty", "error");

          if (!this.isPossibleRefRateDate()) return;

          this.editLoanPayment(this.removeCurrentAmount);
          this.dialog = false;
          this.showPopup("Rahoitusvastiketta muokattu", "success");
        } else {
          const boolean = this.currentApartment.currentLoans.some(
            (el) => String(el.condominiumLoanId) == String(this.currentLoan.condominiumLoanId)
          );

          if (boolean)
            return this.showPopup("Et voi lisätä samaa rahoitusvastiketta uudelleen", "error");

          if (!this.isPossibleRefRateDate()) return;

          this.addLoanPayment();
          this.setDefaultLoanForm();
          this.$refs.form.resetValidation();
        }
      } else {
        this.showPopup("Tarkista kentät", "error");
      }
    },

    allowedLoanStartDates(val) {
      const loanDetails = this.getLoanByCondominiumLoanId(this.currentLoan);
      const value = moment(val).endOf("month").format("YYYY-MM-DD");

      if (Object.keys(loanDetails).length > 0) {
        if (loanDetails.endDate && loanDetails.method !== "fixed annuity") {
          const endDate = moment(loanDetails.endDate).format("YYYY-MM-DD");

          if (
            moment(moment(value)).isBefore(moment(endDate)) &&
            moment(moment(value)).isSameOrBefore(moment().endOf("month").format("YYYY-MM-DD"))
          )
            return true;
          else return false;
        } else {
          return true;
        }
      } else {
        if (moment(val).isBefore(moment().format("YYYY-MM-DD"))) return true;
        else return false;
      }
    },

    isPossibleRefRateDate() {
      const loanDetails = this.getLoanByCondominiumLoanId(this.currentLoan);

      if (Object.keys(loanDetails).length > 0) {
        const startDate = moment(this.currentLoan.startDate).format("YYYY-MM-DD");
        const refRateDate = moment(moment(loanDetails.curRefRateIntStart).format("YYYY-MM-DD"));
        const refRateType = loanDetails.refRateType;

        const startDateMonth = moment(startDate).format("MM");

        let months = 1;

        if (refRateType == "euribor 12") {
          months = 12;
        } else if (refRateType == "euribor 6") {
          months = 6;
        } else if (refRateType == "euribor 3") {
          months = 3;
        }

        const dynamicDates = [];
        for (let i = 0; i < 12; i += months) {
          const currentDate = moment(refRateDate).subtract(i, "months").format("MM");
          dynamicDates.push(currentDate);
        }

        if (!dynamicDates.includes(startDateMonth)) {
          this.showPopup(
            `Lainan päiväys ei vastaa lainaehtojen viitekoron tyyppiä ja kuukautta. 
            Lainan tulee alkaa korkoehtojen mukaisesti jossain seuraavista kuukausista: ${dynamicDates}. Muuta koron tai lainan päivämäärää vastaamaan toisiaan.`,
            "error"
          );
          return false;
        }

        return true;
      } else {
        return true;
      }
    },

    getLoanByCondominiumLoanId(item) {
      const idx = this.condominiumLoans.findIndex(
        (el) => String(el._id) == String(item.condominiumLoanId)
      );

      if (idx != -1) {
        return this.condominiumLoans[idx];
      } else {
        return {};
      }
    },
  },
};
</script>

<style></style>
