<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Luo voimassa oleva vuokrasopimus</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <OldRentalContractForm btn-text="Luo" @sendcontract="createContract($event)">
    </OldRentalContractForm>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import OldRentalContractForm from "../../components/RentalContract/OldRentalContract/OldRentalContractForm";

export default {
  title: "Luo voimassa oleva vuokrasopimus",

  components: {
    OldRentalContractForm,
  },

  mixins: [mixins],

  methods: {
    async createContract(data) {
      try {
        const res = await axiosMethods.post("/api/v1/rental-contract/createOld/", data);

        if (res.data.duplicateTenantEmails.length > 0) {
          this.showPopup(
            `Tallennettaessa löytyi päällekkäisiä vuokralaisten emaileja: \n ${res.data.duplicateTenantEmails
              .map((result) => `${result.email}: ${result.count} kpl`)
              .join(", \n")}`,
            "info",
            -1
          );
        }

        if (res.data.duplicatePartnerEmails.length > 0) {
          this.showPopup(
            `Tallennettaessa löytyi päällekkäisiä toimeksiantajien emaileja: \n ${res.data.duplicatePartnerEmails
              .map((result) => `${result.email}: ${result.count} kpl`)
              .join(", \n")}`,
            "info",
            -1
          );
        }

        this.showPopup("Voimassa olevan vuokrasopimuksen luominen onnistui", "success");
        this.$router.push("/rental-contracts");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
