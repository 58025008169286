<template>
  <v-dialog v-model="dialog" :persistent="loading" width="600">
    <v-card>
      <v-card-title>Hae vuokrakohteen maksuraportti</v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form">
            <label>Raportin aikaväli</label>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(dates.startDate)"
                      outlined
                      dense
                      label="Alkaen"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      ref="startDateFormatted"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                      :rules="validations.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.startDate"
                    :allowed-dates="allowedDates"
                    first-day-of-week="1"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                  ref="endMenu"
                  v-model="endMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(dates.endDate)"
                      outlined
                      dense
                      label="Päättyen"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                      :rules="validations.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.endDate"
                    :allowed-dates="allowedDates"
                    first-day-of-week="1"
                    @input="endMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <label>Vuokrakohde</label>
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  v-model="apartmentId"
                  :items="activeApartments"
                  :item-text="fullAddress"
                  item-value="_id"
                  label="Liittyy vuokrakohteeseen"
                  no-data-text="Vuokrakohteen osoite"
                  outlined
                  small-chips
                  dense
                  :rules="validations.required"
                  :search-input.sync="apartmentInput"
                  @change="apartmentInput = ''"
                  @input.native="getApartments"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" v-if="!loading" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="submit()" :loading="loading">Hae raportti</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState } from "vuex";
import { debounce } from "../../utils/helpers";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false,
      startMenu: false,
      endMenu: false,
      dates: { startDate: null, endDate: null },
      apartmentId: null,
      apartmentInput: "",
      validations,
    };
  },

  computed: {
    ...mapState("apartment", ["activeApartments"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("apartment", ["searchApartment"]),
    ...mapActions("report", ["getOutgoingPaymentIncomeReport"]),

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    allowedDates(val) {
      if (val) {
        const today = new Date(moment().format("YYYY-MM-DD"));
        const thisYear = today.getFullYear();
        const thisMonth = today.getMonth();
        const valYear = new Date(val).getFullYear();
        const valMonth = new Date(val).getMonth();

        if (valYear < thisYear) return true;
        else if (thisYear == valYear && valMonth <= thisMonth) return true;
        else return false;
      }
      return false;
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          await this.getOutgoingPaymentIncomeReport({
            dates: this.dates,
            apartmentId: this.apartmentId,
          });
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped>
.v-card__title .headline {
  font-weight: 300;
}
</style>
