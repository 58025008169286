var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(" Laskutus aikaväliltä "+_vm._s(_vm.formatDate(_vm.reportDates.startDate))+" - "+_vm._s(_vm.formatDate(_vm.reportDates.endDate))+" ")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h3',{staticClass:"mb-1"},[_vm._v("Kirjauspäivän mukaan")]),_c('p',[_vm._v(" Kokonaissaatavat brutto sis. korot: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.accountDate.totalAmount))+" ")]),_c('p',[_vm._v(" Avoimet saatavat: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.accountDate.openAmount))+" "),(_vm.rentReportData.invoices.accountDate.openAmountApartments.length > 0)?_c('span',{staticClass:"info--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.downloadOpenAmountList(
              _vm.rentReportData.invoices.accountDate.openAmountApartments,
              'kirjauspäivän'
            )}}},[_vm._v("Avoimet vuokrakohteittain")]):_vm._e()]),(_vm.rentReportData.invoices.accountDate.salesInvoiceCount)?_c('p',[_vm._v(" Keskimääräinen myyntilaskun bruttosumma: "+_vm._s(_vm.formatCurrency( _vm.rentReportData.invoices.accountDate.salesInvoiceProductsTotalAmount / _vm.rentReportData.invoices.accountDate.salesInvoiceCount ))+" ")]):_vm._e(),_c('p',[_vm._v(" Korkotuotot aikavälillä: ~"+_vm._s(_vm.formatCurrency( _vm.rentReportData.invoices.accountDate.totalAmount - _vm.rentReportData.invoices.accountDate.productsTotalAmount ))+" ")]),_c('h4',{staticClass:"mb-1 mt-1"},[_vm._v("Tuotetyypeittäin")]),_c('p',[_vm._v(" Vuokra brutto: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.accountDate.rentProductsTotalAmount))+" ")]),_c('p',[_vm._v(" Vesi brutto: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.accountDate.waterProductsTotal))+" ")]),_c('p',[_vm._v(" Kaikki muut tuotteet yhteensä brutto: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.accountDate.otherProductsTotal))+" ")]),(_vm.rentReportData.invoices.accountDate.rentProductsCountOnSaleInvoices)?_c('p',[_vm._v(" Keskimääräinen vuokra (tuotetyyppi) laskulla: "+_vm._s(_vm.formatCurrency( _vm.rentReportData.invoices.accountDate.rentProductsTotalOnSaleInvoices / _vm.rentReportData.invoices.accountDate.rentProductsCountOnSaleInvoices ))+" ")]):_vm._e()]),_c('v-col',{staticClass:"pl-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('h3',{staticClass:"mb-1"},[_vm._v("Eräpäivän mukaan")]),_c('p',[_vm._v(" Kokonaissaatavat brutto sis. korot: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.dueDate.totalAmount))+" ")]),_c('p',[_vm._v(" Avoimet saatavat: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.dueDate.openAmount))+" "),(_vm.rentReportData.invoices.dueDate.openAmountApartments.length > 0)?_c('span',{staticClass:"info--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.downloadOpenAmountList(
              _vm.rentReportData.invoices.dueDate.openAmountApartments,
              'eräpäivän'
            )}}},[_vm._v("Avoimet vuokrakohteittain")]):_vm._e()]),(_vm.rentReportData.invoices.dueDate.salesInvoiceCount)?_c('p',[_vm._v(" Keskimääräinen myyntilaskun bruttosumma: "+_vm._s(_vm.formatCurrency( _vm.rentReportData.invoices.dueDate.salesInvoiceProductsTotalAmount / _vm.rentReportData.invoices.dueDate.salesInvoiceCount ))+" ")]):_vm._e(),_c('p',[_vm._v(" Korkotuotot aikavälillä: ~"+_vm._s(_vm.formatCurrency( _vm.rentReportData.invoices.dueDate.totalAmount - _vm.rentReportData.invoices.dueDate.productsTotalAmount ))+" ")]),_c('h4',{staticClass:"mb-1 mt-1"},[_vm._v("Tuotetyypeittäin")]),_c('p',[_vm._v(" Vuokra brutto: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.dueDate.rentProductsTotalAmount))+" ")]),_c('p',[_vm._v(" Vesi brutto: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.dueDate.waterProductsTotal))+" ")]),_c('p',[_vm._v(" Kaikki muut tuotteet yhteensä brutto: "+_vm._s(_vm.formatCurrency(_vm.rentReportData.invoices.dueDate.otherProductsTotal))+" ")]),(_vm.rentReportData.invoices.dueDate.rentProductsCountOnSaleInvoices)?_c('p',[_vm._v(" Keskimääräinen vuokra (tuotetyyppi) laskulla: "+_vm._s(_vm.formatCurrency( _vm.rentReportData.invoices.dueDate.rentProductsTotalOnSaleInvoices / _vm.rentReportData.invoices.dueDate.rentProductsCountOnSaleInvoices ))+" ")]):_vm._e()]),_c('v-col',{staticClass:"pl-sm-2",attrs:{"cols":"12","sm":"6"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }