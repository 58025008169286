var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"1100"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',{staticClass:"wrapper"},[_c('v-stepper',{staticClass:"mt-2",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","rules":[() => (_vm.step > 1 ? _vm.showLoader && _vm.overviewFormIsValidated : true)],"complete":_vm.showLoader && _vm.overviewFormIsValidated}},[_vm._v("Tiedot")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"2","rules":[
              () =>
                _vm.step > 2 ? _vm.newRentIncrease.products.length > 0 && _vm.productsAreValidated : true,
            ],"complete":_vm.newRentIncrease.products.length > 0 && _vm.productsAreValidated}},[_vm._v("Tuotteet")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3","rules":[() => (_vm.step > 3 ? true : true)]}},[_vm._v("Viesti")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('div',{staticClass:"mb-1",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.showLoader},on:{"click":function($event){_vm.validateOverviewForm();
                  _vm.step = 2;}}},[_vm._v("Seuraava")])],1),_c('Overview',{ref:"rentIncreaseOverview"}),_c('div',{staticClass:"mt-1",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.showLoader},on:{"click":function($event){_vm.validateOverviewForm();
                  _vm.step = 2;}}},[_vm._v("Seuraava")])],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('div',{staticClass:"buttons-wrapper mb-1"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 1}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.newRentIncrease.products.length === 0},on:{"click":function($event){_vm.validateProducts();
                  _vm.step = 3;}}},[_vm._v("Seuraava")])],1),_c('Products',{ref:"rentIncreaseProducts"}),_c('div',{staticClass:"buttons-wrapper mt-1"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 1}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.newRentIncrease.products.length === 0},on:{"click":function($event){_vm.validateProducts();
                  _vm.step = 3;}}},[_vm._v("Seuraava")])],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('div',{staticClass:"buttons-wrapper mb-1"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 2}}},[_vm._v("Takaisin")])],1),_c('Message',{ref:"rentIncreaseMessage"}),_c('div',{staticClass:"buttons-wrapper mt-1"},[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.step = 2}}},[_vm._v("Takaisin")]),_c('v-btn',{attrs:{"disabled":_vm.newRentIncrease.content == '',"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.createRentIncrease.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.newRentIncrease.dontSendMessage ? "Luo" : "Luo ja lähetä")+" ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }