<template>
  <v-form ref="form">
    <v-card class="mt-2">
      <v-card-title
        ><div>Perustiedot</div>
        <v-spacer></v-spacer>
        <router-link
          v-if="$route.path.includes('/partner/edit')"
          :to="`/partner/overview/${$route.params.id}`"
        >
          <v-btn color="primary">Yleisnäkymään</v-btn>
        </router-link>
      </v-card-title>

      <v-container>
        <v-radio-group v-model="currentPartner.isCompany" :mandatory="true">
          <v-radio label="Yksityinen" :value="false"></v-radio>
          <v-radio label="Yritys" :value="true"></v-radio>
        </v-radio-group>

        <h4>Toimeksiantaja</h4>

        <v-row dense v-if="currentPartner.isCompany">
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-checkbox
              v-model="fetchFromYtj"
              label="Salli YTJ:n hakea tietoja"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.name"
              :rules="validations.required"
              label="Nimi"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-if="currentPartner.isCompany"
              v-model.trim="currentPartner.businessId"
              label="Y-tunnus"
              outlined
              dense
              @input="checkYTJ"
              hide-details
            ></v-text-field>

            <v-text-field
              v-if="!currentPartner.isCompany"
              v-model.trim="currentPartner.social"
              label="Henkilötunnus"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.phone"
              label="Puhelin"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.email"
              label="Email"
              :rules="validations.email2"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <h4 class="mt-1">Osoite</h4>
        <!-- 
                <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="nominatimAddress"
                :items="foundAddresses"
                :item-text="nominatimHelpers.getAddressListName"
                outlined
                label="Hae osoitteella"
                dense
                return-object
                no-filter
                @change="
                  setAddress($event);
                  clearInput();
                "
                @input.native="getAddresses"
                hide-details
              ></v-autocomplete> </v-col
          ></v-row> -->

        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.address"
              label="Osoite"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.zipCode"
              label="Postinumero"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.city"
              label="Kaupunki"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <h4 class="mt-1">Lisätiedot</h4>

        <v-row dense>
          <v-col md="6">
            <v-textarea
              v-model="currentPartner.comments"
              placeholder="Muuta tietoa..."
              outlined
              dense
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card class="mt-2">
      <v-card-title>Laskutustiedot</v-card-title>
      <v-container>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.bank"
              label="Pankki"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2">
            <v-text-field
              v-model="currentPartner.iban"
              label="Tilinumero"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn color="info" class="mr-4 mt-5" @click="submit">{{ submitBtnText }}</v-btn>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import mixins from "../../mixins/mixins";
import axios from "axios";
import { mapState } from "vuex";
import validations from "@/validations";

export default {
  mixins: [mixins],

  props: {
    submitBtnText: { type: String, default: "" },
  },

  data() {
    return {
      fetchFromYtj: false,
      validations,
    };
  },

  watch: {
    "currentPartner.isCompany"(val) {
      if (val) this.currentPartner.social = null;
      else this.currentPartner.businessId = null;
    },

    "currentPartner.social"(val) {
      if (val) this.currentPartner.social = val.replace(/[a-z]/g, (match) => match.toUpperCase());
      this.currentPartner.businessId = null;
    },

    "currentPartner.businessId": function () {
      this.currentPartner.social = null;
    },

    fetchFromYtj(val) {
      if (val) this.checkYTJ();
    },
  },

  computed: {
    ...mapState("partner", ["currentPartner"]),
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) this.$emit("submit");
    },

    async checkYTJ() {
      if (this.fetchFromYtj) {
        if (
          this.currentPartner.businessId &&
          this.currentPartner.businessId.length >= 9 &&
          this.currentPartner.businessId.includes("-")
        ) {
          try {
            let res = await axios.get(
              `https://avoindata.prh.fi/bis/v1/${this.currentPartner.businessId}`
            );
            let data = res.data.results[0];

            this.currentPartner.name = data.name;

            let administrator = data.addresses.filter((el) => el.version == 1 && el.street != null);
            if (administrator[0]) {
              this.currentPartner.address = administrator[0].street;
              this.currentPartner.zipCode = administrator[0].postCode;
              let city = String(administrator[0].city).toLowerCase();
              this.currentPartner.city = city.charAt(0).toUpperCase() + city.slice(1);
            }
            let administratorPhone = data.contactDetails.filter(
              (el) => el.version == 1 && el.type == "Matkapuhelin"
            );
            if (administratorPhone[0]) this.currentPartner.phone = administratorPhone[0].value;
          } catch (err) {
            this.showPopup(
              "Kaikkia kenttiä tai osaa kenttien tiedoista ei voitu täyttää.",
              "error"
            );
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
