var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-1"},[_vm._v("Kustannuspaikka")]),(
      _vm.currentUser.currentAccount.settings.useApartmentCostAccounting ||
      _vm.currentUser.currentAccount.settings.useCondominiumCostAccounting
    )?_c('div',[_c('div',[(_vm.currentUser.currentAccount.settings.useApartmentCostAccounting)?_c('p',{staticClass:"success--text mb-1"},[_vm._v(" Vuokrakohdekohtainen kustannuspaikka käytössä ")]):_c('p',{staticClass:"success--text mb-1"},[_vm._v("Kiinteistökohtainen kustannuspaikka käytössä")]),(_vm.costCentre && _vm.$route.path.includes('/create'))?_c('div',[_c('p',[_vm._v(_vm._s(_vm.showCostCentre))])]):(
          (_vm.costCentre && (_vm.$route.path.includes('/edit') || _vm.$route.path.includes('/refund'))) ||
          (_vm.costCentre && _vm.editRecurrentInvoice)
        )?_c('div',[(!_vm.currentCostCentre)?_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(" Nykyistä kustannuspaikkaa ei löydy. Kustannuspaikaksi päivitetään tallennettaessa \""+_vm._s(_vm.costCentre)+"\" ")]):(_vm.costCentre == _vm.currentCostCentre)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.showCostCentre))])]):_c('div',[_c('p',[_vm._v(" Nykyinen kustannuspaikka laskulla: "),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.showCostCentre))])]),_c('p',[_vm._v(" Kustannuspaikaksi päivitetään tallennettaessa: "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.costCentre))])])])]):_c('p',{staticClass:"error--text"},[_vm._v("Kustannuspaikkaa ei löydy")])])]):(_vm.showCostCentre)?_c('div',[_c('p',{staticClass:"error--text"},[_vm._v("Ei käytössä")]),_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" Laskun nykyinen kustannuspaikka \""+_vm._s(_vm.showCostCentre)+"\" poistetaan tallennettaessa. ")])]):_c('div',[_c('p',{staticClass:"error--text"},[_vm._v("Ei käytössä")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }