import rentalContractService from "../../services/rentalContract.service";
import productService from "../../services/product.service";
import { showPopup } from "../../utils/helpers";
import _ from "lodash";

const state = {
  rentalContracts: [],
  tenantContractList: [],
  contract: {},
  activeRentalContracts: [],
  totalLength: 0,
  contractReportData: [],
  invoiceProducts: [],
  currentContract: {
    signature: {},
    tenant: { tenantId: {}, revokedDate: null, startDate: null },
    otherTenants: [],
    lease: {},
  },
  loading: false,
  refRentalContract: null,
};

const mutations = {
  setLoading(state, val) {
    state.loading = val;
  },

  setRefRentalContract(state, contract) {
    state.refRentalContract = _.cloneDeep(contract);
  },

  setCurrentContract(state, contract) {
    state.currentContract = { ...contract };
  },

  addContracts(state, data) {
    state.rentalContracts = data.rentalContracts;
    state.totalLength = data.totalLength;
  },

  addTenantContractList(state, contracts) {
    state.tenantContractList = contracts;
  },

  addActiveContracts(state, contracts) {
    state.activeRentalContracts = [...contracts, ...state.activeRentalContracts].filter(
      (value, index, self) => index === self.findIndex((t) => String(t._id) == String(value._id))
    );
  },

  addCurrentContract(state, contract) {
    state.contract = { ...contract };
  },

  deleteContract(state, id) {
    state.rentalContracts = state.rentalContracts.filter((el) => el._id != id);
  },

  setContractReportData(state, data) {
    state.contractReportData = data;
  },

  setInvoiceProducts(state, products) {
    state.invoiceProducts = products;
  },

  replaceContract(state, contract) {
    state.rentalContracts.splice(
      state.rentalContracts.findIndex((x) => String(x._id) == String(contract._id)),
      1,
      contract
    );
  },

  setRentalContractImages(state, data) {
    const index = state.rentalContracts.findIndex((x) => String(x._id) == String(data.contractId));

    if (index != -1) {
      state.rentalContracts[index].images = [...data.images];
    }
  },
};

const actions = {
  async getAllContracts({ commit }, url) {
    try {
      const res = await rentalContractService.getAllContracts(url);

      commit("addContracts", {
        rentalContracts: res.data.rentalContracts,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getTenantRentalContracts({ commit }, url) {
    try {
      const res = await rentalContractService.getTenantRentalContracts(url);

      commit("addContracts", {
        rentalContracts: res.data.rentalContracts,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getTenantRentalContractList({ commit }, url) {
    try {
      const res = await rentalContractService.getTenantRentalContractList(url);

      commit("addTenantContractList", res.data.rentalContracts);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getPartnerRentalContracts({ commit }, url) {
    try {
      const res = await rentalContractService.getPartnerRentalContracts(url);

      commit("addContracts", {
        rentalContracts: res.data.rentalContracts,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      showPopup(err, " error");
    }
  },

  async getOne({ commit }, id) {
    try {
      const res = await rentalContractService.getOne(id);
      commit("addCurrentContract", res.data.contract);
    } catch (err) {
      throw new Error(err);
    }
  },

  async getFormattedContract({ commit }, id) {
    try {
      const res = await rentalContractService.getFormattedContract(id);
      commit("addCurrentContract", res.data.contract);
    } catch (err) {
      throw new Error(err);
    }
  },

  async searchContract({ commit }, url) {
    try {
      const res = await rentalContractService.searchContract(url);
      commit("addActiveContracts", res.data.rentalContracts);
    } catch (err) {
      showPopup(err, " error");
    }
  },

  getRentalContractsById({ commit }, ids) {
    return rentalContractService
      .getRentalContractsById(ids)
      .then((res) => {
        commit("addActiveContracts", res.data.rentalContracts);
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  async deleteContract({ commit }, id) {
    try {
      await rentalContractService.deleteRentalContract(id);
      commit("deleteContract", id);
      showPopup("Vuokrasopimus poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getContractReport({ commit }, obj) {
    try {
      const data = await rentalContractService.getContractReport({
        contractId: obj.contractId,
        dates: obj.dates,
        searchType: obj.searchType,
      });
      commit("setContractReportData", data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async removeReferenceContract({ commit }, data) {
    try {
      const res = await rentalContractService.removeReferenceContract(data);
      commit("replaceContract", res.data.contract);
      commit("othercontract/removeReferenceContract", data.referenceId, { root: true });
      showPopup("Referenssi sopimuksien väliltä on poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async addTenantToContract({ commit }, data) {
    try {
      const res = await rentalContractService.addTenantToContract(data);
      commit("setCurrentContract", res.data.contract);
      commit("replaceContract", res.data.contract);
    } catch (err) {
      throw new Error(err);
    }
  },

  async setRevokedDateToTenant({ commit }, data) {
    try {
      const res = await rentalContractService.setRevokedDateToTenant(data);
      commit("setCurrentContract", res.data.contract);
      commit("replaceContract", res.data.contract);
    } catch (err) {
      throw new Error(err);
    }
  },

  async revertRevokedTenant({ commit }, data) {
    try {
      const res = await rentalContractService.revertRevokedTenant(data);
      commit("setCurrentContract", res.data.contract);
      commit("replaceContract", res.data.contract);
    } catch (err) {
      throw new Error(err);
    }
  },

  async getProductsById({ commit }, productsIds) {
    try {
      const products = await productService.getProductsById(productsIds);
      commit("setInvoiceProducts", products);
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

// Exports
export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
