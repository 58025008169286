var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentApartment)?_c('v-row',{class:[
    _vm.currentForm.styles.align == 'center'
      ? 'justify-center'
      : _vm.currentForm.styles.align == 'left'
      ? 'justify-start'
      : 'justify-end',
  ],attrs:{"dense":""}},[_c('v-col',{staticClass:"hero-header",style:({
      fontFamily: _vm.font,
      backgroundColor: _vm.backgroundColor,
      color: '#ffffff',
    }),attrs:{"cols":_vm.width.cols,"sm":_vm.width.sm,"md":_vm.width.md,"lg":_vm.width.lg,"xl":_vm.width.xl}},[_c('h1',{staticClass:"ml-2 hero-main-text"},[(_vm.currentApartment.floorPlan)?_c('span',[_vm._v(_vm._s(_vm.currentApartment.floorPlan)+" ")]):_vm._e(),(_vm.currentApartment.floorPlan)?_c('span',[_vm._v("|")]):_vm._e(),_vm._v(" "+_vm._s(_vm.currentApartment.area)+" m² "),(_vm.currentApartment.estimatedRent)?_c('span',[_vm._v("| ")]):_vm._e(),(_vm.currentApartment.estimatedRent)?_c('span',[_vm._v(_vm._s(_vm.formatCardPrice(_vm.currentApartment.estimatedRent))+"/kk")]):_vm._e()]),_c('p',{staticClass:"ml-2 hero-small-text"},[_c('strong',[_vm._v(_vm._s(_vm.currentApartment.address))]),_vm._v(", "+_vm._s(_vm.currentApartment.zipCode)+" "+_vm._s(_vm.currentApartment.city)+", "+_vm._s(_vm.currentApartment.houseType)),(_vm.currentApartment.houseType && _vm.currentApartment.condominium.buildYear)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(_vm._s(_vm.currentApartment.condominium.buildYear)+" ")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }