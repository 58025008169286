<template>
  <v-card>
    <v-container>
      <!-- Info  -->
      <h3 class="mb-1 mt-2">Korotetun laskun tiedot</h3>

      <v-form ref="form">
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-menu
              ref="dueDateMenu"
              v-model="dueDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(dueDate)"
                  outlined
                  dense
                  label="Korotus alkaen"
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar"
                  v-prevent-manual-input
                  :readonly="true"
                  :rules="validations.required"
                  hint="Toistuvan laskun eräpäivän mukainen"
                  persistent-hint
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dueDate"
                :allowed-dates="allowedDates"
                type="month"
                first-day-of-week="1"
                @input="dueDateMenu = false"
                @change="setDueDate"
                :rules="validations.required"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              v-model="paymentTerm"
              outlined
              dense
              :items="paymentTerms"
              item-text="text"
              item-value="days"
              suffix="pv"
              label="1. maksuehto"
              hide-details
              :rules="validations.required"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              v-model="sendType"
              outlined
              dense
              :items="sendTypes"
              item-value="val"
              item-text="text"
              label="1. laskun lähetystapa"
              hide-details
              :rules="validations.required"
            ></v-select>
          </v-col>
        </v-row>

        <div class="mt-2 sendtype-wrapper">
          <div>
            <p v-if="sendType === 'print'"><strong>Lähetystapa:</strong> Itse toimitettava</p>

            <div v-if="sendType === 'email'">
              <strong>Email:</strong>
              <span
                v-if="
                  currentContract.baseInvoice.isUpdated &&
                  currentContract.baseInvoice.updatedTenant &&
                  currentContract.baseInvoice.updatedTenant.name
                "
              >
                {{ currentContract.baseInvoice.updatedTenant.email
                }}<span v-if="currentContract.baseInvoice.updatedTenant.email2"
                  >, {{ currentContract.baseInvoice.updatedTenant.email2 }}</span
                >
              </span>

              <span v-else>
                {{ currentContract.baseInvoice.tenant.email
                }}<span v-if="currentContract.baseInvoice.tenant.email2"
                  >, {{ currentContract.baseInvoice.tenant.email2 }}</span
                >
              </span>

              <span v-if="!sendTypeValidation"
                >Täydennä email toistuvalle laskulle ja tallenna lasku ennen korotuksen
                tekemistä.</span
              >
            </div>

            <p v-if="sendType === 'evoice'">
              Operaattoritunnus:
              {{
                currentContract.baseInvoice.evoice.evoiceId
                  ? currentContract.baseInvoice.evoice.evoiceId
                  : "Määrittele laskulle ja tallenna lasku ennen korotuksen tekemistä"
              }}
            </p>
            <p v-if="sendType === 'evoice'">
              Verkkolaskuosoite:
              {{
                currentContract.baseInvoice.evoice.evoiceAddress
                  ? currentContract.baseInvoice.evoice.evoiceAddress
                  : "Määrittele laskulle ja tallenna lasku ennen korotuksen tekemistä"
              }}
            </p>

            <p v-if="sendType == 'post'"><strong>Lähetystapa:</strong> Kirje</p>

            <p
              v-if="
                currentContract.baseInvoice.isUpdated &&
                currentContract.baseInvoice.updatedSendingAddress &&
                currentContract.baseInvoice.updatedSendingAddress.address
              "
            >
              <strong>Vastaanottajan osoite:</strong>
              <span v-if="currentContract.baseInvoice.updatedSendingAddress.addressLine2">
                {{ currentContract.baseInvoice.updatedSendingAddress.addressLine2 }},</span
              >
              {{ currentContract.baseInvoice.updatedSendingAddress.address }},
              {{ currentContract.baseInvoice.updatedSendingAddress.zipCode }}
              {{ currentContract.baseInvoice.updatedSendingAddress.city }}
              {{ currentContract.baseInvoice.updatedSendingAddress.country }}
            </p>

            <p v-else>
              <strong>Vastaanottaja:</strong>
              <span v-if="currentContract.baseInvoice.sendingAddress.addressLine2">
                {{ currentContract.baseInvoice.sendingAddress.addressLine2 }},</span
              >
              {{ currentContract.baseInvoice.sendingAddress.address }},
              {{ currentContract.baseInvoice.sendingAddress.zipCode }}
              {{ currentContract.baseInvoice.sendingAddress.city }}
              {{ currentContract.baseInvoice.sendingAddress.country }}
            </p>
          </div>

          <div>
            <v-icon :color="sendTypeValidation ? 'success' : 'error'">{{
              sendTypeValidation ? "mdi-check-circle" : "mdi-alert-circle"
            }}</v-icon>
          </div>
        </div>

        <!-- Products  -->
        <h3 class="mb-1 mt-2">Korotetun laskun tuotteet</h3>

        <v-row dense>
          <v-col
            ><v-btn
              small
              class="mr-1 mb-1"
              :class="{ get_products_btn: productsBtnAnimation }"
              @click="
                setRentIncreaseProducts();
                setButtonAnimation(false);
              "
              color="info"
              >{{
                currentContract.lease.rentIncreaseType === "own"
                  ? "Hae alkuperäiset tuotteet"
                  : "Hae alkuperäiset tuotteet korotettuna"
              }}</v-btn
            >
            <v-btn
              v-if="
                newRentIncrease.products.length > 0 &&
                currentContract.lease.rentIncreaseType !== 'own'
              "
              class="mb-1"
              small
              :class="{ get_products_btn: productsBtnAnimation }"
              @click="
                increaseCurrentProducts();
                setButtonAnimation(false);
              "
              color="info"
              >Korota nykyiset tuotteet</v-btn
            >
          </v-col>
        </v-row>

        <div v-for="(product, index) in newRentIncrease.products" :key="index">
          <v-row dense class="mb-1">
            <v-col cols="12" sm="6" md="4" class="pr-1">
              <label>Valitse tuote</label>
              <v-autocomplete
                v-model="product.productId"
                :items="products"
                item-text="desc"
                item-value="_id"
                dense
                outlined
                return-object
                small-chips
                @change="setProductToState($event, { ...product }, index)"
                :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6" sm="6" md="3" class="pr-1">
              <v-text-field
                v-model.number="product.desc"
                outlined
                dense
                :rules="validations.required"
                label="Kuvaus"
                hide-details
              />
            </v-col>
            <v-col cols="6" sm="6" md="2" lg="2" class="pr-1">
              <v-text-field
                v-model.number="product.amount"
                outlined
                dense
                step="0.01"
                type="number"
                :rules="validations.required.concat(validations.positiveNumber)"
                label="Hinta"
                suffix="€"
                hide-details
              />
            </v-col>

            <v-col cols="6" sm="6" md="2" lg="1" class="pr-1">
              <v-text-field
                v-model.number="product.count"
                outlined
                dense
                v-only-numbers
                v-prevent-paste
                :rules="validations.required.concat(validations.positiveNumber)"
                label="Määrä"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="6" sm="6" md="2" lg="2" class="pr-1">
              <v-select
                v-model="product.itemtype"
                outlined
                dense
                :items="productItemTypes"
                label="Tuotetyyppi"
                return-object
                hide-details
                :rules="validations.required"
              ></v-select>
            </v-col>

            <v-col cols="6" sm="6" md="3" lg="2">
              <div class="details-container">
                <v-select
                  v-model.number="product.taxpr"
                  outlined
                  dense
                  :items="vatTypes"
                  item-value="val"
                  item-text="text"
                  label="Alv-kanta"
                  hide-details
                  :rules="validations.zeroOrGreater"
                ></v-select>

                <v-icon class="ml-1" color="error" small @click="deleteProduct(index)"
                  >mdi-delete</v-icon
                >
              </div>
            </v-col>
          </v-row>

          <div v-if="product.accountingId" style="margin-top: 5px">
            <p style="font-size: 13px">Tiliöintikoodi: {{ product.accountingId }}</p>
            <v-divider class="mb-1 mt-2"></v-divider>
          </div>
          <v-divider v-else class="mt-2 mb-1"></v-divider>
        </div>
      </v-form>

      <v-row class="mt-2" dense>
        <v-col>
          <v-btn color="primary" small class="mr-4 mb-2" @click="addProduct()"
            >Lisää tuoterivi</v-btn
          >
        </v-col>
      </v-row>

      <!--  Product list -->
      <v-row dense>
        <v-col v-if="newRentIncrease.products.length > 0" class="mt-2 mb-2" cols="12" md="5">
          <!-- VATS -->
          <vat-component :products="newRentIncrease.products"></vat-component>
        </v-col>
        <!-- Invoice information -->
        <v-col cols="12" md="7" class="mt-2 mb-2">
          <div v-if="newRentIncrease.dueDate" class="invoice-overview">
            <p>
              Ensimmäisen korotetun laskun eräpäivä on
              <span class="contract-text">{{ formatDate(newRentIncrease.dueDate) }} </span>
            </p>
            <p>
              Ensimmäiset toistuvan laskun pvm ja lähetyspäivä on
              <span class="contract-text">{{ formatDate(billDate) }}</span>
            </p>
            <p>
              Ensimmäisen korotetun laskun lähetystapa on
              <span class="contract-text">{{ formatSendType(newRentIncrease.sendType) }} </span>
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- PRICES
        <v-row class="mt-2" dense>
          <v-col cols="12" sm="5">
            Prices
            <Prices productType="currentProducts"></Prices>
          </v-col>

          <v-col cols="12" sm="2">
            <div class="arrow-box">
              <v-icon large color="primary">mdi-arrow-right-thick</v-icon>
            </div>
          </v-col>

          <v-col cols="12" sm="5">
            Prices
            <Prices
              v-if="newRentIncrease.products.length > 0"
              productType="rentIncreaseProducts"
            ></Prices>

            Loader
            <full-page-loader
              v-if="newRentIncrease.products.length === 0"
              text="Lisää laskun tuotteet..."
              class="full-page-loader"
              :fontSize="14"
              :size="50"
              :sm="6"
              type="line"
            ></full-page-loader>
          </v-col>
        </v-row> -->
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import moment from "moment";
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import globalValues from "../../configs/globalValues";
import validations from "@/validations";
import helpers from "@/utils/rentIncreaseHelpers";
import VatComponent from "@/components/Invoice/VatComponent";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    VatComponent,
  },

  data() {
    return {
      dueDateMenu: false,
      vatTypes: globalValues.vatTypes,
      productItemTypes: globalValues.productItemTypes,
      validations,
    };
  },

  created() {
    this.$root.$refs.rentIncreaseProducts = this;
  },

  computed: {
    ...mapState("rentIncrease", ["currentContract", "newRentIncrease", "productsBtnAnimation"]),
    ...mapState("product", ["products"]),
    ...mapState("account", ["currentUser"]),

    dueDate: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.dueDate;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "dueDate" });
      },
    },
    sendType: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.sendType;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "sendType" });
      },
    },
    paymentTerm: {
      get() {
        return this.$store.state.rentIncrease.newRentIncrease.paymentTerm;
      },
      set(val) {
        this.setNewRentIncreaseValue({ val, field: "paymentTerm" });
      },
    },

    sendTypes() {
      return globalValues.sendTypes(
        this.currentContract.tenant.tenantId.isCompany,
        this.currentUser.currentAccount.isCompany
      );
    },

    sendTypeValidation() {
      let boolean;
      if (this.sendType == "post") {
        if (
          this.currentContract.baseInvoice.isUpdated &&
          this.currentContract.baseInvoice.updatedSendingAddress &&
          this.currentContract.baseInvoice.updatedSendingAddress.address
        ) {
          boolean =
            this.currentContract.baseInvoice.updatedSendingAddress.address != "" &&
            this.currentContract.baseInvoice.updatedSendingAddress.zipCode != "" &&
            this.currentContract.baseInvoice.updatedSendingAddress.city != "";
        } else {
          boolean =
            this.currentContract.baseInvoice.sendingAddress.address != "" &&
            this.currentContract.baseInvoice.sendingAddress.zipCode != "" &&
            this.currentContract.baseInvoice.sendingAddress.city != "";
        }
      }
      if (this.sendType == "email") {
        if (
          this.currentContract.baseInvoice.isUpdated &&
          this.currentContract.baseInvoice.updatedTenant &&
          this.currentContract.baseInvoice.updatedTenant.name
        ) {
          boolean = this.currentContract.baseInvoice.updatedTenant.email != "";
        } else {
          boolean = this.currentContract.baseInvoice.tenant.email != "";
        }
      }
      if (this.sendType == "evoice") {
        boolean =
          this.currentContract.baseInvoice.evoice.evoiceAddress != "" &&
          this.currentContract.baseInvoice.evoice.evoiceId != "";
      }
      if (this.sendType == "print") {
        boolean = true;
      }

      return boolean;
    },

    paymentTerms() {
      return globalValues.paymentTerms(this.currentContract.baseInvoice.invoiceInterval);
    },

    billDate() {
      let dueDate = moment(this.newRentIncrease.dueDate);

      const nextBillDate = dueDate
        .subtract(this.newRentIncrease.paymentTerm, "days")
        .format("YYYY-MM-DD");

      if (Date.now() > new Date(nextBillDate).getTime()) {
        return moment(Date.now() + 24 * 60 * 60 * 1000).format("YYYY-MM-DD");
      } else return nextBillDate;
    },
  },

  methods: {
    ...mapMutations("rentIncrease", [
      "addProduct",
      "setProduct",
      "deleteProduct",
      "setNewRentIncreaseValue",
      "setButtonAnimation",
      "addRentIncreaseProducts",
    ]),
    ...mapActions("rentIncrease", ["setRentIncreaseProducts", "increaseCurrentProducts"]),

    setDueDate(startDate) {
      this.dueDate = helpers.getInvoiceDueDate(this.currentContract.baseInvoice, startDate);
    },

    saveAllValues() {
      if (this.$refs.form.validate()) {
        if (this.inUse) {
          if (this.newRentIncrease.products.length == 0) {
            this.showPopup("Lisää vähintään yksi tuote!", "error");
            return;
          }
        }
        this.showPopup("Määräaikaiset tuotteet päivitetty. Muista tallentaa lasku.", "primary");

        this.dialog = false;
      } else {
        this.showPopup("Tarkista kaikki pakolliset kentät", "error");
      }
    },

    cancelSave() {
      this.cancelFixedPeriodFields();
      this.dialog = false;
    },

    setProductToState(event, product, index) {
      if (event) this.setProduct({ event, product, index });
    },

    allowedDates(givenMonth) {
      const invoiceInterval = this.currentContract.baseInvoice.invoiceInterval;

      // first month when available
      const rentIncreaseDateMonth = moment(this.currentContract.lease.nextRentIncreaseDate).format(
        "YYYY-MM"
      );

      // first month when available
      const nextDueDate = moment(this.currentContract.baseInvoice.nextDueDate).format("YYYY-MM");

      const timeDiff = moment(givenMonth).diff(moment(nextDueDate), "months", true);
      const reminder = timeDiff % invoiceInterval;

      return moment(givenMonth).isSameOrAfter(rentIncreaseDateMonth) &&
        moment(givenMonth).isSameOrAfter(nextDueDate) &&
        reminder === 0
        ? true
        : false;
    },

    resetValues() {
      // Reset fields when closing the whole dialog
      this.$refs.form.reset();
      this.setNewRentIncreaseValue({
        val: "",
        field: "dueDate",
      });
      this.addRentIncreaseProducts([]);
      this.setButtonAnimation(true);
    },

    validateForm() {
      return this.$refs.form.validate() && this.sendTypeValidation;
    },
  },
};
</script>

<style scoped>
.details-container {
  display: flex;
}

.invoice-overview {
  padding: 20px;
  width: 100%;
  border: 2px solid var(--v-success-base);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.invoice-overview p {
  padding: 0;
  margin: 0;
}

.contract-text {
  font-size: 14px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}

.get_products_btn {
  box-shadow: 0 0 0 0 rgb(105, 105, 105);
  animation: pulse 2s infinite;
}

.sendtype-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(173, 173, 173, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(201, 201, 201, 0);
  }
}
</style>
