<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa vuokralaista</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan vuokralaista..."
      class="full-page-loader"
    ></full-page-loader>

    <p v-if="!loading" style="font-size: 18px">{{ currentTenant.name }}</p>

    <tenant-form
      v-if="!loading"
      class="mt-2"
      submit-btn-text="Tallenna"
      @submit="submit"
    ></tenant-form>

    <update-tenant-info-to-service
      v-model="tenantUpdateInfoDialog"
      :tenant="currentTenant"
      @update="update"
    ></update-tenant-info-to-service>
  </div>
</template>

<script>
import TenantForm from "../../components/Tenant/TenantForm";
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import { mapMutations, mapActions, mapState } from "vuex";
import UpdateTenantInfoToService from "../../components/Tenant/UpdateTenantInfoToServiceDialog.vue";
import FullPageLoader from "@/components/FullPageLoader";

export default {
  title: "Muokkaa vuokralaista",

  data() {
    return {
      tenantUpdateInfoDialog: false,
    };
  },

  components: {
    FullPageLoader,
    TenantForm,
    UpdateTenantInfoToService,
  },

  mixins: [mixins, invoiceMixins],

  computed: {
    ...mapState("tenant", ["currentTenant", "trackedFields", "loading"]),
  },

  async created() {
    try {
      this.setLoading(true);

      await this.getOneTenant(`/api/v1/tenant/getOne/${this.$route.params.id}`);

      // Set tracked fields to tenant to check if servicebased info is changed and if yes, update to invoice service
      this.setTrackedFieldsDefaultValues();

      if (this.currentTenant.isOnlyKeyHolder) {
        this.setTenantField({
          field: "selectedTenantType",
          val: "keyHolder",
        });
      } else {
        this.setTenantField({
          field: "selectedTenantType",
          val: "tenant",
        });
      }

      this.setLoading(false);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("tenant", [
      "setLoading",
      "setCurrentTenant",
      "setLoading",
      "setTenantField",
      "setDefaultValuesToTrackedFields",
    ]),
    ...mapActions("tenant", ["getOneTenant", "updateTenant"]),

    async submit() {
      // Check if updates are needed from the service
      if (this.canUpdateTenantDataToService(this.currentTenant)) {
        // Flatten the trackedFields object
        const flattenedFields = this.flattenObject(this.trackedFields);
        const keys = Object.keys(flattenedFields);
        let makeChangesToService = false;

        for (const key of keys) {
          // Only consider keys that end with .current, .old, or .changeable
          if (key.endsWith(".current") || key.endsWith(".old") || key.endsWith(".changeable")) {
            const baseKey = key.split(".").slice(0, -1).join(".");
            const old = flattenedFields[`${baseKey}.old`];
            const current = flattenedFields[`${baseKey}.current`];

            // Can be updated to invoice service
            if (old !== current) makeChangesToService = true;
          }
        }

        if (makeChangesToService) {
          this.tenantUpdateInfoDialog = true;
        } else {
          this.update(false);
        }
      } else {
        this.update(false);
      }
    },

    async update(updateDataToService) {
      try {
        // Update tenant
        await this.updateTenant({
          tenantId: this.$route.params.id,
          data: this.currentTenant,
          updateDataToService,
        });
        this.tenantUpdateInfoDialog = false;
        this.setTrackedFieldsDefaultValues();
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    setTrackedFieldsDefaultValues() {
      this.setDefaultValuesToTrackedFields({
        name: {
          old: this.currentTenant.name,
          current: this.currentTenant.name,
        },
        isCompany: {
          old: this.currentTenant.isCompany,
          current: this.currentTenant.isCompany,
        },
        businessId: {
          old: this.currentTenant.businessId || null,
          current: this.currentTenant.businessId || null,
        },
        phone: {
          old: this.currentTenant.phone || null,
          current: this.currentTenant.phone || null,
        },
        email: {
          old: this.currentTenant.email || null,
          current: this.currentTenant.email || null,
        },
        evoice: {
          evoiceOperator: {
            old: this.currentTenant.evoice.evoiceOperator || "",
            current: this.currentTenant.evoice.evoiceOperator || "",
          },
          evoiceId: {
            old: this.currentTenant.evoice.evoiceId || "",
            current: this.currentTenant.evoice.evoiceId || "",
          },
          evoiceAddress: {
            old: this.currentTenant.evoice.evoiceAddress || "",
            current: this.currentTenant.evoice.evoiceAddress || "",
          },
        },
        invoiceLanguage: {
          old: this.currentTenant.invoiceLanguage || "",
          current: this.currentTenant.invoiceLanguage || "",
        },
      });
    },
  },
};
</script>

<style scoped></style>
