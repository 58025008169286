<template>
  <v-form ref="form">
    <v-row dense>
      <v-col cols="12" sm="6" md="3" lg="3" xl="2">
        <v-menu
          ref="startMenu"
          v-model="startMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(searchByDates.startDate)"
              outlined
              dense
              label="Alkaen"
              v-bind="attrs"
              v-on="on"
              append-icon="mdi-calendar"
              ref="startDateFormatted"
              v-prevent-manual-input
              :readonly="true"
              hide-details
              clearable
              :rules="startDateRequired ? validations.required : []"
              @click:clear="searchByDates.startDate = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="searchByDates.startDate"
            :allowed-dates="checkStartDate"
            first-day-of-week="1"
            @input="startMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3" xl="2">
        <v-menu
          ref="endMenu"
          v-model="endMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(searchByDates.endDate)"
              outlined
              dense
              label="Päättyen"
              v-bind="attrs"
              v-on="on"
              append-icon="mdi-calendar"
              v-prevent-manual-input
              :readonly="true"
              hide-details
              clearable
              :rules="endDateRequired ? validations.required : []"
              @click:clear="searchByDates.endDate = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="searchByDates.endDate"
            :allowed-dates="checkEndDate"
            first-day-of-week="1"
            @input="endMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="showSearchBy" cols="12" sm="6" md="3" lg="3" xl="2">
        <v-select
          v-model="searchByDates.searchBy"
          hide-details
          outlined
          dense
          label="Haku päiväyksen mukaan"
          :items="searchByItems"
          item-text="text"
          item-value="val"
        >
        </v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import mixins from "@/mixins/mixins";
import validations from "../../validations";

export default {
  props: {
    searchByItems: {
      type: Array,
      default: function () {
        return [
          { text: "Laskun pvm.", val: "billDate" },
          { text: "Kirjauspäivä", val: "accountDate" },
          { text: "Eräpäivä", val: "dueDate" },
        ];
      },
    },
    showSearchBy: {
      type: Boolean,
      default: true,
    },
    startDateRequired: {
      type: Boolean,
      default: false,
    },
    endDateRequired: {
      type: Boolean,
      default: false,
    },
    checkStartDate: {
      type: Function,
      default: function () {
        return true;
      },
    },
    checkEndDate: {
      type: Function,
      default: function () {
        return true;
      },
    },
  },

  mixins: [mixins],

  data() {
    return {
      startMenu: false,
      endMenu: false,
      validations,
    };
  },

  computed: {
    ...mapState("invoice", ["searchByDates"]),
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style></style>
