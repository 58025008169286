<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h2>Vuokrakohteen tiedot</h2>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- COL1 -->
      <v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>Kohdetiedot</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Osoite:</span>
              <strong class="right">{{ a.address }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Kohteen numero: </span>
              <strong class="right">{{ a.apartmentNumber }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Postinumero:</span>
              <strong class="right">{{ a.zipCode }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Postitoimipaikka:</span>
              <strong class="right">{{ a.city }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Kaupunginosa:</span>
              <strong class="right">{{ a.neighborhood || "" }}</strong>
            </p>
            <p v-if="a.country" class="info-wrapper">
              <span class="left">Maa:</span>
              <strong class="right">{{ a.country }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Taloyhtiö:</span>
              <strong class="right">{{ a.condominium.name }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Y-tunnus:</span>
              <strong class="right">{{ a.condominium.businessId }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Osakenumerot:</span>
              <strong class="right">{{ a.shareNumbers || "" }}</strong>
            </p>
            <p v-if="a.vatRegister" class="info-wrapper">
              <span class="left">Alv-velvollinen: </span>
              <strong class="right">{{ a.vatRegister ? "Kyllä" : "Ei" }}</strong>
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>Hintatiedot</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p v-if="a.purchaseDate" class="info-wrapper">
              <span class="left">Ostopäivä:</span>
              <strong class="right">{{ formatDate(a.purchaseDate) }}</strong>
            </p>
            <p v-if="a.priceWithTaxes" class="info-wrapper">
              <span class="left">Ostohinta:</span>
              <strong class="right">{{ formatCurrency(a.priceWithTaxes) }}</strong>
            </p>
            <p v-if="a.estimatedPrice" class="info-wrapper">
              <span class="left">Markkinahinta:</span>
              <strong class="right">{{ formatCurrency(a.estimatedPrice) }}</strong>
            </p>
            <p v-if="a.estimatedRent" class="info-wrapper">
              <span class="left">Markkinavuokra:</span>
              <strong class="right">{{ formatCurrency(a.estimatedRent) }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Nyk. hoitovastike:</span>
              <strong class="right">{{ formatCurrency(a.maintenanceCharge) }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Nyk. vesimaksu:</span>
              <strong class="right">{{ formatCurrency(a.waterCharge) }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Hoitovastike</span>
              <strong class="right"
                >{{ formatCurrency(a.condominium.maintenancePerSquareCost) }}/m²</strong
              >
            </p>
            <p class="info-wrapper">
              <span class="left">Vesimaksu / ennakko:</span>
              <strong class="right">{{ formatCurrency(a.condominium.waterCost) }}/hlö</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Mediamaksu:</span>
              <strong class="right">{{ formatCurrency(a.condominium.mediaCost) }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Yhtiölainat:</span>
              <strong class="right">{{ formatCurrency(currentLoans.totalAmount) }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Rahoitusvastike:</span>
              <strong class="right">{{ formatCurrency(currentLoans.amount) }}/kk</strong>
            </p>
            <p v-if="a.deposit" class="info-wrapper">
              <span class="left">Vakuus:</span>
              <strong class="right">{{ formatCurrency(a.deposit) }}</strong>
            </p>
            <p v-if="electricityType" class="info-wrapper">
              <span class="left">Sähkötyyppi:</span>
              <strong class="right">{{ electricityType }}</strong>
            </p>
            <p v-if="a.electricityType === 2 && a.fixedElectricity" class="info-wrapper">
              <span class="left">Sähkömaksu:</span>
              <strong class="right">{{ formatCurrency(a.fixedElectricity) }}</strong>
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>Ominaisuudet</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Vuokrattavana: </span>
              <strong class="right">{{ a.activeForRent ? "Kyllä" : "Ei" }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Tyyppi:</span>
              <strong class="right">{{ a.houseType }}</strong>
            </p>
            <p v-if="a.floor" class="info-wrapper">
              <span class="left">Kerros:</span>
              <strong class="right"
                >{{ a.floor }}
                <span v-if="a.condominium.floors">/ {{ a.condominium.floors }}</span></strong
              >
            </p>
            <p class="info-wrapper">
              <span class="left">Pinta-ala:</span>
              <strong class="right">{{ a.area }} m²</strong>
            </p>
            <p v-if="a.floorPlan" class="info-wrapper">
              <span class="left">Kokoonpano:</span>
              <strong class="right">{{ a.floorPlan }}</strong>
            </p>
            <p v-if="a.roomCount" class="info-wrapper">
              <span class="left">Huoneiden määrä:</span>
              <strong class="right">{{ a.roomCount }}</strong>
            </p>
            <p v-if="currentCondition" class="info-wrapper">
              <span class="left">Kunto:</span>
              <strong class="right">{{ currentCondition }}</strong>
            </p>
            <p v-if="a.balcony" class="info-wrapper">
              <span class="left">Parveke:</span>
              <strong class="right">{{ a.balcony ? "Kyllä" : "Ei" }}</strong>
            </p>
            <p v-if="a.animals" class="info-wrapper">
              <span class="left">Eläimet ok:</span>
              <strong class="right">{{ a.animals ? "Kyllä" : "Ei" }}</strong>
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!-- COL2 -->
      <v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-sm-1" dense>
          <v-col cols="12">
            <h3>Isännöitsijä</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Yritys: </span>
              <strong class="right">{{ contact.administratorCompany }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Nimi: </span>
              <strong class="right">{{ contact.administratorName }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Puhelin: </span>
              <strong class="right">{{ contact.administratorPhone }}</strong>
            </p>
            <p v-if="contact.administratorEmail" class="info-wrapper">
              <span class="left">Email: </span>
              <strong class="right link" @click="mailTo([contact.administratorEmail])">
                {{ contact.administratorEmail }}
              </strong>
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>Kiinteistösihteeri / Kirjanpitäjä</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Yritys: </span>
              <strong class="right">{{ contact.accountantCompany }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Nimi: </span>
              <strong class="right">{{ contact.accountantName }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Puhelin: </span>
              <strong class="right">{{ contact.accountantPhone }}</strong>
            </p>
            <p v-if="contact.accountantEmail" class="info-wrapper">
              <span class="left">Email: </span>
              <strong class="right link" @click="mailTo([contact.accountantEmail])">
                {{ contact.accountantEmail }}
              </strong>
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-1" dense>
          <v-col cols="12">
            <h3>Huolto</h3>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Yritys: </span>
              <strong class="right">{{ contact.propertyMaintenanceCompany }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Nimi: </span>
              <strong class="right">{{ contact.janitor }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Puhelin: </span>
              <strong class="right">{{ contact.janitorPhone }}</strong>
            </p>
            <p v-if="contact.janitorEmail" class="info-wrapper">
              <span class="left">Email: </span>
              <strong class="right link" @click="mailTo([contact.janitorEmail])">
                {{ contact.janitorEmail }}
              </strong>
            </p>
          </v-col>
        </v-row>
      </v-col>

      <!-- COL3 -->
      <v-col cols="12" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pr-sm-3">
        <v-row class="mt-sm-1" dense>
          <v-col cols="12">
            <h3>Toimeksianto</h3>
          </v-col>
        </v-row>

        <v-row v-if="!assignmentInUse" dense>
          <v-col cols="12">
            <p>Toimeksiantosopimus ei ole käytössä.</p>
          </v-col>
        </v-row>

        <v-row v-if="assignmentInUse && assignment" dense>
          <v-col cols="12">
            <p class="info-wrapper">
              <span class="left">Voimassa: </span>
              <strong class="right">{{ assignmentDates }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Laskutus: </span>
              <strong class="right">{{ assignmentTypeAndAmount }}</strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Välittäjät: </span>
              <strong class="right">
                <div v-for="(realtor, idx) in assignment.realtors" :key="idx + 'a'">
                  <p>{{ realtor.name }} ({{ realtor.rewardPercent }} %)</p>
                </div>
              </strong>
            </p>
            <p class="info-wrapper">
              <span class="left">Kommentti: </span>
              <strong class="right">{{ assignment.comments }}</strong>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "@/mixins/mixins";
import globalValues from "../../configs/globalValues";

export default {
  props: ["sm", "md", "lg", "xl"],

  mixins: [mixins],

  computed: {
    a() {
      return this.$store.state.apartment.currentApartment;
    },

    contact() {
      return {
        administratorCompany: this.a?.condominium?.administrator?.companyName || "",
        administratorName: this.a?.condominium?.administrator?.name || "",
        administratorEmail: this.a?.condominium?.administrator?.email || "",
        administratorPhone: this.a?.condominium?.administrator?.phone || "",
        accountantCompany: this.a?.condominium?.administrator?.accountantCompany || "",
        accountantName: this.a?.condominium?.administrator?.accountantName || "",
        accountantEmail: this.a?.condominium?.administrator?.accountantEmail || "",
        accountantPhone: this.a?.condominium?.administrator?.accountantPhone || "",
        propertyMaintenanceCompany:
          this.a?.condominium?.administrator?.propertyMaintenanceCompany || "",
        janitor: this.a?.condominium?.administrator?.janitor || "",
        janitorEmail: this.a?.condominium?.administrator?.janitorEmail || "",
        janitorPhone: this.a?.condominium?.administrator?.janitorPhone || "",
      };
    },

    currentLoans() {
      if (this.a.currentLoans && this.a.currentLoans.length > 0) {
        return this.a.currentLoans.reduce(
          (sum, cur) => {
            if (cur.amount) sum["amount"] += cur.amount;

            if (cur.currentAmount) sum["totalAmount"] += cur.currentAmount;
            else sum["totalAmount"] += cur.totalAmount;

            return sum;
          },
          { amount: 0, totalAmount: 0 }
        );
      } else {
        return 0;
      }
    },

    currentCondition() {
      const conditions = { 1: "Hyvä", 2: "Tyydyttävä", 3: "Välttävä" };
      const conditionIdx = this.$store.state.apartment?.currentApartment?.currentCondition;
      return conditionIdx != undefined ? conditions[String(conditionIdx)] : null;
    },

    electricityType() {
      const elecIdx = this.$store.state.apartment?.currentApartment?.electricityType;
      if (elecIdx) {
        let element = globalValues.electricityTypes.find((el) => el.val === elecIdx);
        if (element) return element.text;
      }
      return null;
    },

    assignmentInUse() {
      return this.$store.state.apartment?.currentApartment?.assignment?.inUse ? true : false;
    },

    assignment() {
      if (!this.assignmentInUse) return null;
      else
        return {
          startDate: this.a?.assignment?.startDate || null,
          endDate: this.a?.assignment?.endDate || null,
          rewardType: this.a?.assignment?.rewardType || "Ei palkkiomallia",
          fixedAmountPerMonth: this.a?.assignment?.fixedAmountPerMonth || 0,
          percent: this.a?.assignment?.percent || 0,
          comments: this.a?.assignment?.comments || "",
          realtors: this.a?.assignment?.realtors || [],
        };
    },

    assignmentDates() {
      if (this.assignmentInUse && this.assignment) {
        const start = this.assignment.startDate;
        const end = this.assignment.endDate || null;
        return end
          ? `${this.formatDate(start)} - ${this.formatDate(end)}`
          : `${this.formatDate(start)} -`;
      } else {
        return "";
      }
    },

    assignmentTypeAndAmount() {
      if (this.assignmentInUse && this.assignment) {
        const rewardType =
          this.assignment.rewardType === "fixed"
            ? "Kiinteä / kk"
            : this.assignment.rewardType === "percent"
            ? "Prosenttiosuus"
            : this.assignment.rewardType;
        const percent = this.assignment.percent;
        const fixedAmountPerMonth = this.assignment.fixedAmountPerMonth;
        const reward =
          this.assignment.rewardType === "fixed"
            ? `(${this.formatCurrency(fixedAmountPerMonth)})`
            : this.assignment.rewardType === "percent"
            ? `(${percent + 100} %)`
            : this.assignment.rewardType;
        return `${rewardType} ${reward}`;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.info-wrapper {
  display: flex;
  border-bottom: 1px solid #d4d4d4;
}

.left {
  width: 140px;
}

.right {
  flex: 1;
  text-align: right;
}

.link {
  color: var(--v-info-base);
  cursor: pointer;
}
</style>
