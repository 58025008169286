<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa voimassa olevaa vuokrasopimusta {{ contract.contractNumber }}</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <OldRentalContractForm
      btn-text="Tallenna"
      :contr="contract"
      @sendcontract="editContract($event)"
    >
    </OldRentalContractForm>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import OldRentalContractForm from "../../components/RentalContract/OldRentalContract/OldRentalContractForm";
import { mapMutations } from "vuex";

export default {
  title: "Muokkaa voimassa olevaa vuokrasopimusta",

  components: {
    OldRentalContractForm,
  },

  mixins: [mixins],

  data() {
    return {
      contract: {},
    };
  },

  async created() {
    try {
      this.setLoading(true);

      let res = await axiosMethods.get(
        `/api/v1/rental-contract/get-one-formatted/${this.$route.params.id}`
      );
      this.contract = res.data.contract;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("contract", ["setLoading"]),

    async editContract(data) {
      try {
        const res = await axiosMethods.update(
          "/api/v1/rental-contract/update/" + this.$route.params.id,
          data
        );

        if (res.data.duplicateTenantEmails.length > 0) {
          this.showPopup(
            `Tallennettaessa löytyi päällekkäisiä vuokralaisten emaileja: \n ${res.data.duplicateTenantEmails
              .map((result) => `${result.email}: ${result.count} kpl`)
              .join(", \n")}`,
            "info",
            -1
          );
        }

        if (res.data.duplicatePartnerEmails.length > 0) {
          this.showPopup(
            `Tallennettaessa löytyi päällekkäisiä toimeksiantajien emaileja: \n ${res.data.duplicatePartnerEmails
              .map((result) => `${result.email}: ${result.count} kpl`)
              .join(", \n")}`,
            "info",
            -1
          );
        }

        this.showPopup("Voimassa olevan vuokrasopimuksen muokkaaminen onnistui", "success");

        this.$router.push("/rental-contracts");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
