import axiosMethods from "../mixins/axios";

export default {
  async getTenantReport() {
    return await axiosMethods.get("/api/v1/reports/get-tenant-report");
  },
  async getRentReport(dates) {
    return await axiosMethods.post("/api/v1/reports/rent-report", dates);
  },
  async getContractReport(dates) {
    return await axiosMethods.post("/api/v1/reports/contract-report", dates);
  },
  async getAssignmentReport(data) {
    return await axiosMethods.post("/api/v1/reports/assignment-report", data);
  },
  async getRentReportReceipts(dates) {
    return await axiosMethods.post("/api/v1/reports/rent-report-receipts", dates);
  },
  async getApartmentStatistics(data) {
    return await axiosMethods.post(data.url);
  },
  async getRentRollReport() {
    return await axiosMethods.get("/api/v1/reports/rent-roll-report");
  },
  async getOutgoingPaymentIncomeReport(data) {
    return await axiosMethods.post("/api/v1/reports/outgoing-payment-income-report", data);
  },
};
