<template>
  <div>
    <span class="font-weight-bold mr-1">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span v-on="on" :class="`${color}--text`" style="cursor: pointer">
            {{ formatCurrency(item.totalAmount) }}
          </span>
        </template>
        <div>
          <p class="font-weight-bold">Tuotteet</p>
          <span v-for="(prod, idx) in item.products" :key="'a' + idx">
            {{ replaceProductTags(prod.desc, item.dueDate, item.accountDate, item.language) }} |
            {{ formatCurrency(prod.amount * prod.count * (prod.taxpr / 100 + 1)) }} <br />
          </span>
        </div>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import invoiceMixins from "@/mixins/invoiceMixins";
export default {
  mixins: [mixins, invoiceMixins],

  props: {
    item: Object,
    color: {
      type: String,
      default: "success",
    },
  },
};
</script>

<style></style>
