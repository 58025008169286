<template>
  <v-dialog v-model="dialog" width="900">
    <v-card>
      <v-container>
        <v-card-title> {{ title }} </v-card-title>

        <v-card-text class="mt-1">
          <v-form ref="form">
            <h3 class="mt-1">Tyyppi ja talletus</h3>
            <v-row dense>
              <v-col sm="6" md="4" lg="4">
                <v-select
                  v-model="deposit.name"
                  dense
                  outlined
                  :items="names"
                  label="Vakuuden tyyppi"
                  hide-details
                  :rules="required"
                >
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="deposit.depositType"
                  dense
                  outlined
                  :items="depositTypes"
                  item-text="text"
                  item-value="val"
                  label="Talletustapa"
                  hide-details
                  :rules="required"
                ></v-select>
              </v-col>
            </v-row>

            <h3 class="mt-1">Lasku ja summat</h3>

            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model.number="deposit.targetAmount"
                  v-prevent-paste
                  dense
                  outlined
                  type="number"
                  step="0.01"
                  label="Tavoitesumma"
                  hide-details
                  suffix="€"
                  :rules="targetAmountNeeded"
                  @input="hideResendBtn = true"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="targetDueDateMenu"
                  v-model="targetDueDateMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(deposit.targetDueDate)"
                      v-bind="attrs"
                      v-on="on"
                      label="Eräpäivä"
                      dense
                      outlined
                      append-icon="mdi-calendar"
                      v-prevent-manual-input
                      :readonly="true"
                      hide-details
                      clearable
                      @click:clear="deposit.targetDueDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="deposit.targetDueDate"
                    first-day-of-week="1"
                    @input="targetDueDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col v-if="!deposit.relatedInvoiceText" cols="12" sm="6" md="4">
                <!-- Related Invoice -->
                <v-autocomplete
                  v-model="deposit.relatedInvoice"
                  :items="activeInvoices"
                  :item-text="invoiceNumberTenant"
                  item-value="_id"
                  label="Liittyy Vuokranet laskuun"
                  no-data-text="Laskun numero tai laskunsaaja"
                  outlined
                  dense
                  small-chips
                  return-object
                  deletable-chips
                  :search-input.sync="searchInput4"
                  @change="searchInput4 = ''"
                  @input.native="getInvoices"
                  hide-details
                >
                </v-autocomplete>
                <div v-if="deposit.relatedInvoice">
                  <p>
                    Laskun saatavat yht:
                    {{ formatCurrency(deposit.relatedInvoice.totalAmount) }}
                  </p>
                  <p>
                    Tehdyt hyvitykset yhteensä:
                    {{ formatCurrency(calcRefundTotal(deposit.relatedInvoice.refundInvoiceIds)) }}
                  </p>
                </div>
              </v-col>
            </v-row>

            <div v-if="!deposit.relatedInvoice">
              <h3 class="mt-1">Muut tiedot</h3>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <!-- Related Invoice text -->
                  <v-text-field
                    v-model="deposit.relatedInvoiceText"
                    dense
                    outlined
                    label="Laskun nro. tai linkki"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="deposit.referenceNumber"
                    dense
                    outlined
                    label="Laskun viitenumero"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="deposit.assignmentNumber"
                    dense
                    outlined
                    label="Toimeksiannon numero"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <!-- Released -->
            <div v-if="deposit.released.length > 0">
              <v-divider class="mb-2 mt-2"></v-divider>

              <h3 class="mb-1">Palautetut vakuudet</h3>

              <div v-for="(item, index) in deposit.released" :key="index + 'a'">
                <p class="text-label">
                  <strong> Päiväys: </strong>
                  {{ formatDate(item.date) }},
                  <strong> Summa: </strong>

                  <span>{{ formatCurrency(item.amount) }}</span>
                </p>
              </div>
            </div>

            <v-divider class="mb-1 mt-2"></v-divider>

            <!-- Received -->
            <h3 class="mb-1 mt-2">Vastaanotetut vakuudet</h3>

            <div v-for="(item, index) in deposit.received" :key="index + 'b'">
              <v-row dense :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }">
                <v-col cols="10" sm="5" md="3">
                  <v-text-field
                    v-model.number="item.amount"
                    dense
                    outlined
                    type="number"
                    step="0.01"
                    label="Määrä"
                    suffix="€"
                    :rules="positiveNumber"
                    hide-details
                    @input="hideResendBtn = true"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-menu
                    :ref="item.receiveDateMenu"
                    v-model="item.receiveDateMenu"
                    :close-on-content-click="false"
                    min-width="290"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.dateFormattedReceiveDate"
                        dense
                        outlined
                        v-bind="attrs"
                        :rules="required"
                        v-on="on"
                        append-icon="mdi-calendar"
                        label="Saapumispäivä"
                        v-prevent-manual-input
                        :readonly="true"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.date"
                      first-day-of-week="1"
                      @input="item.receiveDateMenu = false"
                      @change="hideResendBtn = true"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="5" md="5">
                  <div class="details-container">
                    <v-text-field
                      v-model="item.name"
                      dense
                      outlined
                      label="Maksaja"
                      hide-details
                    ></v-text-field>

                    <v-icon class="ml-1" color="error" small @click="deleteReceived(index)"
                      >mdi-delete</v-icon
                    >
                  </div>
                </v-col>
              </v-row>
            </div>

            <v-row dense>
              <v-col>
                <v-btn color="primary" class="mr-4 mb-3" @click="addReceived"
                  >Lisää vakuussumma</v-btn
                >
              </v-col>
            </v-row>

            <h3 class="mb-1">Liitännät</h3>
            <v-row dense>
              <!-- Related Contract -->
              <v-col
                v-if="!deposit.relatedApartment"
                cols="12"
                sm="6"
                md="4"
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-autocomplete
                  v-model="deposit.relatedContract"
                  :items="activeRentalContracts"
                  :item-text="nameContractnumber"
                  item-value="_id"
                  label="Liittyy vuokrasopimukseen"
                  no-data-text="Vuokrasopimuksen numero tai vuokralainen"
                  outlined
                  small-chips
                  return-object
                  deletable-chips
                  :rules="required"
                  :search-input.sync="searchInput1"
                  @change="
                    searchInput1 = '';
                    deposit.relatedTenants = [];
                    deposit.relatedApartment = null;
                    hideResendBtn = true;
                  "
                  @input.native="getRentalContracts"
                >
                </v-autocomplete>
              </v-col>

              <!-- Related Tenants -->
              <v-col
                v-if="!deposit.relatedContract"
                cols="12"
                sm="6"
                md="4"
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-autocomplete
                  v-model="deposit.relatedTenants"
                  :items="activeTenants"
                  :item-text="nameAndSocial"
                  item-value="_id"
                  label="Liittyy vuokralaiseen"
                  no-data-text="Vuokralaisen nimi"
                  outlined
                  small-chips
                  return-object
                  deletable-chips
                  multiple
                  :rules="requiredArr"
                  :search-input.sync="searchInput2"
                  @change="searchInput2 = ''"
                  @input.native="getTenants"
                >
                </v-autocomplete>
              </v-col>

              <!-- Related Apartment -->
              <v-col cols="12" sm="6" md="4" v-if="!deposit.relatedContract">
                <v-autocomplete
                  v-model="deposit.relatedApartment"
                  :items="activeApartments"
                  :item-text="fullAddress"
                  item-value="_id"
                  label="Liittyy vuokrakohteeseen"
                  no-data-text="Vuokrakohteen osoite"
                  outlined
                  small-chips
                  return-object
                  deletable-chips
                  :rules="required"
                  :search-input.sync="searchInput3"
                  @change="
                    searchInput3 = '';
                    deposit.relatedContract = null;
                    hideResendBtn = true;
                  "
                  @input.native="getApartments"
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12">
                <v-textarea
                  v-model="deposit.comment"
                  label="Vakuuden kommentti"
                  dense
                  outlined
                  hide-details
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row dense v-if="deposit.comments.length > 0">
              <v-col cols="12">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Kommenttihistoria ({{ deposit.comments.length }})</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div v-for="(comment, index) in deposit.comments" :key="index + 'b'">
                        <p style="margin-top: 6px; margin-bottom: -2px">{{ comment.text }}</p>
                        <small
                          >{{ comment.createdBy.name }}, {{ formatDate(comment.createdAt) }}</small
                        >
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <div v-if="isAuthorized('realtor', 'create')">
              <!-- Received -->
              <h3 class="mb-1 mt-2">Lähetä kuittaukset</h3>

              <v-row dense>
                <v-col cols="12" sm="6" md="5">
                  <v-checkbox
                    v-model="deposit.sendReceivedEmail"
                    label="Lähetä vastaanottoviesti"
                    hide-details
                    style="margin-top: 0"
                    @change="
                      deposit.relatedRealtors = !deposit.sendReceivedEmail
                        ? []
                        : deposit.relatedRealtors
                    "
                  ></v-checkbox>
                  <small style="font-size: 12px"
                    >Vakuuden vastaanottamisesta lähetetään viesti vuokralaisille sekä valituille
                    välittäjille.</small
                  >
                </v-col>

                <v-col v-if="deposit.sendReceivedEmail" cols="12" sm="12" md="7">
                  <v-autocomplete
                    v-model="deposit.relatedRealtors"
                    :items="activeRealtors"
                    :item-text="nameAndEmail"
                    item-value="_id"
                    label="Välittäjät"
                    no-data-text="Välittäjän nimi tai email"
                    outlined
                    small-chips
                    return-object
                    deletable-chips
                    multiple
                    :rules="requiredArr"
                    :search-input.sync="searchInput5"
                    @change="searchInput5 = ''"
                    @input.native="getRealtors"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col v-if="deposit.sendReceivedEmail" cols="12">
                  <v-textarea
                    v-model="deposit.customMessage"
                    label="Vakuuskohtainen email-viesti."
                    dense
                    outlined
                    hide-details
                    rows="3"
                  ></v-textarea>
                  <small class="error--text"
                    >Tämä viestikenttä liitetään aina email-viestiin, joka lähtee asiakkaalle ja
                    välittäjälle!</small
                  >
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-btn
                    v-if="
                      !hideResendBtn &&
                      deposit.sendReceivedEmail &&
                      (deposit.emailStatus == 'send' || deposit.emailStatus == 'edit')
                    "
                    color="primary"
                    small
                    @click="resendMessage"
                    >Lähetä kuittaus uudelleen</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="createOrEdit">{{ btnText }}</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import { debounce } from "../../utils/helpers";
import _ from "lodash";
import { eventBus } from "../../main";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data(vm) {
    return {
      editVal: false,
      searchInput1: "",
      searchInput2: "",
      searchInput3: "",
      searchInput4: "",
      searchInput5: "",
      hideResendBtn: false,
      apartmentInput: "",
      realtorInput: "",
      contractInput: "",
      tenantInput: "",
      invoiceInput: "",
      targetDueDateMenu: false,
      deposit: {
        depositType: "",
        name: "",
        assignmentNumber: "",
        targetAmount: null,
        targetDueDate: null,
        relatedTenants: [],
        relatedContract: null,
        relatedApartment: null,
        relatedInvoice: null,
        relatedInvoiceText: "",
        referenceNumber: "",
        relatedRealtors: [],
        received: [],
        released: [],
        files: [],
        comment: "",
        comments: [],
        customMessage: "",
        sendReceivedEmail: false,
      },
      defaultDepositForm: {},
      depositTypes: [
        { text: "Pankkitakaus", val: "bank" },
        { text: "Maksettu vuokranantajan tilille", val: "payment" },
        { text: "Kelan / Sosiaalitoimen maksusitoumus", val: "commitment" },
        { text: "Henkilötakaus", val: "guarantee" },
        { text: "Asiakasvaratili", val: "customerReserveAccount" },
      ],
      names: ["Vuokravakuus", "Avainpantti"],
      targetAmountNeeded: [(v) => vm.testTargetAmount(v) || "Pakollinen kenttä"],
      required: [(v) => !!v || "Pakollinen kenttä"],
      requiredArr: [(v) => vm.testArrLength(v) || "Pakollinen kenttä"],
      positiveNumber: [(v) => vm.testNumber(v) || "Summan pitää olla suurempi kuin 0"],
    };
  },

  created() {
    this.deposit.sendReceivedEmail = this.currentUser.currentAccount.settings.useDepositEmails;
    const deposit = _.cloneDeep(this.deposit);
    this.defaultDepositForm = Object.freeze(deposit);
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("contract", ["activeRentalContracts"]),
    ...mapState("apartment", ["activeApartments"]),
    ...mapState("realtor", ["activeRealtors"]),
    ...mapState("invoice", ["activeInvoices"]),
    ...mapState("tenant", ["activeTenants"]),
    ...mapState("deposit", ["currentDeposit"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    btnText() {
      if (this.edit) {
        return "Tallenna";
      } else {
        return "Luo";
      }
    },

    title() {
      if (this.edit) {
        return "Muokkaa vakuutta" + " " + this.deposit.name;
      } else {
        return "Luo vakuus";
      }
    },
  },

  watch: {
    "deposit.received": {
      deep: true,
      handler(arr) {
        arr.forEach((el) => {
          el.dateFormattedReceiveDate = this.formatDate(el.date);
        });
      },
    },

    edit(val) {
      if (!val) {
        this.deposit = _.cloneDeep(this.defaultDepositForm);
        this.$refs.form.resetValidation();
      }
    },

    currentDeposit(deposit) {
      if (deposit && this.value) {
        this.hideResendBtn = false;

        // format dates

        deposit.received.forEach((el) => {
          el.date = new Date(el.date).toISOString().substring(0, 10);
        });

        deposit.targetDueDate = deposit.targetDueDate
          ? new Date(deposit.targetDueDate).toISOString().substring(0, 10)
          : null;

        // Add contract
        if (deposit.relatedContract) {
          const contract = { ...deposit.relatedContract };
          this.$store.state.contract.activeRentalContracts = [contract];
        }

        // Add invoice
        if (deposit.relatedInvoice) {
          const invoice = { ...deposit.relatedInvoice };
          this.$store.state.invoice.activeInvoices = [invoice];
        }

        // Add apartment
        if (deposit.relatedApartment) {
          const apartment = { ...deposit.relatedApartment };
          this.$store.state.apartment.activeApartments = [apartment];
        }

        // Add realtor
        if (deposit.relatedRealtors.length > 0) {
          const realtors = [...deposit.relatedRealtors];
          this.$store.state.realtor.activeRealtors = realtors;
        }

        // Add tenants
        if (deposit.relatedTenants.length > 0) {
          const tenants = [...deposit.relatedTenants];
          this.$store.state.tenant.activeTenants = tenants;
        }

        this.deposit = _.cloneDeep(deposit);
      }
    },

    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
    }, 1000),

    realtorInput: debounce(function (newVal) {
      this.searchRealtor(`/api/v1/realtor/search-realtor?search=${newVal}`);
    }, 1000),

    contractInput: debounce(function (newVal) {
      this.searchContract(`/api/v1/rental-contract/search-rental-contract?search=${newVal}`);
    }, 1000),

    tenantInput: debounce(function (newVal) {
      this.searchTenant(`/api/v1/tenant/search-tenant?search=${newVal}`);
    }, 1000),

    invoiceInput: debounce(function (newVal) {
      this.searchInvoice(`/api/v1/invoices/search?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("contract", ["searchContract"]),
    ...mapActions("apartment", ["searchApartment"]),
    ...mapActions("realtor", ["searchRealtor"]),
    ...mapActions("invoice", ["searchInvoice"]),
    ...mapActions("tenant", ["searchTenant"]),
    ...mapActions("deposit", ["createDeposit", "editDeposit", "resendDepositMessage"]),
    ...mapMutations("deposit", ["setDeposit"]),

    getRentalContracts(event) {
      this.contractInput = event.target.value;
    },

    getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    getRealtors(event) {
      this.realtorInput = event.target.value;
    },

    getInvoices(event) {
      this.invoiceInput = event.target.value;
    },

    getTenants(event) {
      this.tenantInput = event.target.value;
    },

    nameContractnumber(item) {
      if (item.tenant) {
        return item.contractNumber + ", " + item.tenant.name;
      }
    },

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    nameAndEmail(item) {
      return `${item.name} (${item.email})`;
    },

    invoiceNumberTenant(item) {
      return `${item.invoiceNumber}, ${item.tenant.name}`;
    },

    testArrLength(val) {
      return val.length === 0 ? false : true;
    },

    testTargetAmount(val) {
      if (val === null || val === "") {
        return this.currentUser.currentAccount.settings.depositTargetAmountNeeded ? false : true;
      } else {
        if (val <= 0) {
          return false;
        } else if (this.currentUser.currentAccount.settings.depositTargetAmountNeeded) {
          return val <= 0 ? false : true;
        } else {
          return true;
        }
      }
    },

    testNumber(val) {
      if (val > 0) return true;
      else return false;
    },

    nameAndSocial(item) {
      const name = item.name;
      const isCompany = item.isCompany;
      const businessId = item.businessId || "Ei y-tunnusta";
      const social = item.social || "Ei henkilötunnusta";
      const id = isCompany ? businessId : social;
      return name + " " + `(${id})`;
    },

    addReceived() {
      this.deposit.received.push({
        date: "",
        amount: null,
        name: "",
        receiveDateMenu: false,
        dateFormattedReceiveDate: "",
      });
    },

    deleteReceived(index) {
      if (confirm("Oletko varma, että haluat poistaa vastaanotetun vakuuden?")) {
        this.deposit.received.splice(index, 1);
      }
    },

    testReceivedTotal() {
      // Test received vs released
      const receivedSum = this.deposit.received.reduce((sum, item) => sum + item.amount, 0);
      const releasedSum = this.deposit.released.reduce((sum, item) => sum + item.amount, 0);

      if (releasedSum > receivedSum) return false;

      if (this.deposit.targetAmount) {
        return this.deposit.targetAmount >= receivedSum;
      } else return true;
    },

    async createOrEdit() {
      try {
        if (this.$refs.form.validate()) {
          if (this.deposit.received.length == 0) {
            return this.showPopup("Lisää ainakin yksi vastaanotettu vakuus", "error");
          }

          if (!this.testReceivedTotal()) {
            return this.showPopup(
              "Vastaanotettujen vakuuksien summa ei voi ylittää tavoitesummaa tai alittaa palautettujen vakuuksien summaa. Poista tavoite tai korjaa vakuuksien summat.",
              "error"
            );
          }

          if (this.edit) {
            await this.editDeposit(this.deposit);
            eventBus.$emit("setShowResults", false);
          } else {
            await this.createDeposit(this.deposit);
            eventBus.$emit("setShowResults", false);
            // Reset
            this.deposit = _.cloneDeep(this.defaultDepositForm);
            this.$refs.form.resetValidation();
          }
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    resendMessage() {
      if (this.$refs.form.validate()) {
        if (this.receivedSmallerThanTarget()) {
          if (
            confirm(
              "Saadut suoritukset ovat pienemmät kuin tavoitesumma. Lähetetäänkö kuittaus silti?"
            )
          ) {
            this.resendDepositMessage(this.currentDeposit._id);
          }
        } else {
          if (confirm("Haluatko varmasti lähettää viestin uudelleen vastaanottajille?")) {
            this.resendDepositMessage(this.currentDeposit._id);
          }
        }
      }
    },

    receivedSmallerThanTarget() {
      const receivedSum = this.deposit.received.reduce((sum, item) => sum + item.amount, 0);
      return receivedSum < this.deposit.targetAmount;
    },

    calcRefundTotal(items) {
      let paidAmounts = 0;
      items.forEach((item) => {
        item.paidAmounts.forEach((el) => {
          paidAmounts += el.amount;
        });
      });

      return paidAmounts;
    },
  },
};
</script>

<style scoped>
.details-container {
  display: flex;
}

.text-label {
  font-weight: 500;
}
</style>
