<template>
  <v-dialog v-model="dialog" width="600" :persistent="loading">
    <!-- LOADING -->
    <v-card v-show="loading">
      <v-card-title>Allekirjoitusprosessi käynnissä. Odota hetki...</v-card-title>

      <v-card-text class="text-center">
        <v-progress-circular
          class="text-center mb-2"
          :value="100"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-text>
    </v-card>

    <!-- NOT LOADING -->
    <v-card v-show="!loading">
      <v-card-title
        >Visma Sign | Sähköinen allekirjoitus sop.num.
        {{ signatureItem.contractNumber }}</v-card-title
      >

      <!-- Not send -->
      <v-card-text v-if="!hasDocumentUuId" class="mt-2">
        <!-- Show signers before sending -->
        <show-signers-before-send
          ref="showSignerBeforeSend"
          :noEmailSigners="noEmailSigners"
          :allSigners="allSigners"
          :contractType="contractType"
        ></show-signers-before-send>

        <v-form ref="form">
          <v-menu
            ref="validUntil"
            v-model="validMenu"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(validUntilDate)"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                label="Allekirjoituskutsu voimassa"
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :readonly="true"
                hide-details
                :rules="validations.required"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="validUntilDate"
              :allowed-dates="allowedDates"
              first-day-of-week="1"
              @input="validMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-form>

        <h3 class="mb-1 mt-2">Lähettäjä</h3>
        <p>
          Allekirjoituskutsun lähettäjän nimi on
          <strong>{{ currentUser.currentAccount.name }}</strong>
        </p>

        <!-- Status -->
        <h3 class="mb-1 mt-2">Status</h3>

        <p style="margin-bottom: 25px" :class="signatureColorClass">
          {{ getSignatureState(signatureItem) }}
        </p>

        <v-btn class="mt-2" color="info" @click="sendSignature()"
          >Lähetä allekirjoitettavaksi</v-btn
        >
      </v-card-text>

      <!-- If sent -->
      <v-card-text v-if="hasDocumentUuId" class="mt-2">
        <h3 class="mb-1">Status</h3>

        <p v-if="!isReady">Ladataan allekirjoittajia...</p>

        <div v-else>
          <p class="mb-1" :class="signatureColorClass">
            {{ getSignatureState(signatureItem) }}
          </p>

          <div>
            <div v-for="signer in invitations" :key="signer.uuid">
              <span
                ><strong>{{ signer.name }} ({{ signer.email }})</strong>
              </span>
              <span> | {{ formatStatus(signer.status) }}</span>
            </div>
          </div>

          <div class="mt-2">
            <!-- cancel only if document is pending or unsent invites to be canceled-->
            <v-btn
              class="mb-2"
              v-if="
                signatureItem.vismaSign.status == 'pending' &&
                signatureItem.vismaSign.status != 'cancelled'
              "
              color="warning"
              outlined
              @click="cancelSigningProcess(signatureItem.vismaSign.documentUuId)"
            >
              Peruuta allekirjoitukset
            </v-btn>

            <!-- delete only if document must be cancelled or signed or have no invitations or signatures to be deleted. -->
            <v-btn
              v-if="
                signatureItem.vismaSign.status == 'signed' ||
                signatureItem.vismaSign.status == 'cancelled' ||
                invitations.length == 0
              "
              class="mb-2"
              color="error"
              outlined
              @click="deleteSigningProcess(signatureItem.vismaSign.documentUuId)"
            >
              Poista allekirjoitusprosessi
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <!-- Download contract -->
      <v-card-text
        v-if="signatureItem.vismaSign.status && signatureItem.vismaSign.status != 'cancelled'"
      >
        <v-btn color="primary mb-1" @click="download()">Lataa dokumentti</v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";
import { mapMutations, mapState } from "vuex";
import validations from "@/validations";
import signatureHelpers from "@/utils/signatureHelpers.js";
import ShowSignersBeforeSend from "./ShowSignersBeforeSend.vue";
import moment from "moment";

export default {
  mixins: [mixins],

  components: {
    ShowSignersBeforeSend,
  },

  props: {
    value: { type: Boolean, default: false },
    document: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      invitations: [],
      noEmailSigners: [],
      isReady: false,
      validUntilDate: null,
      validMenu: false,
      signatureHelpers,
      validations,
    };
  },

  computed: {
    ...mapState("signature", ["loading", "signatureItem", "contractType", "additionalSigners"]),
    ...mapState("account", ["currentUser"]),

    allSigners: {
      get() {
        return this.$store.state.signature.allSigners;
      },
      set(val) {
        this.setSignatureField({ field: "allSigners", val: val });
      },
    },

    hasDocumentUuId() {
      return this.signatureItem.vismaSign.documentUuId;
    },

    userIsAccountOwner() {
      return this.currentUser.ownerOfAccounts.includes(this.currentUser.currentAccount._id);
    },

    signatureColorClass() {
      return `${this.getSignatureColor(this.signatureItem)}--text`;
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(val) {
      if (val) this.setSignatureField({ field: "additionalSigners", val: [] });
    },
  },

  methods: {
    ...mapMutations("contract", ["replaceContract"]),
    ...mapMutations("othercontract", ["replaceOtherContract"]),
    ...mapMutations("signature", ["setSignatureItem", "setSignatureField", "removeFromAllSigners"]),
    ...mapMutations("pdfViewer", ["openViewer"]),

    startProcess() {
      this.selectSigners();
      this.getDocument();

      this.$nextTick(() => {
        this.setValidUntilDate();
      });
    },

    sendSignature() {
      if (!this.$refs.showSignerBeforeSend.validateAdditionalSigners()) {
        return this.showPopup("Tarkista allekirjoittajat", "error");
      }

      if (!this.$refs.form.validate()) {
        return this.showPopup("Tarkista muut pakolliset kentät", "error");
      }

      this.$emit("sendsignatureinvitation", {
        signers: {
          allSigners: this.allSigners,
          additionalSigners: this.additionalSigners,
        },
        validUntilDate: this.validUntilDate,
      });
    },

    checkStatuses(signers) {
      let boolean = false;
      signers.forEach((signer) => {
        if (signer.email == this.currentUser.currentAccount.email && this.userIsAccountOwner) {
          boolean = signer.status == "signed" ? false : true;
        }
      });
      return boolean;
    },

    formatStatus(status) {
      if (status == "waiting-for-send") return "Odottaa lähetystä allekirjoittajalle";
      if (status == "sending") return "Allekirjoituksia lähetetään...";
      if (status == "new") return "Odottaa allekirjoitusta";
      if (status == "opened")
        return "Allekirjoittaja on avannut allekirjoitusnäkymän, mutta ei ole vielä allekirjoittanut";
      if (status == "signed") return "Allekirjoitettu";
      if (status == "cancelled") return "Allekirjoitus peruttu";
    },

    selectSigners() {
      if (this.contractType == "rentalContract") {
        this.getRentalContractSigners();
      } else if (this.contractType == "otherContract") {
        this.getOtherContractSigners();
      }
    },

    getRentalContractSigners() {
      const { allSigners, noEmailSigners } = signatureHelpers.getRentalContractSigners(
        this.signatureItem,
        this.currentUser,
        this.userIsAccountOwner
      );

      this.setSignatureField({
        field: "allSigners",
        val: allSigners,
      });
      this.noEmailSigners = noEmailSigners;
    },

    getOtherContractSigners() {
      const { allSigners, noEmailSigners } = signatureHelpers.getOtherContractSigners(
        this.signatureItem
      );

      this.setSignatureField({
        field: "allSigners",
        val: allSigners,
      });
      this.noEmailSigners = noEmailSigners;
    },

    async getDocument() {
      this.isReady = false;

      if (this.hasDocumentUuId) {
        try {
          let res = await apiAgent.post(
            `/api/v1/signature/vismasign/document/${this.signatureItem.vismaSign.documentUuId}/contract/${this.signatureItem._id}`,
            { contractType: this.contractType }
          );

          if (res.data.contract) {
            if (this.contractType == "rentalContract") {
              this.replaceContract({ ...res.data.contract });
            } else if (this.contractType == "otherContract") {
              this.replaceOtherContract({ ...res.data.contract });
            }
            // set item to state
            this.setSignatureItem(res.data.contract);
          }

          this.invitations = res.data.document.invitations;

          this.isReady = true;
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },

    async download() {
      try {
        const res = await apiAgent({
          method: "GET",
          url: `/api/v1/signature/vismasign/document/${this.signatureItem.vismaSign.documentUuId}/file`,
          responseType: "arraybuffer",
        });
        let name;
        if (this.contractType == "rentalContract") {
          name = `Vuokrasopimus_${this.signatureItem.apartment.address} ${this.signatureItem.apartment.apartmentNumber}.pdf`;
        } else {
          name = `Sopimus_${this.signatureItem.contractNumber}_${this.signatureItem.name}.pdf`;
        }

        const blob = new Blob([res.data], { type: "application/pdf" });

        this.openViewer({
          data: blob,
          name,
        });
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async cancelSigningProcess(documentUuId) {
      if (confirm("Haluatko varmasti peruuttaa allekirjoituksen?")) {
        try {
          await apiAgent({
            method: "POST",
            url: `/api/v1/signature/vismasign/document/${documentUuId}/contract/${this.signatureItem._id}/cancel`,
            data: {
              contractType: this.contractType,
            },
          });

          await this.getDocument();

          this.showPopup("Allekirjoitusprosessi peruttu", "success");
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },

    async deleteSigningProcess(documentUuId) {
      if (confirm("Haluatko varmasti poistaa nykyisen allekirjoituksen?")) {
        try {
          const res = await apiAgent({
            method: "POST",
            url: `/api/v1/signature/vismasign/document/${documentUuId}/contract/${this.signatureItem._id}/delete`,
            data: {
              contractType: this.contractType,
            },
          });

          if (res.data.contract) {
            if (this.contractType == "rentalContract") {
              this.replaceContract({ ...res.data.contract });
            } else {
              this.replaceOtherContract({ ...res.data.contract });
            }
            // set to state
            this.setSignatureItem(res.data.contract);
          }

          this.dialog = false;
          this.showPopup("Allekirjoitusprosessi ja dokumentti poistettu", "success");
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },

    setValidUntilDate() {
      if (!this.hasDocumentUuId) this.$refs.form.reset();

      if (this.contractType == "rentalContract" && this.signatureItem?.lease?.startDate) {
        const startDate = moment(this.signatureItem.lease.startDate).format("YYYY-MM-DD");
        const twoDaysBeforeStart = moment(startDate).subtract(2, "days").format("YYYY-MM-DD");

        if (moment(twoDaysBeforeStart).isSameOrAfter(moment().format("YYYY-MM-DD"))) {
          this.validUntilDate = startDate;
        }
      } else {
        this.validUntilDate = moment().add(30, "days").format("YYYY-MM-DD");
      }
    },

    allowedDates(val) {
      const date = new Date(moment(val).format("YYYY-MM-DD")).getTime();
      const today = new Date(moment().add(2, "days").format("YYYY-MM-DD")).getTime();
      if (date < today) return false;
      else return true;
    },
  },
};
</script>

<style scoped>
.card-oldlist {
  height: 500px;
  overflow-y: scroll;
}
</style>
