<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Luo hyvityslasku</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()">Takaisin</v-btn>
    </div>

    <invoice-form
      :contr="contract"
      :inv="invoice"
      save-btn-text="Luo hyvityslaskuluonnos"
      @sendrefundinvoice="submit($event)"
    ></invoice-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import InvoiceForm from "../../components/Invoice/InvoiceForm";
import { mapMutations } from "vuex";

export default {
  title: "Luo hyvityslasku",

  components: {
    InvoiceForm,
  },

  mixins: [mixins],

  data() {
    return {
      invoice: {
        otherDueDates: {},
        products: [],
        sendType: "post",
        freeText:
          "Vuokranmaksupäivä on joka kuukauden viides päivä. Maksa vuokra aina samalla viitteellä.",
        outgoingPayments: { inUse: false },
      },
      contract: {},
    };
  },

  async created() {
    try {
      this.setLoading(true);

      let res = await axiosMethods.get(
        `/api/v1/invoices/${this.$route.params.id}?checkAccountingIds=true`
      );
      let invoice = res.data.invoice;

      this.contract = invoice.contract;

      let inv = {
        dueDate: null,
        billDate: null,
        accountDate: null,
        addAttachment: false,
        userCreatedAttachment: false,
        base64Pdf: {
          fileName: "",
          base64FileString: "",
        },
        pdfEditorContent: invoice.pdfEditorContent,
        freeText: "",
        sendType: invoice.sendType,
        products: invoice.products,
        tenant: invoice.tenant,
        sendToApartmentAddress: invoice.sendToApartmentAddress,
        sendingAddress: invoice.sendingAddress
          ? invoice.sendingAddress
          : { address: null, zipCode: null, city: null, country: null },
        invoiceNumber: invoice.invoiceNumber,
        refundInvoiceIds: invoice.refundInvoiceIds,
        evoice: invoice.evoice,
        totalAmount: invoice.totalAmount,
        openAmount: invoice.openAmount,
        outgoingPayments: invoice.outgoingPayments,
        yourCode: invoice.yourCode,
        language: invoice.language ? invoice.language : "fin",
        attachedDeposits: invoice.attachedDeposits,
        contract: invoice.contract,
        costCentre: invoice.costCentre,
      };

      this.invoice = inv;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("invoice", ["setLoading", "setSaving"]),

    async submit(data) {
      try {
        await axiosMethods.post(
          `/api/v1/invoices/refund-related/create/${this.$route.params.id}`,
          data
        );

        this.setSaving(false);
        this.showPopup("Uusi hyvityslasku luotu", "success");
        this.$router.push("/refund-invoices?tab=1");
      } catch (err) {
        this.setSaving(false);
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
