<template>
  <v-container>
    <v-row
      ><v-col cols="12">
        <!-- Loader -->
        <full-page-loader
          v-if="loading"
          text="Hetkinen, uudelleenohjataan oikeaan kohteeseen..."
          class="full-page-loader"
        ></full-page-loader> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import FullPageLoader from "./FullPageLoader";
import mixins from "@/mixins/mixins";
import { mapActions } from "vuex";

export default {
  mixins: [mixins],

  data() {
    return {
      loading: true,
    };
  },

  components: {
    FullPageLoader,
  },

  async created() {
    try {
      const path = await this.getLink(this.$route.params.id);
      this.$router.push(path).catch(() => {});
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapActions("shortLink", ["getLink"]),
  },
};
</script>

<style></style>
