<template>
  <v-dialog v-model="dialog" width="500" :persistent="loading">
    <v-card>
      <v-card-title> Luo ohisuoritus (laskun nro. {{ currentInvoice.invoiceNumber }})</v-card-title>
      <v-card-subtitle style="margin-top: 0px"
        >Voit luoda ohisuorituksen Ropo Capitaliin, jos suoritus on tehty suoraan tilillesi Ropo
        Capitalin sijasta.
      </v-card-subtitle>

      <v-card-text class="mt-2">
        <p class="error--text mb-1" style="margin-bottom: 5px">
          <strong
            >Voit tehdä maksimissaan {{ formatCurrency(maxPaymentAmount) }} ohisuorituksen</strong
          >
        </p>
        <v-form ref="form">
          <v-text-field
            label="Summa"
            v-model.number="payment.amount"
            step="0.01"
            type="number"
            suffix="€"
            :rules="required.concat(maxAmount).concat(positiveNumber)"
            outlined
            dense
          ></v-text-field>

          <v-menu
            ref="payDateMenu"
            v-model="payDateMenu"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(payment.payDate)"
                v-bind="attrs"
                :rules="required"
                outlined
                dense
                v-on="on"
                append-icon="mdi-calendar"
                label="Suorituksen pvm."
                v-prevent-manual-input
                :readonly="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="payment.payDate"
              :allowed-dates="allowedPayDates"
              first-day-of-week="1"
              @input="payDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false" :disabled="loading">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="submitPayment()" :loading="loading">Luo ohisuoritus</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import invoiceHelpers from "@/utils/invoiceHelpers";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data(vm) {
    return {
      loading: false,
      maxPaymentAmount: 0,
      payDateMenu: false,
      dateFormattedPayDate: "",
      payment: {
        amount: null,
        payDate: "",
      },
      maxAmount: [
        (v) => vm.testMaxAmount(v) || `Maksimisumma on ${this.formatCurrency(vm.maxPaymentAmount)}`,
      ],
      positiveNumber: [(v) => v > 0 || "Anna positiivinen luku"],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("invoice", ["currentInvoice"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.$refs.form.reset();
        this.$emit("input", value);
      },
    },
  },

  watch: {
    currentInvoice: {
      deep: true,
      handler(item) {
        this.calcTotalPaymentAmount(item);
      },
    },
  },

  methods: {
    ...mapActions("invoice", ["makePayment"]),

    async submitPayment() {
      if (this.loading) return;

      if (this.$refs.form.validate()) {
        if (
          confirm("Tarkistihan, että summa vastaa saatua suoritusta ja haluat varmasti jatkaa?")
        ) {
          try {
            this.loading = true;
            await this.$nextTick();
            await this.makePayment(this.payment);
            this.loading = false;
            this.dialog = false;
            this.showPopup("Ohi suoritus luotu", "success");
          } catch (err) {
            this.loading = false;
            this.showPopup(err, "error");
          }
        }
      }
    },

    testMaxAmount(val) {
      return val > this.maxPaymentAmount ? false : true;
    },

    calcTotalPaymentAmount(invoice) {
      const totalAmount = invoice.totalAmount;
      const openAmount = invoice.openAmount || 0;
      if (openAmount === 0) {
        this.maxPaymentAmount = 0;
        return;
      }
      const productsAmount = invoiceHelpers.calcProductsTotalAmount(invoice);

      // Calc interests
      const interests = totalAmount - productsAmount;
      // Calculate max payment sum
      this.maxPaymentAmount = parseFloat((openAmount - interests).toFixed(2));
    },

    allowedPayDates(val) {
      const today = new Date(moment(new Date()).format("YYYY-MM-DD"));

      const show = new Date(val).getTime() <= today ? true : false;
      return show;
    },
  },
};
</script>

<style scoped></style>
