<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="isAuthorized('outgoingPayment', 'edit')"
        color="primary"
        class="mb-2 mr-1"
        @click="uploadFileDialog = true"
        >Tarkista maksut</v-btn
      >
      <v-btn
        v-if="isAuthorized('outgoingPayment', 'read')"
        color="primary"
        class="mr-1 mb-2 ml-auto"
        @click="rentalIncomeDialog = true"
        >Lataa raportti</v-btn
      >
    </v-card-title>

    <v-container>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Maksun nro, vuokrakohde, toimeksiantaja, viite..."
            single-line
            hide-details
            solo
            @keyup.enter="getDataFromApi()"
            @click:append="getDataFromApi()"
          ></v-text-field>
        </v-col>
      </v-row>
      <div style="max-width: 360px">
        <v-checkbox
          v-model="showPaidPayments"
          dense
          label="Näytä maksetut"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="showUpdatedPayments"
          dense
          label="Näytä tarkistetut"
          hide-details
        ></v-checkbox>
      </div>
    </v-container>

    <v-data-table
      :headers="filteredHeaders"
      :items="payments"
      :footer-props="globalValues.footerProps"
      :items-per-page="getDefaultTableRows()"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalLength"
    >
      <!-- Last check-->
      <template #[`item.lastChecked`]="{ item }">
        <span>{{ formatDateAndTime(item.lastChecked) }}</span>
      </template>

      <!-- Target month-->
      <template #[`item.targetMonth`]="{ item }">
        <span>{{ formatDateMonthName(item.targetMonth) }}</span>
      </template>

      <!-- Payment number-->
      <template #[`item.paymentNumber`]="{ item }">
        <span
          ><strong># {{ item.paymentNumber }}</strong>
        </span>
      </template>

      <!-- Apartment -->
      <template #[`item.apartment`]="{ item }">
        <router-link
          style="text-decoration: none"
          :to="`/apartment/overview/${item.apartment._id}`"
        >
          <span v-if="item.apartment">
            {{ item.apartment.address
            }}<span v-if="item.apartment.apartmentNumber"
              >, {{ item.apartment.apartmentNumber }}</span
            >
          </span>
        </router-link>
      </template>

      <!-- Owners -->
      <template #[`item.apartment.owners`]="{ item }">
        <span class="error--text" v-if="item.apartment.owners.length == 0">Ei omistajia</span>
        <div v-else v-for="(owner, idx) in item.apartment.owners" :key="'a' + idx">
          <router-link
            v-if="owner.onModel == 'Partner'"
            style="text-decoration: none"
            :to="`/partner/overview/${owner.ownerId._id}`"
          >
            <span>{{ owner.name }}</span>
          </router-link>
          <span v-else>{{ owner.name }}</span>
        </div>
      </template>

      <!-- Invoices-->
      <template #[`item.invoices`]="{ item }">
        <span v-for="(invoice, idx) in item.invoices" :key="'b' + idx">
          <span>
            <v-tooltip right max-width="400">
              <template #activator="{ on }">
                <span v-on="on" class="info--text" style="cursor: pointer">{{
                  invoice.invoiceNumber
                }}</span>
              </template>
              <span
                ><strong>Lasku {{ invoice.invoiceNumber }}</strong> <br />
                Eräpäivä: {{ formatDate(invoice.dueDate) }} <br />
                Saatavat: {{ formatCurrency(invoice.totalAmount) }} <br />
                Avoinna: {{ formatCurrency(invoice.openAmount) }} <br />
                <strong>Suoritukset:</strong> <br />
                <div v-for="(payment, idx) in invoice.paidAmounts" :key="'g' + idx">
                  <small>{{ formatDate(payment.date) }}</small> <br />
                  <p style="margin-bottom: 0px; margin-top: -2px">
                    Summa: {{ formatCurrency(payment.amount) }}
                  </p>
                </div>
              </span>
            </v-tooltip></span
          >
          <span v-if="idx < item.invoices.length - 1"> | </span>
        </span>
        <div v-if="item.invoices.length > 0" class="success--text font-weight-bold">
          {{ formatCurrency(item.invoiceAmount) }}
        </div>
      </template>

      <!-- Refund Invoices-->
      <template #[`item.refundInvoices`]="{ item }">
        <div v-for="(invoice, idx) in item.refundInvoices" :key="'c' + idx">
          <span>{{ invoice.invoiceNumber }}</span>
          <span v-if="idx < item.refundInvoices.length - 1"> | </span>
        </div>
        <div v-if="item.refundInvoices.length > 0" class="error--text font-weight-bold">
          {{ formatCurrency(item.refundsAmount) }}
        </div>
      </template>

      <!-- Joined to-->
      <template #[`item.joinedTo`]="{ item }">
        <v-tooltip v-if="item.joinedTo" top max-width="400">
          <template #activator="{ on, attrs }">
            <div class="primary--text" style="cursor: pointer" v-bind="attrs" v-on="on">
              <span> # {{ item.joinedTo.paymentNumber }} </span>
            </div>
          </template>

          <h4 class="mb-1">Kohdistetut maksut</h4>

          <p style="margin-bottom: 6px; font-size: 12px">
            {{ formatDateMonthName(item.joinedTo.targetMonth) }}
          </p>
          <p>Maksu: # {{ item.joinedTo.paymentNumber }}</p>
        </v-tooltip>
        <span v-else></span>
      </template>

      <!-- Total receipts amount-->
      <template #[`item.receiptsAmount`]="{ item }">
        <v-tooltip v-if="item.receipts.length > 0" top max-width="350">
          <template #activator="{ on, attrs }">
            <div class="primary--text" style="cursor: pointer" v-bind="attrs" v-on="on">
              <span v-if="!includesInActiveReceipts(item)">Erittely</span>
              <span v-else>
                <v-icon color="warning" small>mdi-alert-circle</v-icon>
              </span>
            </div>
          </template>

          <h4 class="mb-1">Tuotteet</h4>
          <div v-for="(receipt, index) in item.receipts" :key="index + 'e'">
            <strong>{{ receipt.name }}</strong>
            <div v-for="(prod, index) in receipt.products" :key="index + 'f'">
              <span :class="{ 'joined-payment': !receipt.active }"
                >{{ prod.name }}: {{ formatCurrency(prod.count * prod.amount) }}</span
              >
            </div>
          </div>
        </v-tooltip>

        <span v-if="item.receipts.length > 0" class="error--text font-weight-bold">{{
          formatCurrency(item.receiptsAmount)
        }}</span>
      </template>

      <template #[`item.payments`]="{ item }">
        <span
          :style="{
            'text-decoration': item.joinedTo && item.paymentType == 'payment' ? 'line-through' : '',
          }"
        >
          {{ formatCurrency(calcTotalPayments(item)) }}
        </span>
        <span
          v-if="item.payments.length > 0 || item.joinedPayments.length > 0"
          style="cursor: pointer"
          @click="
            $store.state.outgoingpayment.currentPayment = { ...item };
            dialogPayments = true;
          "
          ><v-icon small>mdi-comment-edit-outline</v-icon>
        </span>
      </template>

      <!-- Total open amount -->
      <template #[`item.openAmount`]="{ item }">
        <div
          :class="
            calcOpenAmount(item) > 0 && item.paymentType == 'payment'
              ? 'success--text'
              : calcOpenAmount(item) < 0 && item.paymentType == 'payment'
              ? 'error--text'
              : calcOpenAmount(item) == 0
              ? 'success--text'
              : 'error--text'
          "
          class="font-weight-bold"
        >
          <div
            :style="{
              'text-decoration':
                item.joinedTo && item.paymentType == 'payment' ? 'line-through' : '',
            }"
          >
            <span>{{ getPaymentType(item) }}</span>
            {{ formatCurrency(calcOpenAmount(item)) }}
          </div>
        </div>
      </template>

      <!-- Payment Reference-->
      <template #[`item.paymentReference`]="{ item }">
        <span v-if="item.paymentReference">{{ item.paymentReference }}</span>
      </template>

      <!-- Actions -->
      <template #[`item.actions`]="{ item }">
        <div class="action-grid-container">
          <v-icon
            v-if="isAuthorized('outgoingPayment', 'edit') && tab == 0"
            title="Muokkaa"
            small
            @click="
              $store.state.outgoingpayment.currentPayment = { ...item };
              editPaymentDialog = true;
            "
            >mdi-pencil</v-icon
          >
          <v-icon
            v-if="isAuthorized('outgoingPayment', 'create')"
            title="Tee ohisuoritus (maksu)"
            small
            @click="
              $store.state.outgoingpayment.currentPayment = { ...item };
              makePaymentDialog = true;
            "
            >mdi-cash-multiple</v-icon
          >
          <v-icon
            v-if="isAuthorized('outgoingPayment', 'delete')"
            title="Poista"
            small
            @click="
              selected = { ...item };
              deleteItem(item);
            "
            >mdi-delete</v-icon
          >
        </div>
      </template>

      <template #no-data>
        <h2>Ei lähteviä maksuja</h2>
      </template>
    </v-data-table>

    <!-- Delete payment -->
    <v-dialog v-model="deleteDialog" width="400">
      <v-card>
        <v-card-title>Poista lähtevä maksu # {{ selected.paymentNumber }} </v-card-title>
        <v-card-text>
          <p>Maksu sisältää kuitteja. Mihin tilaan kuitit asetetaan maksun poistamisen jälkeen?</p>
        </v-card-text>

        <v-card-text>
          <v-select
            v-model="subtractReceiptFromOutgoing"
            label="Kuittien tila"
            :items="[
              { text: 'Lähtevät maksut käytössä', val: true },
              { text: 'Lähtevät maksut pois päältä', val: false },
            ]"
            item-text="text"
            item-value="val"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="deleteDialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="
              deletePayment({ paymentId: selected._id, subtractReceiptFromOutgoing });
              deleteDialog = false;
            "
            >Poista maksu</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Payments dialog  -->
    <all-payments v-model="dialogPayments"></all-payments>
    <!-- File upload -->
    <upload-csv-file v-model="uploadFileDialog"></upload-csv-file>
    <!-- Edit Payment -->
    <edit-payment v-model="editPaymentDialog"></edit-payment>
    <!-- Make Payment -->
    <make-payment v-model="makePaymentDialog"></make-payment>
    <!-- Income and settlement dialog -->
    <rental-income-report-dialog v-model="rentalIncomeDialog"></rental-income-report-dialog>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import UploadCsvFile from "../../components/OutgoingPayments/UploadCsvDialog.vue";
import EditPayment from "../../components/OutgoingPayments/EditPayment.vue";
import AllPayments from "../../components/OutgoingPayments/AllPaymentsDialog.vue";
import MakePayment from "../../components/OutgoingPayments/MakePayment.vue";
import globalValues from "@/configs/globalValues";
import { mapActions, mapState, mapMutations } from "vuex";
import RentalIncomeReportDialog from "./RentalIncomeReportDialog.vue";

export default {
  title: "Lähtevät maksut",

  props: ["tab"],

  components: {
    UploadCsvFile,
    EditPayment,
    AllPayments,
    MakePayment,
    RentalIncomeReportDialog,
  },

  mixins: [mixins],

  data: () => ({
    selected: { payments: [], joinedPayments: [] },
    showPaidPayments: false,
    showUpdatedPayments: false,
    uploadFileDialog: false,
    editPaymentDialog: false,
    makePaymentDialog: false,
    dialogPayments: false,
    deleteDialog: false,
    subtractReceiptFromOutgoing: true,
    loading: true,
    options: {},
    dialog: false,
    rentalIncomeDialog: false,
    search: "",
    headers: [
      { text: "Tarkistettu", value: "lastChecked", show: [0, 1] },
      { text: "Maksu nro.", value: "paymentNumber", show: [0, 1] },
      { text: "Kohde kk", value: "targetMonth", show: [0, 1] },
      { text: "Vuokrakohde", value: "apartment", show: [0, 1] },
      { text: "Toimeksiantaja", value: "apartment.owners", show: [0, 1] },
      { text: "Myyntilaskut", value: "invoices", show: [0] },
      { text: "Hyvityslaskut", value: "refundInvoices", show: [0] },
      { text: "Kulut", value: "receiptsAmount", show: [0, 1] },
      { text: "Kohdistettu", value: "joinedTo", show: [0] },
      { text: "Suoritukset", value: "payments", show: [0, 1] },
      { text: "Avoinna", value: "openAmount", show: [0, 1] },
      { text: "Viite", value: "paymentReference", show: [0, 1] },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [0, 1],
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("outgoingpayment", ["payments", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    filteredHeaders() {
      let headers;

      if (!this.showPaidPayments && !this.showUpdatedPayments) {
        if (this.tab == 0) {
          headers = this.headers.filter(
            (el) => el.text != "Tarkistettu viim." && el.show.includes(0)
          );
        } else if (this.tab == 1) {
          headers = this.headers.filter(
            (el) => el.text != "Tarkistettu viim." && el.show.includes(1)
          );
        }
      } else {
        if (this.tab == 0) {
          headers = this.headers;
        } else if (this.tab == 1) {
          headers = this.headers.filter(
            (el) => el.text != "Tarkistettu viim." && el.show.includes(1)
          );
        }
      }

      return headers;
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    tab() {
      this.getDataFromApi();
    },

    editPaymentDialog(val) {
      if (val) this.$store.state.outgoingpayment.activePayments = [];
    },

    totalLength() {
      this.options.page = 1;
    },

    showPaidPayments(val) {
      if (val) {
        this.showUpdatedPayments = false;
      }
      this.getDataFromApi();
    },

    showUpdatedPayments(val) {
      if (val) {
        this.showPaidPayments = false;
      }
      this.getDataFromApi();
    },
  },

  methods: {
    ...mapActions("outgoingpayment", ["getAllPayments", "deletePayment"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      let url;
      const paymentType = this.tab == 0 ? "payment" : "receipt";

      if (this.showPaidPayments) {
        url = `/api/v1/outgoing-payment/get-all?paymentType=${paymentType}&status=paid&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      } else if (this.showUpdatedPayments) {
        url = `/api/v1/outgoing-payment/get-all?paymentType=${paymentType}&status=updated&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      } else {
        url = `/api/v1/outgoing-payment/get-all?paymentType=${paymentType}&status=created&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      }

      await this.getAllPayments(url);

      this.loading = false;
    },

    editItem(item) {
      this.$router.push("/outgoing-payment/edit/" + item._id);
    },

    deleteItem(item) {
      // Open dialog and ask subtract style
      if (item.receipts.length > 0) {
        this.deleteDialog = true;
        return;
      } else {
        // Delete right away with confirm
        if (confirm("Haluatko varmasti poistaa lähtevän maksun: " + item.paymentNumber))
          this.deletePayment({ paymentId: item._id, subtractReceiptFromOutgoing: null });
      }
    },

    getPaymentType(item) {
      if (item.paymentType == "payment") {
        return this.calcOpenAmount(item) < 0
          ? "Laskutetaan"
          : this.calcOpenAmount(item) > 0
          ? "Maksetaan"
          : "";
      } else {
        return "";
      }
    },

    includesInActiveReceipts(item) {
      return item.receipts.filter((el) => el.active == false).length > 0;
    },

    getAttachedPayments(item) {
      const joinedPayments = item.joinedPayments;
      const otherPayments = item.payments.filter((item) => item.otherPayment);
      return [...joinedPayments, ...otherPayments];
    },

    getOtherPayments(payments) {
      return payments.filter((item) => item.otherPayment);
    },

    getStatementPayments(payments) {
      return payments.filter((item) => !item.otherPayment);
    },

    calcTotalPayments(item) {
      let total = 0;

      item.payments.forEach((el) => {
        total += el.amount;
      });

      item.joinedPayments.forEach((el) => {
        total += el.openAmount;
      });

      return total;
    },

    calcOpenAmount(item) {
      return item.openAmount;
    },

    calcAttachedTotal(item) {
      let total = 0;
      item.payments.forEach((el) => {
        if (el.otherPayment) {
          total += el.amount;
        }
      });

      item.joinedPayments.forEach((el) => {
        total += el.openAmount;
      });

      return total;
    },
  },
};
</script>

<style scoped>
.joined-payment {
  text-decoration: line-through;
}
</style>
