<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Uusi toimeksiantaja</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <partner-form submit-btn-text="Luo" @submit="submit"></partner-form>
  </div>
</template>

<script>
import PartnerForm from "../../components/Partner/PartnerForm";
import mixins from "../../mixins/mixins";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  title: "Uusi toimeksiantaja",

  components: {
    PartnerForm,
  },

  mixins: [mixins],

  created() {
    this.setDefaultPartner();
  },

  computed: {
    ...mapState("partner", ["currentPartner"]),
  },

  methods: {
    ...mapActions("partner", ["createPartner"]),
    ...mapMutations("partner", ["setDefaultPartner"]),

    async submit() {
      try {
        await this.createPartner(this.currentPartner);
        this.showPopup("Uusi toimeksiantaja luotu", "success");
        this.$router.push("/partners");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
