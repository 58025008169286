<template>
  <div class="py-3 min-w-0 w-full">
    <v-card flat>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <small>
              Laskut haetaan sopimusten mukaan, joissa vuokralainen on ollut, ei sen mukaan, kuka on
              laskun vastaanottaja.
            </small>
          </v-col>
        </v-row>

        <!-- Search by dates -->
        <search-by-dates></search-by-dates>

        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              :label="getInvoiceSearchLabelByService('invoice')"
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-col md="6"> </v-col>
        </v-row>
        <div style="max-width: 360px">
          <v-checkbox
            v-show="hideAdditionalSearchBtns"
            v-model="showPaidInvoices"
            label="Näytä maksetut laskut"
            dense
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-if="isAuthorized('invoice', 'showInactiveBaseInvoices') && hideAdditionalSearchBtns"
            v-model="showInactiveBaseInvoices"
            label="Näytä korvatut toistuvaislaskut"
            dense
            class="mr-5"
            hide-details
          ></v-checkbox>

          <!-- <v-checkbox
            v-model="showActiveRecurrent"
            label="Näytä vain aktiiviset toistuvaislaskut"
            dense
            hide-details
          ></v-checkbox> -->
        </div>
        <v-row dense class="mt-1">
          <v-col cols="6" md="4">
            <v-btn class="mr-5 mb-2" color="info" @click.prevent="getDataFromApi()">Etsi</v-btn>
            <v-btn class="mb-2" color="error" outlined @click="resetForms()">Tyhjennä</v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="invoices"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :item-class="appendStyle"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- InvoiceNumber -->
        <template #[`item.invoiceNumber`]="{ item }">
          <p :class="getInvoiceStateClass(getInvoiceState(item))" style="font-size: 12px">
            {{ getInvoiceState(item) }}
          </p>
          <span v-if="item.invoiceNumber"
            ><strong># {{ item.invoiceNumber }}</strong>
          </span>
          <span v-else><strong class="draft--text">Luonnos</strong></span>
        </template>

        <!-- billDate -->
        <template #[`item.billDate`]="{ item }">
          <span>{{ formatDate(item.billDate) }}</span>
        </template>

        <!--  dueDate -->
        <template #[`item.dueDate`]="{ item }">
          <span>{{ formatDate(item.dueDate) }}</span>
        </template>

        <!-- Next send date -->
        <template #[`item.paymentTerm`]="{ item }">
          <span v-if="item.isRecurrent && item.nextDueDate">
            {{ formatDate(invoiceHelpers.calcNextRecurrentBillDate(item)) }}
          </span>
        </template>

        <!-- Ropo24 nextDueDate -->
        <template #[`item.nextDueDate`]="{ item }">
          <span v-if="item.nextDueDate" class="font-weight-bold success--text">
            {{ formatDate(item.nextDueDate) }}
          </span>
        </template>

        <!-- Tenant -->
        <template #[`item.tenant`]="{ item }">
          <span v-if="invoiceTenant(item).isReceiver">{{ item.tenant.name }}</span>
          <span v-else
            >{{ item.tenant.name }} ({{ invoiceTenant(item).tenant.tenantId.name }})</span
          >
        </template>

        <!-- Apartment Address -->
        <template #[`item.contract`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.contract.apartment.id._id}`"
          >
            <span>{{ item.contract.apartment.id.address }}</span>
            <span v-if="item.contract.apartment.id.apartmentNumber"
              >, {{ item.contract.apartment.id.apartmentNumber }}
            </span>
          </router-link>

          <div v-for="(room, idx) in getRentedRooms(item.contract.apartment)" :key="idx + 'a'">
            <p class="draft--text" style="margin-bottom: -2px !important">{{ room.name }}</p>
          </div>
        </template>

        <!-- Products / show on activeRecurrent  -->
        <template #[`item.products`]="{ item }">
          <div v-html="formatProducts(item)"></div>
        </template>

        <!-- Comments -->
        <template #[`item.comments`]="{ item }">
          <span class="mr-1">{{ formatCommentsInTableRow(item.comments) }}</span>
          <span
            v-if="item.comments.length > 0"
            style="cursor: pointer"
            @click="
              selected = item;
              dialogComments = true;
            "
            ><v-icon small>mdi-comment-edit-outline </v-icon></span
          >
        </template>

        <!-- SendTypes -->
        <template #[`item.sendType`]="{ item }">
          <p class="warning--text" v-if="item.sendStatus == 'pending'">Odottaa lähetystä</p>
          <span v-if="!showActiveRecurrent"
            ><v-icon small>{{ getSendIcon(item.sendType) }}</v-icon>
          </span>
          <span v-else
            ><v-icon small>{{ getSendIcon(item.autoSendType) }}</v-icon></span
          >
        </template>

        <!-- Total amount -->
        <template #[`item.totalAmount`]="{ item }">
          <product-list :item="item"></product-list>
        </template>

        <!-- Outgoing payments -->
        <template #[`item.outgoingPayments`]="{ item }">
          <span v-if="item.outgoingPayments.inUse" class="font-weight-bold success--text"
            >Käytössä</span
          >
          <span v-else class="font-weight-bold error--text">Ei käytössä</span>
        </template>

        <!-- Open amount -->
        <template #[`item.openAmount`]="{ item }">
          <open-amount
            :item="item"
            @openpaidamountdialog="openPaidAmountDialog(item)"
          ></open-amount>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="
                isAuthorized('invoice', 'create') &&
                hasServiceId(item) &&
                !showActiveRecurrent &&
                invoiceHelpers.hasOpenEquity(item)
              "
              :class="{ refundSend: refundSend(item.refundInvoiceIds) }"
              class="grid-item"
              title="Luo hyvityslasku"
              small
              @click="createRefundInvoice(item)"
            >
              mdi-cash-refund</v-icon
            >

            <v-icon
              v-if="isAuthorized('invoice', 'read') && hasServiceId(item) && !showActiveRecurrent"
              title="Lataa pdf"
              small
              @click="
                downloadItem = { ...item };
                downloadPdfDialog = true;
              "
              >mdi-file-pdf</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('invoice', 'edit') &&
                !item.active &&
                !item.overwritten &&
                !hasServiceId(item)
              "
              class="grid-item"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('invoice', 'read') &&
                !item.active &&
                !item.overwritten &&
                !hasServiceId(item) &&
                canDownloadPreviewInvoice(item)
              "
              class="grid-item"
              title="Esikatsele"
              small
              @click="
                downloadItem = { ...item };
                downloadPdf(true, null);
              "
              >mdi-magnify</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('invoice', 'delete') &&
                !item.active &&
                !item.overwritten &&
                !hasServiceId(item)
              "
              class="grid-item"
              title="Poista"
              small
              @click="deleteItem(item)"
            >
              mdi-delete</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei laskuja</h2>
        </template>
      </v-data-table>

      <!-- Dialog comments -->
      <comments-dialog v-model="dialogComments" :invoice="selected"></comments-dialog>

      <!-- Dialog PaidAmounts -->
      <paid-amounts v-model="dialogPaidAmounts" :invoice="selected"></paid-amounts>

      <!-- Dialog download pdf -->
      <download-invoice-dialog
        v-model="downloadPdfDialog"
        :invoiceNumber="downloadItem.invoiceNumber"
        @downloadpdf="downloadPdf(false, $event)"
      ></download-invoice-dialog>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import SearchByDates from "../../components/Invoice/SearchByDates.vue";
import PaidAmounts from "@/components/Invoice/PaidAmountsDialog";
import CommentsDialog from "@/components/Invoice/CommentsDialog";
import { mapState, mapActions, mapMutations } from "vuex";
import invoiceHelpers from "@/utils/invoiceHelpers";
import globalValues from "@/configs/globalValues";
import DownloadInvoiceDialog from "../../components/Invoice/DownloadInvoiceDialog.vue";
import OpenAmount from "@/components/Invoice/TableComponents/OpenAmount";
import ProductList from "@/components/Invoice/TableComponents/ProductList";

export default {
  title: "Laskut",
  mixins: [mixins, invoiceMixins],

  components: {
    SearchByDates,
    PaidAmounts,
    CommentsDialog,
    DownloadInvoiceDialog,
    OpenAmount,
    ProductList,
  },

  data: () => ({
    loading: true,
    options: {},
    page: 1,
    downloadPdfDialog: false,
    downloadItem: {},
    downloadStatusCode: 0,
    dialogComments: false,
    dialogPaidAmounts: false,
    dialogErrors: false,
    invoiceItem: {},
    selected: { comments: [], sendingErrors: [], paidAmounts: [] },
    search: "",
    hideAdditionalSearchBtns: true,
    allHeaders: [
      {
        text: "Lasku",
        value: "invoiceNumber",
        show: [0, 2, 6],
      },
      {
        text: "Laskun pvm.",
        value: "billDate",
        show: [0, 1, 2, 5, 6],
      },
      {
        text: "Eräpäivä",
        value: "dueDate",
        show: [0, 1, 2, 5, 6],
      },
      {
        text: "Sopimus",
        value: "contract.contractNumber",
        show: [0, 1, 2, 6],
        sortable: false,
      },
      {
        text: "Saaja",
        value: "tenant",
        show: [0, 1, 6],
      },
      {
        text: "Kohde",
        value: "contract",
        show: [0, 1, 2, 6],
      },
      {
        text: "Tuotteet",
        value: "productDrafts",
        show: [1, 4, 6],
      },
      {
        text: "Tuotteet",
        value: "products",
        show: [0, 4],
      },
      {
        text: "Saatavat",
        value: "totalAmount",
        show: [0, 1, 2, 5, 6],
      },
      {
        text: "Seuraava lähetyspäivä",
        value: "paymentTerm",
        show: [0, 1, 4],
      },
      {
        text: "Seuraava eräpäivä",
        value: "nextDueDate",
        show: [0, 1, 4],
      },
      {
        text: "Status",
        value: "comments",
        show: [0, 5],
      },
      {
        text: "Lähtevät maksut",
        value: "outgoingPayments",
        show: [7],
      },
      {
        text: "Avoinna",
        value: "openAmount",
        show: [0, 5],
      },
      {
        text: "Toimitus",
        value: "sendType",
        show: [0, 1],
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [0, 1],
      },
    ],
    showInactiveBaseInvoices: false,
    showPaidInvoices: false,
    showActiveRecurrent: false,
    invoiceHelpers,
    globalValues,
  }),

  computed: {
    ...mapState("invoice", ["totalLength", "invoices", "measurementsNeeded", "searchByDates"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("account", ["currentUser"]),

    headers() {
      return this.allHeaders.filter((el) => {
        if (this.showActiveRecurrent) {
          return (
            (el.show.includes(0) && !el.show.includes(5)) ||
            (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
          );
        } else {
          return (
            (el.show.includes(0) && !el.show.includes(4)) ||
            (el.show.includes(7) && this.currentUser.currentAccount.settings.outgoingPayments)
          );
        }
      });
    },
  },

  created() {
    this.resetSearchByDates("dueDate");
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;

          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    search(val) {
      if (val) {
        this.hideAdditionalSearchBtns = false;
      } else {
        this.hideAdditionalSearchBtns = true;
      }
    },

    showInactiveBaseInvoices() {
      if (this.showInactiveBaseInvoices) {
        this.showActiveRecurrent = false;
      }
      this.getDataFromApi();
    },

    showPaidInvoices() {
      if (this.showPaidInvoices) {
        this.showActiveRecurrent = false;
      }
      this.getDataFromApi();
    },

    showActiveRecurrent() {
      if (this.showActiveRecurrent) {
        this.showPaidInvoices = false;
        this.showInactiveBaseInvoices = false;
      }
      this.getDataFromApi();
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("invoice", ["getTenantInvoices", "deleteInvoice"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("invoice", ["resetSearchByDates"]),
    ...mapMutations("pdfViewer", ["openViewer"]),

    openPaidAmountDialog(invoice) {
      this.selected = invoice;
      this.dialogPaidAmounts = true;
    },

    async getDataFromApi() {
      this.loading = true;

      if (this.search) {
        this.showPaidInvoices = false;
        this.showInactiveBaseInvoices = false;
      }

      // Save main search term
      this.saveMainSearchTerm(this.search);

      const url = this.getInvoiceUrl();
      await this.getTenantInvoices({ url });

      this.loading = false;
    },

    getInvoiceUrl() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const search = this.search.trim().toLowerCase();
      const start = this.searchByDates.startDate;
      const end = this.searchByDates.endDate;
      const searchBy = this.searchByDates.searchBy;
      const additionalQuery = `&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}&startDate=${start}&endDate=${end}&searchBy=${searchBy}`;
      let url;

      if (this.showActiveRecurrent) {
        url = `/api/v1/invoices/tenant-invoices/${this.$route.params.id}?active=true&invoiceType=base&isRecurrent=true&nextDueDate=exists`;
      } else {
        // If search, search all from all sales invoices
        if (search) {
          url = `/api/v1/invoices/tenant-invoices/${this.$route.params.id}?`;
        } else if (this.showInactiveBaseInvoices) {
          url = this.showPaidInvoices
            ? `/api/v1/invoices/tenant-invoices/${this.$route.params.id}?overwritten=true&paid=true&invoiceType=base`
            : `/api/v1/invoices/tenant-invoices/${this.$route.params.id}?overwritten=true&invoiceType=base`;
        } else {
          url = this.showPaidInvoices
            ? `/api/v1/invoices/tenant-invoices/${this.$route.params.id}?paid=true`
            : // // Starting point where page loads
              `/api/v1/invoices/tenant-invoices/${this.$route.params.id}?paid=false&overwritten=false`;
        }
      }

      return url + additionalQuery;
    },

    formatProducts(item) {
      const PRODUCT_VAL = item.isUpdated ? "updatedProducts" : "products";

      let productsString = "";

      item[PRODUCT_VAL].forEach((el) => {
        if (item.nextDueDate) {
          productsString += `<div>${this.replaceProductTags(
            el.desc,
            item.nextDueDate,
            this.getNextAccountDate(item),
            item.language
          )} ${this.formatCurrency(el.count * (el.amount * (1 + el.taxpr / 100)))}</div>`;
        }
      });

      return productsString;
    },

    createRefundInvoice(item) {
      this.$router.push(`/invoice/refund/${item._id}`).catch(() => {});
    },

    async downloadPdf(preview, statusCode) {
      try {
        const data = await invoiceHelpers.downloadInvoice(this.downloadItem, preview, statusCode);
        this.openViewer({
          data,
          name: `Lasku__${this.downloadItem.invoiceNumber || "esikatselu"}.pdf`,
        });
        this.downloadPdfDialog = false;
      } catch (err) {
        this.showPopup(err + " Kokeile vaihtaa laskun tyyppiä", "error");
      }
    },

    editItem(item) {
      if (item.invoiceType === "refund") {
        if (this.hasRefundServiceId(item))
          this.$router.push("/invoice/refund/edit-related/" + item._id);
        else this.$router.push("/invoice/refund/edit/" + item._id);
      } else {
        this.$router.push("/invoice/edit/" + item._id);
      }
    },

    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa laskun")) {
        this.deleteInvoice(item._id);
      }
    },

    appendStyle(item) {
      if (item.overwritten) return "overwritten";
    },

    refundSend(refunds) {
      return refunds.length > 0 ? true : false;
    },

    invoiceTenant(invoice) {
      return invoiceHelpers.getInvoiceTenant(invoice);
    },

    resetForms() {
      this.resetSearchByDates("dueDate");
      this.search = "";
      this.showPaidInvoices = false;
      this.showInactiveBaseInvoices = false;
      this.getDataFromApi();
    },
  },
};
</script>

<style scoped>
.v-tooltip__content {
  font-size: 14px !important;
  opacity: 0.9 !important;
  background-color: rgb(78, 78, 78);
  padding: 25px;
}

.v-data-table >>> .overwritten {
  color: rgb(165, 165, 165) !important;
}

.refundSend {
  color: #0096c7;
}

.paid-amounts {
  display: grid;
  grid-template-columns: 120px auto;
  grid-auto-rows: 18px;
}
</style>
