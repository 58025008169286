<template>
  <v-dialog v-model="dialog" fullscreen persistent>
    <v-card>
      <v-toolbar flat>
        <v-card-title> Muokkaa valittuja vuokrakohteita </v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row dense>
          <v-col cols="12" lg="8" xl="9" order-sm="2" order-lg="1" order="2">
            <h4 class="info--text mb-3 mt-2 mt-sm-2">
              Huom! Vain kentät, joissa on jokin arvo, tallennetaan valittuihin vuokrakohteisiin.
              Tyhjiä kentät eivät yliaja nykyisiä arvoja.
            </h4>

            <v-form ref="form">
              <h3>Perustiedot</h3>
              <p class="mb-2">
                ** Julkiset tiedot voidaan näyttää, jos kohteet julkaistaan asuntoportaaleihin tai
                omille verkkosivuille
              </p>

              <h4>Vuokrattavana</h4>
              <v-row dense class="mb-1">
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model="apartment.activeForRent"
                    :items="[
                      { text: 'Kyllä', val: true },
                      { text: 'Ei', val: false },
                    ]"
                    item-text="text"
                    item-value="val"
                    outlined
                    dense
                    label="Vuokrattavana"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.concurrentContracts"
                    v-only-numbers
                    v-prevent-paste
                    :rules="validations.emptyOrPositiveNumber"
                    suffix="kpl"
                    label="Päällekkäisiä sopimuksia max"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <h4>Tyyppitiedot</h4>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-radio-group
                    v-model="apartment.vatRegister"
                    style="margin-top: 0px"
                    hide-details
                  >
                    <v-radio label="Arvonlisäverovelvollinen" :value="true"></v-radio>
                    <v-radio label="Ei arvonlisäverovelvollinen" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col v-if="$route.name != 'Luo kohde'" cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-autocomplete
                    v-model="apartment.condominium"
                    item-text="name"
                    item-value="_id"
                    :items="activeCondominiums"
                    label="Taloyhtiö / Kiinteistö"
                    return-object
                    small-chips
                    clearable
                    @change="changeAddress($event)"
                    :search-input.sync="searchInput"
                    @input.native="getCondominiums"
                    @click:clear="deleteAddress"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model="apartment.houseType"
                    outlined
                    dense
                    :items="houseTypes"
                    label="Vuokrakohteen tyyppi **"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model="apartment.doorType"
                    outlined
                    dense
                    :items="doorTypes"
                    label="Oven tyyppi **"
                    hide-details
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model="apartment.currentCondition"
                    outlined
                    dense
                    :items="[
                      { val: 1, text: 'Hyvä' },
                      { val: 2, text: 'Tyydyttävä' },
                      { val: 3, text: 'Välttävä' },
                    ]"
                    item-value="val"
                    item-text="text"
                    label="Vuokrakohteen kunto **"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.area"
                    type="number"
                    step="0.1"
                    label="Pinta-ala **"
                    suffix="m²"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="apartment.houseType != 'Kiinteistö'"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <v-text-field
                    v-model="apartment.floorPlan"
                    placeholder="esim. 1h + kk"
                    label="Vuokrakohteen kokoonpano **"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <h4 class="mt-1">Osoite</h4>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model="apartment.address"
                    label="Osoite **"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model="apartment.zipCode"
                    label="Postinumero **"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model="apartment.city"
                    label="Kaupunki **"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model="apartment.neighborhood"
                    label="Kaupunginosa **"
                    outlined
                    dense
                    disabled
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  v-if="apartment.houseType != 'Kiinteistö'"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <v-text-field
                    v-model.number="apartment.floor"
                    v-only-numbers
                    v-prevent-paste
                    label="Kerros **"
                    outlined
                    dense
                    hide-details
                    :rules="validations.emptyOrPositiveNumber"
                  ></v-text-field>
                </v-col>
              </v-row>

              <h4 class="mt-1">Lisätiedot</h4>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.roomCount"
                    v-only-numbers
                    v-prevent-paste
                    label="Huoneiden/tilojen määrä **"
                    suffix="kpl"
                    outlined
                    dense
                    hide-details
                    :rules="validations.emptyOrPositiveNumber"
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="apartment.houseType != 'Kiinteistö'"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <v-select
                    v-model="apartment.animals"
                    label="Eläimet sallittu **"
                    :items="[
                      { val: true, text: 'Kyllä' },
                      { val: false, text: 'Ei' },
                    ]"
                    item-value="val"
                    item-text="text"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>

                <v-col
                  v-if="apartment.houseType != 'Kiinteistö'"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                  xl="2"
                >
                  <v-select
                    v-model="apartment.balcony"
                    label="Parveke **"
                    :items="[
                      { val: true, text: 'Kyllä' },
                      { val: false, text: 'Ei' },
                    ]"
                    item-value="val"
                    item-text="text"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model="apartment.furnished"
                    label="Vuokrataan kalustettuna **"
                    :items="[
                      { val: true, text: 'Kyllä' },
                      { val: false, text: 'Ei' },
                    ]"
                    item-value="val"
                    item-text="text"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>

              <h4 class="mt-1">Kuvaus</h4>
              <v-row dense>
                <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                  <v-textarea
                    v-model="apartment.description"
                    label="Vuokrakohteen kuvaus **"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>

              <h3 class="mb-2">Hinnat</h3>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.priceWithTaxes"
                    type="number"
                    step="0.01"
                    label="Velaton hinta veroineen"
                    outlined
                    dense
                    suffix="€"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.estimatedPrice"
                    type="number"
                    step="0.01"
                    label="Nykyinen hinta-arvio"
                    outlined
                    dense
                    suffix="€"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.estimatedRent"
                    type="number"
                    step="0.01"
                    label="Markkinavuokra**"
                    outlined
                    dense
                    suffix="€/kk"
                    hide-details
                    :rules="validations.emptyOrPositiveNumber"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-select
                    v-model.number="apartment.electricityType"
                    :items="[
                      { val: 1, text: 'Vuokralaisen oma sopimus' },
                      { val: 2, text: 'Kiinteä kk-maksu' },
                      { val: 3, text: 'Sisältyy vuokraan' },
                    ]"
                    item-text="text"
                    item-value="val"
                    label="Sähkömaksu **"
                    outlined
                    dense
                    @change="apartment.fixedElectricity = null"
                    hide-details
                  ></v-select>
                </v-col>

                <v-col v-if="apartment.electricityType == 2" cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.fixedElectricity"
                    type="number"
                    step="0.01"
                    label="Kiinteä sähkömaksu **"
                    outlined
                    dense
                    suffix="€/kk"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-text-field
                    v-model.number="apartment.deposit"
                    type="number"
                    step="0.01"
                    label="Vakuus **"
                    outlined
                    dense
                    suffix="€"
                    hide-details
                    :rules="validations.emptyOrPositiveNumber"
                  ></v-text-field>
                </v-col>
              </v-row>

              <h3 class="mb-2 mt-1">Muut tiedot</h3>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <v-menu
                    ref="purchaseDateMenu"
                    v-model="purchaseDateMenu"
                    :close-on-content-click="false"
                    min-width="290"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate(apartment.purchaseDate)"
                        v-bind="attrs"
                        v-on="on"
                        label="Kohteen ostopäivä"
                        dense
                        outlined
                        append-icon="mdi-calendar"
                        v-prevent-manual-input
                        :readonly="true"
                        hide-details
                        class="mb-1"
                        clearable
                        @click:clear="apartment.purchaseDate = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="apartment.purchaseDate"
                      first-day-of-week="1"
                      @input="purchaseDateMenu = false"
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>

            <v-row dense class="mt-3">
              <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="info" @click="editMultiple()"> Tallenna </v-btn>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" lg="4" xl="3" order-sm="1" order-lg="2" order="1" class="pl-lg-2">
            <v-card>
              <v-card-title class="white--text info">
                Muokattavat vuokrakohteet
                <v-spacer></v-spacer>
              </v-card-title>

              <v-virtual-scroll
                :items="selectedApartments"
                :item-height="25"
                height="400"
                background-color="transparent"
              >
                <template v-slot="{ item }">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.address }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action> </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapState, mapActions } from "vuex";
import { debounce } from "../../utils/helpers";
import globalValues from "../../configs/globalValues";
import _ from "lodash";
import validations from "@/validations";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      defaultForm: {},
      searchInput: "",
      condominiumInput: "",
      houseTypes: globalValues.houseTypes,
      doorTypes: globalValues.doorTypes,
      purchaseDateMenu: false,
      apartment: {
        activeForRent: null,
        vatRegister: null,
        houseType: null,
        doorType: null,
        currentCondition: null,
        address: null,
        zipCode: null,
        city: null,
        area: null,
        floor: null,
        balcony: null,
        furnished: null,
        animals: null,
        floorPlan: null,
        roomCount: null,
        priceWithTaxes: null,
        estimatedPrice: null,
        estimatedRent: null,
        electricityType: null,
        fixedElectricity: null,
        deposit: null,
        condominium: null,
        concurrentContracts: null,
        description: null,
        purchaseDate: null,
      },
      validations,
    };
  },

  created() {
    this.defaultForm = Object.freeze(_.cloneDeep(this.apartment));
  },

  computed: {
    ...mapState("apartment", ["selectedApartments"]),
    ...mapState("condominium", ["activeCondominiums"]),
    ...mapState("account", ["activeLandlords", "currentUser"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.apartment = { ...this.defaultForm };
        this.$emit("input", value);
      },
    },
  },

  watch: {
    "apartment.houseType"(val) {
      if (val == "Kiinteistö") {
        this.apartment.apartmentNumber = "";
        this.apartment.floor = null;
        this.apartment.balcony = null;
        this.apartment.floorPlan = "";
        this.apartment.animals = null;
      }
    },

    condominiumInput: debounce(function (newVal) {
      this.searchCondominium(`/api/v1/condominium/search-condominium?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("condominium", ["searchCondominium"]),
    ...mapActions("apartment", ["updateMultipleApartments"]),

    editMultiple() {
      if (confirm("Oletko varma, että haluat muokata kaikkia valittuja kohteita?")) {
        if (this.$refs.form.validate()) {
          const apartmentIds = this.selectedApartments.map((apartment) => apartment._id);
          this.updateMultipleApartments({ apartmentIds, apartment: this.apartment });
        }
      }
    },

    async getCondominiums(event) {
      this.condominiumInput = event.target.value;
    },

    changeAddress(condominium) {
      if (condominium) {
        this.apartment.condominium = condominium._id;
        this.apartment.address = condominium.address;
        this.apartment.zipCode = condominium.zipCode;
        this.apartment.city = condominium.city;
        this.apartment.neighborhood = condominium.neighborhood;
      }
    },

    deleteAddress() {
      this.apartment.condominium = null;
      this.apartment.address = null;
      this.apartment.zipCode = null;
      this.apartment.city = null;
      this.apartment.neighborhood = null;
    },

    allowedDates(val) {
      const date = new Date(val);
      const today = new Date();
      if (date > today) return false;
      else return true;
    },
  },
};
</script>

<style scoped></style>
