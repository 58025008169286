import { render, staticRenderFns } from "./DepositReleaseDialog.vue?vue&type=template&id=506e0ecd&scoped=true"
import script from "./DepositReleaseDialog.vue?vue&type=script&lang=js"
export * from "./DepositReleaseDialog.vue?vue&type=script&lang=js"
import style0 from "./DepositReleaseDialog.vue?vue&type=style&index=0&id=506e0ecd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506e0ecd",
  null
  
)

export default component.exports