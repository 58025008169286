<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title> Luo ohisuoritus maksuun # {{ currentPayment.paymentNumber }}</v-card-title>
      <v-card-subtitle style="margin-top: 0px"
        >Voit luoda + tai - merkkisen manuaalisen ohisuorituksen maksulle. Negatiivinen suoritus on
        maksettu suoritus, positivinen suoritus on saatu suoritus.</v-card-subtitle
      >

      <v-card-text class="mt-2">
        <v-form ref="form">
          <v-text-field
            label="Summa (brutto)"
            v-model.number="payment.amount"
            step="0.01"
            type="number"
            suffix="€"
            :rules="required"
            outlined
            dense
          ></v-text-field>

          <v-menu
            ref="payDateMenu"
            v-model="payDateMenu"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormattedPayDate"
                v-bind="attrs"
                :rules="required"
                outlined
                dense
                v-on="on"
                append-icon="mdi-calendar"
                label="Suorituksen pvm."
                v-prevent-manual-input
                :readonly="true"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="payment.payDate"
              :allowed-dates="allowedPayDates"
              first-day-of-week="1"
              @input="payDateMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-textarea
            v-model="payment.message"
            label="Oma viesti"
            placeholder="Kuvaile maksutapahtumaa..."
            outlined
            dense
            :rules="required"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="submitPayment()">Luo ohisuoritus</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      payDateMenu: false,
      dateFormattedPayDate: "",
      payment: {
        amount: null,
        payDate: "",
      },
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("outgoingpayment", ["currentPayment"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) this.$refs.form.reset();
        this.$emit("input", value);
      },
    },
  },

  watch: {
    "payment.payDate"() {
      this.dateFormattedPayDate = this.formatDate(this.payment.payDate);
    },
  },

  methods: {
    ...mapActions("outgoingpayment", ["makePayment"]),

    async submitPayment() {
      if (this.$refs.form.validate()) {
        try {
          await this.makePayment({ payment: this.payment, paymentId: this.currentPayment._id });
          this.dialog = false;
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    allowedPayDates(val) {
      const today = new Date(moment(new Date()).format("YYYY-MM-DD"));

      const show = new Date(val).getTime() <= today ? true : false;
      return show;
    },
  },
};
</script>

<style scoped></style>
