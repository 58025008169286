<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>Muokkaa vakuutta {{ deposit.name }}</v-card-title>
      <v-card-text>
        <v-row justify="center" class="mt-5">
          <v-col md="10">
            <v-form ref="form">
              <v-select
                v-model="deposit.name"
                outlined
                dense
                :items="names"
                label="Vakuuden tyyppi"
                :rules="required"
              >
              </v-select>

              <v-select
                v-model="deposit.depositType"
                outlined
                dense
                :items="depositTypes"
                item-text="text"
                item-value="val"
                label="Talletuksen tapa"
                :rules="required"
              ></v-select>

              <v-text-field
                v-model.number="deposit.amount"
                dense
                outlined
                type="number"
                step="0.01"
                label="Määrä"
                suffix="€"
                :rules="required"
              ></v-text-field>

              <v-menu
                ref="payDateMenu"
                v-model="payDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormattedPayDate"
                    dense
                    outlined
                    v-bind="attrs"
                    :rules="required"
                    v-on="on"
                    append-icon="mdi-calendar"
                    label="Saapumispäivä"
                    v-prevent-manual-input
                    :readonly="true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deposit.payDate"
                  first-day-of-week="1"
                  @input="payDateMenu = false"
                ></v-date-picker>
              </v-menu>

              <v-textarea v-model="deposit.comment" label="Kommentti" dense outlined></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="editDeposit()">Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";

export default {
  props: {
    value: { type: Boolean, default: false },

    contractNum: {
      type: Number,
      default: 0,
    },

    contractId: {
      type: String,
      default: "",
    },

    depositObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  mixins: [mixins],

  data() {
    return {
      payDateMenu: false,
      dateFormattedPayDate: "",
      deposit: {
        depositType: "",
        name: "",
        payDate: "",
        amount: null,
        comment: "",
        _id: "",
      },
      depositTypes: [
        { text: "Pankkitakaus", val: "bank" },
        { text: "Maksettu vuokranantajan tilille", val: "payment" },
        { text: "Kelan / Sosiaalitoimen maksusitoumus", val: "commitment" },
        { text: "Henkilötakaus", val: "guarantee" },
        { text: "Asiakasvaratili", val: "customerReserveAccount" },
      ],
      names: ["Vuokravakuus", "Avainpantti"],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    "deposit.payDate"() {
      this.dateFormattedPayDate = this.formatDate(this.deposit.payDate);
    },

    depositObj: function (obj) {
      this.deposit = obj || {};

      if (this.deposit.payDate) {
        this.deposit.payDate = new Date(this.deposit.payDate).toISOString().substring(0, 10);
      } else {
        this.deposit.payDate = new Date(new Date()).toISOString().substring(0, 10);
      }
    },
  },

  methods: {
    async editDeposit() {
      try {
        await axiosMethods.update(
          "/api/v1/rental-contract/editDeposit/" + this.contractId,
          this.deposit
        );

        this.showPopup("Vakuus muokattu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
