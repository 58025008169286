<template>
  <div class="min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Luo allekirjoitettava dokumentti</span>
    </div>

    <v-stepper class="mt-2" v-model="step">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :rules="[() => (step > 1 ? validateData() : true)]"
          :complete="dataCheck"
          >Tiedot</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step step="2" :rules="[() => (step > 2 ? validateInformation() : true)]"
          >Sopimus</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <!-- Document -->
          <document-form
            ref="dataForm"
            @getpreviewtemplateready="$refs.summary.contractModelIdChangeHandler()"
          ></document-form>

          <div align="right">
            <v-btn color="primary" @click="step = 2">Seuraava</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="buttons-wrapper">
            <v-btn color="error" outlined @click="step = 1">Takaisin</v-btn>
          </div>

          <!-- ERROR -->
          <h3 v-if="!dataCheck" class="error--text mt-2">
            Lisää vähintään sopimuksen nimi ja yksi vuokralainen sopimuksen osapuoliin!
          </h3>

          <!-- Summary -->
          <Summary :isValidContract="dataCheck" ref="summary"></Summary>

          <div class="buttons-wrapper">
            <v-btn color="error" outlined @click="step = 1">Takaisin</v-btn>
            <v-btn color="info" @click.prevent="submit()">Luo</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import DocumentForm from "../../components/OtherContracts/DocumentForm";
import Summary from "../../components/OtherContracts/Summary";
import mixins from "../../mixins/mixins";
import { mapActions, mapState } from "vuex";

export default {
  title: "Luo vuokrasopimus",

  components: {
    DocumentForm,
    Summary,
  },

  mixins: [mixins],

  data() {
    return {
      step: 1,
      dataCheck: false,
      isReferenced: false,
    };
  },

  async created() {
    // set contract to initial values
    this.$store.state.othercontract.contract = {
      name: "",
      tenant: null,
      otherTenants: [],
      landlords: [],
      apartment: null,
      keys: [],
      modelId: "",
      content: "",
      signaturePad: { signers: [], signature: null },
    };

    eventBus.$on("validatedata", (boolean) => {
      this.dataCheck = boolean;
    });
  },

  computed: {
    ...mapState("contract", ["refRentalContract"]),
    ...mapState("contractmodel", ["contentError"]),
  },

  async mounted() {
    if (this.$route.query.createReferenceDocument === "true" && this.refRentalContract) {
      this.$refs.dataForm.setRefRentalContractValues();
      this.isReferenced = true;
    } else {
      this.isReferenced = false;
    }

    if (this.$route.query.tenant) {
      try {
        await this.getOneTenant("/api/v1/tenant/getOne/" + this.$route.query.tenant);
      } catch (err) {
        this.showPopup("Vuokralaista ei onnistuttu hakemaan.", "error");
      }
    }
  },

  watch: {
    step(val) {
      if (val == 2) {
        this.$refs.summary.getAllContent();
      }
    },
  },

  methods: {
    ...mapActions("othercontract", ["createOtherContract"]),
    ...mapActions("tenant", ["getOneTenant"]),

    validateData() {
      this.$refs.dataForm.validateForm();

      if (this.dataCheck) return true;
      else return false;
    },

    async submit() {
      // Check if all content is loaded to summary
      if (
        this.$refs.summary.modelContentLoaded == "" ||
        this.$refs.summary.modelContentLoaded == "<p></p>" ||
        this.contentError
      ) {
        return this.showPopup(
          'Lataa vuokrasopimuksen tiedot sopimuspohjan "Lataa"-napista tai luo uusi sopimuspohja ja varmista, että siinä on sisältöä ja se on oikein muotoiltu.',
          "error"
        );
      }

      if (this.dataCheck) {
        const contractData = this.$store.getters["othercontract/getContract"];

        try {
          await this.createOtherContract({
            contractData,
            isReferenced: this.isReferenced,
            rentalContractId: this.isReferenced ? this.refRentalContract._id : null,
          });
          this.$router.push("/other-contracts");
        } catch (err) {
          this.showPopup(err, "error");
        }
      } else {
        this.showPopup(
          "Lomakkeen kaikki kentät eivät ole täytetty oikein. Tarkista kaikki pakolliset kentät.",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
