<template>
  <v-dialog v-model="dialog" width="1100">
    <v-card>
      <v-card-title> {{ title }} </v-card-title>
      <v-card-text class="wrapper">
        <v-stepper class="mt-2" v-model="step">
          <!-- HEADER -->

          <v-stepper-header>
            <!-- Step 1 -->
            <v-stepper-step
              step="1"
              :rules="[() => (step > 1 ? showLoader && overviewFormIsValidated : true)]"
              :complete="showLoader && overviewFormIsValidated"
              >Tiedot</v-stepper-step
            >

            <v-divider></v-divider>
            <!-- Step 2 -->
            <v-stepper-step
              step="2"
              :rules="[
                () =>
                  step > 2 ? newRentIncrease.products.length > 0 && productsAreValidated : true,
              ]"
              :complete="newRentIncrease.products.length > 0 && productsAreValidated"
              >Tuotteet</v-stepper-step
            >

            <v-divider></v-divider>

            <!-- Step 3 -->
            <v-stepper-step step="3" :rules="[() => (step > 3 ? true : true)]"
              >Viesti</v-stepper-step
            >
          </v-stepper-header>

          <!-- ITEMS -->

          <v-stepper-items>
            <!-- Overview -->
            <v-stepper-content step="1">
              <div class="mb-1" align="right">
                <v-btn
                  color="primary"
                  :disabled="!showLoader"
                  @click="
                    validateOverviewForm();
                    step = 2;
                  "
                  >Seuraava</v-btn
                >
              </div>

              <Overview ref="rentIncreaseOverview"></Overview>

              <div class="mt-1" align="right">
                <v-btn
                  color="primary"
                  :disabled="!showLoader"
                  @click="
                    validateOverviewForm();
                    step = 2;
                  "
                  >Seuraava</v-btn
                >
              </div>
            </v-stepper-content>

            <!-- Products -->
            <v-stepper-content step="2">
              <div class="buttons-wrapper mb-1">
                <v-btn color="error" outlined @click="step = 1">Takaisin</v-btn>
                <v-btn
                  color="primary"
                  :disabled="newRentIncrease.products.length === 0"
                  @click="
                    validateProducts();
                    step = 3;
                  "
                  >Seuraava</v-btn
                >
              </div>

              <!-- overview -->
              <Products ref="rentIncreaseProducts"></Products>

              <div class="buttons-wrapper mt-1">
                <v-btn color="error" outlined @click="step = 1">Takaisin</v-btn>
                <v-btn
                  color="primary"
                  :disabled="newRentIncrease.products.length === 0"
                  @click="
                    validateProducts();
                    step = 3;
                  "
                  >Seuraava</v-btn
                >
              </div>
            </v-stepper-content>

            <!-- Message -->
            <v-stepper-content step="3">
              <div class="buttons-wrapper mb-1">
                <v-btn color="error" outlined @click="step = 2">Takaisin</v-btn>
              </div>

              <!-- overview -->
              <Message ref="rentIncreaseMessage"></Message>

              <div class="buttons-wrapper mt-1">
                <v-btn color="error" outlined @click="step = 2">Takaisin</v-btn>
                <v-btn
                  :disabled="newRentIncrease.content == ''"
                  color="primary"
                  @click.prevent="createRentIncrease"
                  >{{ newRentIncrease.dontSendMessage ? "Luo" : "Luo ja lähetä" }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import Overview from "./Overview.vue";
import Products from "./Products.vue";
import Message from "./Message.vue";
import helpers from "@/utils/rentIncreaseHelpers";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
  },

  components: {
    Overview,
    Products,
    Message,
  },

  mixins: [mixins],

  data() {
    return {
      step: 1,
    };
  },

  computed: {
    ...mapState("rentIncrease", [
      "currentContract",
      "newRentIncrease",
      "productsBtnAnimation",
      "replyToSelector",
      "productsAreValidated",
      "overviewFormIsValidated",
    ]),
    ...mapGetters("rentIncrease", ["showLoader"]),
    ...mapState("contractmodel", ["contentError"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$refs.rentIncreaseOverview.resetValues();
        this.$refs.rentIncreaseProducts.resetValues();
        this.$refs.rentIncreaseMessage.resetValues();
        this.step = 1;
      },
    },

    title() {
      return `Luo vuokrankorotus sopimukseen ${this.currentContract.contractNumber}`;
    },

    indexRentIncreaseInUse() {
      const type = this.currentContract?.lease?.rentIncreaseType || "";
      if (type.toLowerCase().includes("index")) return true;
      else return false;
    },
  },

  watch: {
    currentContract(val) {
      // Get invoice products to product state
      this.getInvoiceProducts();
      // Set form values
      this.setFormValues(val);
    },

    step(val) {
      if (val == 3) {
        this.$refs.rentIncreaseMessage.getAllContent();
      }

      if (val == 2 && this.productsBtnAnimation) {
        setTimeout(() => {
          this.setButtonAnimation(false);
        }, 5000);
      }
    },
  },

  methods: {
    ...mapMutations("rentIncrease", [
      "setNewRentIncreaseValue",
      "setButtonAnimation",
      "setProductsAreValidated",
      "setOverviewFormIsValidated",
    ]),
    ...mapActions("rentIncrease", ["createNewRentIncrease"]),
    ...mapActions("product", ["getInvoiceProducts"]),

    setFormValues(contract) {
      if (this.indexRentIncreaseInUse) {
        this.setNewRentIncreaseValue({
          val: contract.lease.startRentPrice,
          field: "startRentPrice",
        });
      }

      if (
        contract.lease.rentIncreaseType &&
        contract.lease.rentIncreaseType.toLowerCase().includes("percentage")
      ) {
        this.setNewRentIncreaseValue({
          val: contract.lease.rentIncreasePercentage || 0,
          field: "percentage",
        });
      }

      if (contract.lease.rentIncreaseType === "fixed") {
        this.setNewRentIncreaseValue({
          val: contract.lease.rentIncreaseFixed,
          field: "fixedAmount",
        });
      }

      if (contract.baseInvoice.nextDueDate) {
        this.setNewRentIncreaseValue({
          val: helpers.getInvoiceDueDate(contract.baseInvoice, contract.lease.nextRentIncreaseDate),
          field: "dueDate",
        });
      }

      this.setNewRentIncreaseValue({
        val: contract.baseInvoice.paymentTerm,
        field: "paymentTerm",
      });

      this.setNewRentIncreaseValue({
        val: contract.baseInvoice.autoSendType === "evoice" ? "evoice" : "post",
        field: "sendType",
      });

      // Message reply-to email
      if (!this.newRentIncrease.dontSendMessage) {
        this.setNewRentIncreaseValue({
          val: helpers.getReplyToEmail(this.replyToSelector),
          field: "replyToEmail",
        });
      }
    },

    async createRentIncrease() {
      if (this.validateAllForms()) {
        if (this.contentError) {
          return this.showPopup(
            'Viestipohja sisältää teknisiä virheitä. Korjaa viestin sisältö tai luo uusi sopimuspohja ja lataa se "Lataa"-napista.',
            "error"
          );
        }

        try {
          const nextDueDate = moment(this.currentContract.baseInvoice.nextDueDate).format(
            "YYYY-MM-DD"
          );
          const dueDate = moment(this.newRentIncrease.dueDate).format("YYYY-MM-DD");

          if (moment(nextDueDate).isSame(dueDate)) {
            if (
              !confirm(
                "Korotetun laskun 1. eräpäiväksi on asetettu sama päivä kuin nykyisen toistuvan laskun seuraava eräpäivä. Mikäli jatkat, korotus aktivoidaan ja toistuvaislasku päivitetään tallennuksen yhteydessä."
              )
            )
              return;
          } else {
            if (
              !confirm(
                "Korotus aktivoidaan ja toistuvaislasku päivitetään automaattisesti, kun sen seuraava eräpäivä vastaa korotetun laskun eräpäivän kuukautta."
              )
            )
              return;
          }

          await this.createNewRentIncrease();
          this.showPopup("Vuokrankorotus luotu ja siirretty tehtyjen listalle", "success");
          this.dialog = false;
        } catch (err) {
          this.showPopup(err, "error");
        }
      } else {
        this.showPopup(
          "Tarkista, että kaikki kentät on oikein täytetty ja lomakkeella ei ole virheilmoituksia.",
          "error"
        );
      }
    },

    validateProducts() {
      this.setProductsAreValidated(this.$refs.rentIncreaseProducts.validateForm());
    },

    validateOverviewForm() {
      this.setOverviewFormIsValidated(this.$refs.rentIncreaseOverview.validateForm());
    },

    validateAllForms() {
      return (
        this.$refs.rentIncreaseOverview.validateForm() &&
        this.$refs.rentIncreaseProducts.validateForm() &&
        this.$refs.rentIncreaseMessage.validateForm()
      );
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 8px !important;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.v-stepper__content {
  padding: 10px 10px 10px 10px;
}

@media only screen and (max-width: 800px) {
  .v-stepper__content {
    padding: 10px 2px 10px 2px;
  }
}
</style>
